export const initialUserState = {
    user: {
        displayName: '',
        email: '',
        photoURL: '',
        uid: '',
    },
    roles: {
        a: 0,
        o: '',
        s: false,
    },
}

export const userReducer = (state = initialUserState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'userChanged':
            return { ...state, ...payload }
        default:
            return state
    }
}
