import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

import { SpacedGroup } from 'UI/Components/SpacedGroup'

const useStyles = makeStyles(theme => ({
    description: {
        fontSize: '12px',
        marginBottom: theme.spacing(0.5),
        textAlign: 'center',
        color: '#A4A6A8',
    },
}))

export const Actions = ({ isQG }: { isQG: boolean }) => {
    const classes = useStyles()
    return (
        <SpacedGroup flexDirection="column">
            {!isQG && (
                <Typography className={classes.description}>
                    Screen recording is limited to 30 minutes.
                </Typography>
            )}
        </SpacedGroup>
    )
}
