import Config from '../config'
import { getExtensionVersion } from './extServices'
import { uuid } from './utils'

const pjson = require('../../package.json')

const getOS = () => {
    const userAgent = navigator?.userAgent
    const platform = navigator?.platform

    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os: string | null = null

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (/Linux/.test(platform)) {
        os = 'Linux'
    }

    return { os }
}

const detectBrowserWithVersion = () => {
    const nAgt = navigator?.userAgent
    let browserName = navigator?.appName
    let fullVersion = '' + parseFloat(navigator?.appVersion)
    let nameOffset, verOffset, ix

    // In Opera 15+, the true version is after "OPR/"
    if ((verOffset = nAgt.indexOf('OPR/')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 4)
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browserName = 'Opera'
        fullVersion = nAgt.substring(verOffset + 6)
        if ((verOffset = nAgt.indexOf('Version')) != -1)
            fullVersion = nAgt.substring(verOffset + 8)
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browserName = 'Microsoft Internet Explorer'
        fullVersion = nAgt.substring(verOffset + 5)
    }
    // In Chrome, the true version is after "Chrome"
    else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browserName = 'Google Chrome'
        fullVersion = nAgt.substring(verOffset + 7)
    }
    // In Safari, the true version is after "Safari" or after "Version"
    else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browserName = 'Safari'
        fullVersion = nAgt.substring(verOffset + 7)
        if ((verOffset = nAgt.indexOf('Version')) != -1)
            fullVersion = nAgt.substring(verOffset + 8)
    }
    // In Firefox, the true version is after "Firefox"
    else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browserName = 'Mozilla Firefox'
        fullVersion = nAgt.substring(verOffset + 8)
    }
    // In most other browsers, "name/version" is at the end of userAgent
    else if (
        (nameOffset = nAgt.lastIndexOf(' ') + 1) <
        (verOffset = nAgt.lastIndexOf('/'))
    ) {
        browserName = nAgt.substring(nameOffset, verOffset)
        fullVersion = nAgt.substring(verOffset + 1)
        if (browserName.toLowerCase() == browserName.toUpperCase()) {
            browserName = navigator.appName
        }
    }
    // trim the fullVersion string at semicolon/space if present
    if ((ix = fullVersion.indexOf(';')) != -1)
        fullVersion = fullVersion.substring(0, ix)
    if ((ix = fullVersion.indexOf(' ')) != -1)
        fullVersion = fullVersion.substring(0, ix)

    const majorVersion = parseInt('' + fullVersion, 10)
    if (isNaN(majorVersion)) {
        fullVersion = '' + parseFloat(navigator.appVersion)
    }

    return {
        browser: browserName,
        browserVersion: fullVersion,
    }
}

export const composeGlobalProps = (baseProps: any = null) => ({
    ...detectBrowserWithVersion(),
    ...getOS(),
    ...(SDK_MODE
        ? {
              ...(baseProps || {}),
              source: 'sdk',
              'sdk-version': pjson.version,
              environment: Config.firebase.projectId,
          }
        : {
              source: 'chrome-extension',
              'ext-id': chrome.runtime.id,
              'ext-version': getExtensionVersion(),
              environment: Config.firebase.projectId,
          }),
})

export const composeUserProps = user =>
    user
        ? {
              // Custom props should be before the main user props, because main props should prevail
              ...(user.customProps || {}),
              'user-id': user.user.uid,
              email: user.user.email,
              name: user.user.displayName,
              'organization-id': SDK_MODE ? uuid() : user.roles?.o,
          }
        : {}
