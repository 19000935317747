import React, { memo } from 'react'

import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import RedoIcon from '@mui/icons-material/Redo'

import { uiId } from 'services/utils'

import { Tags } from './Tags'
import { ApplicationsFilter } from './ApplicationsFilter'
import { SpaceFilter } from './SpaceFilter'
import { UseFilterState } from './use-filter-state'
import { SpacedGroup } from 'UI/Components'

import { setToExtLocalStorage } from 'services/extServices'
import { SPACE_FILTER_STATE } from 'background/constants'

const useStyles = makeStyles(theme => ({
    labelIcon: {
        marginRight: theme.spacing(0.5),
    },
    iconButton: {
        minHeight: '30px',
        color: '#212121',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    redoIcon: {
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        fontSize: '16px',
        marginRight: theme.spacing(0.5),
    },
}))

type Props = {
    appFilter: UseFilterState<string>
    tagsFilter: UseFilterState<string[]>
    spaceFilter: UseFilterState<string[]>
}

export const isAllSpacesSelected = (value: Array<string>) =>
    !value.length || value.includes('ALL')

export const PlaybookFilters = memo(
    ({ appFilter, tagsFilter, spaceFilter }: Props) => {
        const classes = useStyles()

        return (
            <SpacedGroup
                spacing={2}
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <SpacedGroup spacing={1} flexWrap="wrap">
                    {EXT_MODE && (
                        <>
                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                minHeight="30px"
                            >
                                <SpaceFilter
                                    onUpdate={value => {
                                        spaceFilter.updateState(value)
                                        setToExtLocalStorage(
                                            SPACE_FILTER_STATE,
                                            value
                                        )

                                        if (isAllSpacesSelected(value)) {
                                            return
                                        }

                                        appFilter.resetState()
                                        tagsFilter.resetState()
                                    }}
                                    value={spaceFilter.state}
                                />
                            </Box>

                            <Box
                                position="relative"
                                display="flex"
                                alignItems="center"
                                minHeight="30px"
                            >
                                <ApplicationsFilter
                                    value={
                                        appFilter.state ||
                                        appFilter.defaultState ||
                                        ''
                                    }
                                    onUpdate={value => {
                                        appFilter.resetDefaultState()
                                        appFilter.updateState(value)
                                        tagsFilter.resetState()
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    <Tags
                        tags={tagsFilter.state}
                        defaultTags={tagsFilter.defaultState}
                        onUpdateTags={tagsFilter.updateState}
                        onUpdateDefTags={tagsFilter.updateDefaultState}
                    />
                </SpacedGroup>

                <IconButton
                    className={classes.iconButton}
                    size="small"
                    disableRipple
                    disableFocusRipple
                    id={uiId('back-to-default-btn')}
                    onClick={() => {
                        setToExtLocalStorage(SPACE_FILTER_STATE, [])
                        appFilter.resetToDefaultState()
                        tagsFilter.resetToDefaultState()
                        spaceFilter.resetToDefaultState()
                    }}
                >
                    <RedoIcon className={classes.redoIcon} />
                    <Typography variant="body2">Clear</Typography>
                </IconButton>
            </SpacedGroup>
        )
    }
)
