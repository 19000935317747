import { useEffect, useMemo, useState } from 'react'

import Typography from '@mui/material/Typography'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TranslateIcon from '@mui/icons-material/Translate'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'

import { LanguageType, MagicCaptureType } from 'types'
import { MAGIC_CAPTURE_LAST_LANG } from 'background/constants'
import { getMagicCaptureConfig, logToAnalytics } from 'services/swMessenger'
import { useStorageSetting } from 'hooks'

import { DescriptionInput } from './DescriptionInput'
import { CaptureBtn } from './CaptureBtn'
import { UpgradeBanner } from './UpgradeBanner'

type Props = {
    startQG: (mc: MagicCaptureType) => void
    usedQGStatus: {
        usedMC: number
        quotaMC: number | null
    }
}

const useStyles = makeStyles(() => ({
    language: {
        color: '#FFFFFF',
        lineHeight: '24px',
        fontSize: '18px',
        fontWeight: 700,
        letterSpacing: '0.1px',
    },
    selectMenu: { height: '300px' },
    langSelect: {
        lineHeight: '20px',
        fontWeight: 400,
        fontSize: '18px',
        color: '#FFFFFF',
        letterSpacing: '0.25px',
    },
    arrow: { color: '#FFFFFF' },
    iconWrap: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '54px',
        height: '54px',
        borderRadius: '27px',
        backgroundColor: 'rgba(0, 0, 0, 0.20)',
        color: '#FFFFFF',
    },
}))

export const StartQuickGuidde = ({ startQG, usedQGStatus }: Props) => {
    const classes = useStyles()
    const [language, setLanguage] = useState<string>('')
    const [description, setDescription] = useState<string>('')

    const [magicCaptureLangs, setMagicCaptureLangs] = useState<
        LanguageType[] | null
    >(null)

    const {
        storageSetting: preferredLang,
        changeSetting: changePreferredLang,
    } = useStorageSetting(MAGIC_CAPTURE_LAST_LANG)

    useEffect(() => {
        getMagicCaptureConfig(({ languages }) => {
            setMagicCaptureLangs(languages)
        })
    }, [])

    useEffect(() => {
        if (!magicCaptureLangs || language) return

        const prefLang = magicCaptureLangs.find(
            l => l.langCode === preferredLang
        )
        const defaultLang = magicCaptureLangs.find(l => l.langCode === 'en-US')

        setLanguage(prefLang?.langCode || defaultLang?.langCode || '')
    }, [language, magicCaptureLangs, preferredLang])

    const sortedLanguages: LanguageType[] = useMemo(() => {
        return (
            magicCaptureLangs?.sort((a, b) => {
                // Sort alphabetically based on language name
                if (a.langName < b.langName) return -1
                if (a.langName > b.langName) return 1
                return 0
            }) || []
        )
    }, [magicCaptureLangs])

    const disabled = useMemo(
        () => !description || description.length > 150,
        [description]
    )

    const handleSubmit = () => {
        const lang = magicCaptureLangs?.find(l => l.langCode === language)

        logToAnalytics('wizard_captureButton_clicked', {
            videoType: 'smart_capture',
            videoDescription: description,
            selectedLanguage: lang,
        })

        changePreferredLang(language)
        startQG({
            description,
            captureType: {
                id: '',
                title: '',
                imageUrl: '',
                withExtraInput: false,
            }, // TODO: Deprecate?
            language: lang,
        })
    }

    return (
        <>
            <UpgradeBanner usedQGStatus={usedQGStatus} />

            <Typography variant="h1">Describe your Guidde</Typography>

            <Box mt={3.5} mb={3}>
                <Typography variant="caption" component="p">
                    <>
                        The better you describe what you want to capture, the
                        higher
                        <br /> quality content Guidde will produce
                    </>
                </Typography>
            </Box>

            <DescriptionInput
                value={description}
                onChange={event => {
                    if (event.target.value.length <= 150) {
                        setDescription(event.target.value as string)
                    }
                }}
                onEnter={() => {
                    if (disabled) return
                    handleSubmit()
                }}
            />

            <Box
                mt={5}
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
            >
                <Box display="flex">
                    <Box className={classes.iconWrap} mr={2.5}>
                        <TranslateIcon color="inherit" />
                    </Box>
                    <Box>
                        <Typography className={classes.language}>
                            Choose the language for your guidde
                        </Typography>
                        <Select
                            variant="standard"
                            disableUnderline
                            classes={{
                                root: classes.langSelect,
                                icon: classes.arrow,
                            }}
                            value={language}
                            onChange={event => {
                                setLanguage(event.target.value as string)
                            }}
                            MenuProps={{
                                classes: { paper: classes.selectMenu },
                            }}
                        >
                            {sortedLanguages.map((lt: LanguageType) => (
                                <MenuItem key={lt.langCode} value={lt.langCode}>
                                    {lt.langName}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Box>

                <CaptureBtn disabled={disabled} onClick={handleSubmit} />
            </Box>
        </>
    )
}
