const garbageCollector: any = {
    injections: [],
    intervals: [],
    listeners: [],
    callbacks: [],
}

const noop = () => {}
export const registerGCItem = SDK_MODE
    ? noop
    : (
          type: 'injections' | 'intervals' | 'listeners' | 'callbacks',
          value:
              | Element
              | number
              | {
                    type: string
                    value: (...args: any) => any
                    global: Window | Document
                    useCapture?: boolean
                }
              | {
                    value: (...args: any) => any
                    chromeAPI:
                        | chrome.storage.StorageChangedEvent
                        | chrome.runtime.ExtensionMessageEvent
                }
      ) => {
          garbageCollector[type].push(value)
      }

export const runGarbageCollector = SDK_MODE
    ? noop
    : () => {
          garbageCollector.injections.forEach((injection: Element) => {
              injection.remove()
          })

          garbageCollector.intervals.forEach((interval: number) => {
              window.clearInterval(interval)
          })

          garbageCollector.listeners.forEach(
              (listener: {
                  type: string
                  value: (...args: any) => any
                  global: Window | Document
                  useCapture?: boolean
              }) => {
                  listener.global.removeEventListener(
                      listener.type,
                      listener.value,
                      listener.useCapture || false
                  )
              }
          )

          garbageCollector.callbacks.forEach(
              (callback: {
                  value: (...args: any) => any
                  chromeAPI:
                      | chrome.storage.StorageChangedEvent
                      | chrome.runtime.ExtensionMessageEvent
              }) => {
                  callback.chromeAPI.removeListener(callback.value)
              }
          )
      }

export const establishGarbageCollector = SDK_MODE
    ? noop
    : () => {
          if (chrome?.runtime?.onMessage) {
              const gcListener = (msg, _, sendResponse) => {
                  if (msg.action === 'RUN_GARBAGE_COLLECTOR') {
                      runGarbageCollector()
                      chrome.runtime.onMessage.removeListener(gcListener)
                      sendResponse('GARBAGE_CLEARED')
                  }

                  return false
              }

              chrome.runtime.onMessage.addListener(gcListener)
          }
      }
