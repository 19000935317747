import { createContext, useState, Context, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ServicesUsageType } from '@guidde/common-types/fsdb'

import {
    getPlaybook,
    getPlayMessage,
    logToAnalytics,
    onServiceUsageUpdate,
    getServiceUsage,
    onSetQuickAction,
    insertQuickActionResult,
} from 'services/swMessenger'
import {
    initialUseBoolean,
    TypeUseBoolean,
    useBoolean,
    useLibraryState,
} from 'hooks'
import { setPlaybook } from 'ducks/actions'

import { sendMessageToParentScript } from 'services/parentService'
import { RootState } from 'ducks/rootReducer'
import { captureSentryEvent } from '../services/sentry'
import { ServiceUsageExtType } from 'types'
import { uuid } from '../services/utils'

type PlayerPosition = 'bottomRight' | 'center'

const initialContext: AppContextType = {
    startTime: null,
    isLibraryOpened: false,
    playbookMode: initialUseBoolean,
    playlistMode: initialUseBoolean,
    serviceUsage: {
        isFreePlan: true,
        isRecordingLocked: false,
        isCustomPrice: false,
        usedStorage: 0,
        planName: 'Basic',
        hasScreenRecording: false,
    },
    openModalMode: () => {},
    closeModalMode: () => {},
    openVideoPlayer: () => {},
}

type AppContextType = {
    startTime: null | number
    isLibraryOpened: boolean
    playbookMode: TypeUseBoolean
    playlistMode: TypeUseBoolean
    serviceUsage: {
        isFreePlan: boolean
        isRecordingLocked: boolean
        isCustomPrice: boolean
        usedStorage: number
        planName: string
        hasScreenRecording: boolean
    }
    openModalMode: () => void
    closeModalMode: () => void
    openVideoPlayer: (
        playbook,
        playerPosition?: PlayerPosition,
        startTime?: number
    ) => void
}

export const AppContext: Context<AppContextType> = createContext(initialContext)

export const AppProvider = ({ children }) => {
    const dispatch = useDispatch()

    const playbookMode = useBoolean()
    const playlistMode = useBoolean()

    const isSDKEmbed = useSelector((state: RootState) => state.sdkConfig.embed)
    const user = useSelector((state: RootState) => state.user)

    const orgId = user.roles?.o

    const [startTime, setStartTime] = useState<null | number>(null)
    const [serviceUsage, setServiceUsage] = useState<ServiceUsageExtType>(
        initialContext.serviceUsage
    )
    const [libraryMode, setLibraryMode] = useState<'quickAction' | undefined>(
        undefined
    )
    const isLibraryOpened = useLibraryState()

    const setPlaybookMode = playbookMode.setTrue
    const setPlaylistMode = playlistMode.setTrue

    const openPlaybookMode = useCallback(
        (startTime?: number, playerPosition?: PlayerPosition) => {
            setStartTime(startTime || null)
            setPlaybookMode()

            sendMessageToParentScript({
                action: 'RESIZE_TO_PLAYER',
                params: { playerPosition, isSDKEmbed },
            })
        },
        [setPlaybookMode, isSDKEmbed]
    )

    const openPlaylistMode = useCallback(
        (playerPosition?: PlayerPosition) => {
            setPlaylistMode()

            sendMessageToParentScript({
                action: 'RESIZE_TO_PLAYER',
                params: { playerPosition, isSDKEmbed },
            })
        },
        [setPlaylistMode, isSDKEmbed]
    )

    const openVideoPlayer = useCallback(
        (playbook, playerPosition?: PlayerPosition, startTime?: number) => {
            if (!playbook || (Array.isArray(playbook) && playbook.length === 0))
                return false

            if (EXT_MODE && libraryMode === 'quickAction') {
                // Quick action feature works only on specific domains
                // Instead of opening a playbook, it inserts it into specified input/textarea as a gif or link
                insertQuickActionResult({
                    playbook,
                    trackLinkId: uuid(),
                })
                sendMessageToParentScript({ action: 'CLOSE_IFRAME' })
            } else {
                dispatch(setPlaybook(playbook))

                if (playbook.isPlaylist) {
                    openPlaylistMode(playerPosition)
                } else {
                    openPlaybookMode(startTime, playerPosition)
                }
            }
        },
        [dispatch, libraryMode, openPlaybookMode, openPlaylistMode]
    )

    const handleUsageUpdate = useCallback(
        (usage: ServicesUsageType) => {
            const newUsage = usage
                ? {
                      isFreePlan: usage.isFreePlan,
                      isCustomPrice: usage.isCustomPrice,
                      isRecordingLocked:
                          usage.flags?.readOnly?.playbooks ||
                          usage.flags?.readOnly?.spaces,
                      usedStorage: usage.usedStorage,
                      planName: usage.planName,
                      hasScreenRecording: usage.hasScreenRecording || false,
                  }
                : initialContext.serviceUsage

            setServiceUsage(newUsage)
        },
        [setServiceUsage]
    )

    const openModalMode = useCallback(() => {
        sendMessageToParentScript({ action: 'OPEN_REC_IFRAME' })
    }, [])

    const closeModalMode = useCallback(() => {
        sendMessageToParentScript({ action: 'CLOSE_REC_IFRAME' })
    }, [])

    useEffect(() => {
        onServiceUsageUpdate(handleUsageUpdate)
        getServiceUsage(handleUsageUpdate)
        // orgId is taken internally in functions, but we need resubscribe when it changes
    }, [handleUsageUpdate, orgId])

    const contextValue = {
        playbookMode,
        playlistMode,
        startTime,
        openModalMode,
        closeModalMode,
        serviceUsage,
        openVideoPlayer,
        isLibraryOpened,
    }

    useEffect(() => {
        if (EXT_MODE && LIB_MODE) {
            onSetQuickAction(() => {
                setLibraryMode('quickAction')
            })

            if (!isLibraryOpened) {
                setLibraryMode(undefined)
            }
        }
    }, [isLibraryOpened])

    useEffect(() => {
        if (EXT_MODE) {
            // Go and watch feature that allows to play playbook on external url
            getPlayMessage(openVideoPlayer, {})
        }

        if (SDK_MODE) {
            window.addEventListener('message', event => {
                const { type, playbookId, position } = event?.data

                if (type === 'OPEN_PLAYBOOK') {
                    getPlaybook(
                        playbook => {
                            if (playbook) {
                                openVideoPlayer(playbook, position)
                                logToAnalytics('videoPlaySuccess', {
                                    playbookId,
                                })
                            } else {
                                logToAnalytics('videoPlayError', { playbookId })

                                const errMsg = `GUIDDE SDK: Could not find a video with id ${playbookId}. Please, check if the video exists and is available in current sdk space.`
                                captureSentryEvent(errMsg, true, { playbookId })

                                alert('Could not find a requested video.')
                            }
                        },
                        { playbookId }
                    )
                }
            })
        }
    }, [openVideoPlayer])

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    )
}
