/* Shows whether the library is opened or closed. */

import { useEffect, useState } from 'react'
import {
    LibraryIframeActionE,
    sendMessageToParentScript,
} from '../services/parentService'
import { useParentListener } from './use-parent-listener'

export const useLibraryState = () => {
    const [isLibraryOpened, setLibraryState] = useState<boolean>(false)

    useParentListener((msgType, data) => {
        if (msgType === LibraryIframeActionE.INIT_LIBRARY_STATE) {
            setLibraryState(Boolean(data.isOpened))
        }

        if (msgType === LibraryIframeActionE.LIB_OPENED) {
            setLibraryState(true)
        }

        if (msgType === LibraryIframeActionE.LIB_CLOSED) {
            setLibraryState(false)
        }
    })

    // Request the initial value of the library state
    useEffect(() => {
        sendMessageToParentScript({ action: 'GET_LIBRARY_STATE' })
    }, [])

    return isLibraryOpened
}
