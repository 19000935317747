/* eslint-disable no-console */
import { getAuthDomain } from './getAuthDomain'

/* In parent script, the script is located in the host page, so we can't just
 * overwrite console functions. We have to make sure console log is generated by our script. */
export const consoleSilencer = (debug: boolean = false) => {
    if (debug) return

    const noop = () => {}

    const customLog = async originalLog => {
        //index 0 - the error message
        //index 1 - this function
        //index 2 - the calling function, i.e., the actual one that did console.log()
        const stack = new Error().stack?.split('\n')
        const callingFile = stack?.[2]

        const isGuiddeLocalhost =
            Boolean(process.env.GUIDDE_LOCAL_SERVER) ||
            (process.env.NODE_ENV === 'development' &&
                process.env.REACT_APP_BUILD_TARGET === 'sdk')

        const authDomain = await getAuthDomain()
        const isSDKDomain = callingFile?.includes(`sdk.${authDomain}`)

        if (isGuiddeLocalhost || isSDKDomain) {
            return (msg, ...args) => {
                if (String(msg).includes('GUIDDE SDK:')) {
                    return originalLog(msg, ...args)
                } else {
                    return noop()
                }
            }
        } else {
            return (...args) => originalLog(...args)
        }
    }

    Object.keys(console).forEach(key => {
        if (typeof console[key] == 'function') {
            const originalLog = console[key]
            console[key] = customLog(originalLog)
        }
    })
}
