import firebase from './init'
import { getUser } from './auth'
import { collection, onSnapshot } from 'firebase/firestore'
import { captureSentryError } from '../services/sentry'

let isInitial: boolean = true
let orgUsers: Array<any> = []
let unsubFromOrgUsers: null | (() => void) = null

const orgUsersSubscription = orgId =>
    new Promise(resolve => {
        // onSnapshot will receive updates whenever org users are modified
        unsubFromOrgUsers = onSnapshot(
            collection(firebase.firestore(), `usersPerOrg/${orgId}/users/`),
            querySnapshot => {
                // Clear all previous results
                orgUsers = []

                querySnapshot.forEach(doc => {
                    orgUsers.push(doc.data())
                })

                resolve(orgUsers)
            },
            e => {
                isInitial = false // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getOrgUsers = async () => {
    const firebaseUser = getUser()

    const orgId = firebaseUser?.roles?.o
    if (!orgId) return Promise.resolve([])

    if (isInitial) {
        isInitial = false
        return orgUsersSubscription(orgId)
    }

    return Promise.resolve(orgUsers)
}

export const firebaseOrgUsersGC = () => {
    isInitial = true
    orgUsers = []
    unsubFromOrgUsers?.()
}
