import firebase from './init'
import { captureSentryError } from '../services/sentry'
import { getUser } from './auth'
import { collection, onSnapshot, query, where } from 'firebase/firestore'

let isInitial: boolean = true
let userSpaces = {}
let unsubFromUserSpaces: null | (() => void) = null

const userSpacesSubscription = uid =>
    new Promise(resolve => {
        const userSpacesQuery = query(
            collection(firebase.firestore(), 'spaces'),
            where(`members.${uid}.uid`, '==', uid)
        )
        // onSnapshot will receive updates whenever user spaces are modified
        unsubFromUserSpaces = onSnapshot(
            userSpacesQuery,
            querySnapshot => {
                // Clear all previous results
                userSpaces = {}

                querySnapshot.forEach(doc => {
                    const space = doc.data()
                    userSpaces[space.id] = space
                })

                resolve(userSpaces)
            },
            e => {
                isInitial = false // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getUserSpaces = () => {
    const firebaseUser = getUser()
    const uid = firebaseUser?.user?.uid

    if (!uid) return Promise.resolve({})

    if (isInitial) {
        isInitial = false
        return userSpacesSubscription(uid)
    }

    return Promise.resolve(userSpaces)
}

export const firebaseUserSpacesGC = () => {
    isInitial = true
    userSpaces = {}
    unsubFromUserSpaces?.()
}
