export type Recording = {
    uploadId: string
    url: string
}

export type WebcamSetting = 'disabled' | 'mini' | 'maxi'

export type RecPanelMode = 'pageCircle' | 'iframeControls'

export enum RecordingStatusE {
    STOPPED = 'stopped',
    RECORDING = 'recording',
    PROCESSING = 'processing',
}

export type RecordingStatus = `${RecordingStatusE}`
