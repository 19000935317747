import { useContext, useRef } from 'react'
import { useSelector } from 'react-redux'

import { Avatar, Badge, Box, IconButton, Link, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import SearchIcon from '@mui/icons-material/Search'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CloseIcon from '@mui/icons-material/Close'

import { ReactComponent as GuiddeLogo } from 'assets/icons/guidde_red.svg'
import { RootState } from 'ducks/rootReducer'
import { uiId } from 'services/utils'
import { sendMessageToParentScript } from 'services/parentService'
import { openExternalLink } from 'services/swMessenger'

import { NotificationsContext } from './NotificationsProvider'

const useStyles = makeStyles(theme => ({
    panel: {
        minWidth: EXT_MODE ? '56px' : '65px',
        width: EXT_MODE ? '56px' : '65px',
        height: '100%',
        background: 'white',
        boxShadow: '-1px 0 0 0 #eeeeee',
        zIndex: 6,
    },
    iconButton: {
        padding: '4px',
    },
    avatar: {
        height: '25px',
        width: '25px',
        borderRadius: '50%',
    },
    badge: {
        padding: '3px',
        minHeight: 20,
        minWidth: 20,
        textAlign: 'center',
    },
    poweredByContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    poweredByText: {
        lineHeight: 1,
        fontSize: '10px',
        color: 'gray',
    },
    spacing: {
        '& > *': {
            marginBottom: theme.spacing(2),
        },
        '& button': {
            outline: 'none',
        },
    },
}))

type Props = {
    tab: number | string
    onSetTab: (tab: number) => void
}

export const SidePanel = ({ tab, onSetTab }: Props) => {
    const classes = useStyles()

    const notificationsCount = useContext(
        NotificationsContext
    )?.notifications.filter(({ status }) => status === 'new').length

    const showNotifications = notificationsCount > 0

    const anchorRef = useRef<HTMLButtonElement | null>(null)

    const { user } = useSelector((state: RootState) => state.user)

    return (
        <>
            <Box>
                <Box
                    className={classes.panel}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt={2}
                        className={classes.spacing}
                    >
                        <IconButton
                            size="small"
                            onClick={() => {
                                sendMessageToParentScript({
                                    action: 'CLOSE_IFRAME',
                                })
                            }}
                            id={uiId('side-panel-close-btn')}
                        >
                            <CloseIcon color="primary" />
                        </IconButton>

                        {/* NEXT COMPONENTS SHOULD RENDER IN EXT MODE ONLY */}
                        {EXT_MODE && (
                            <IconButton
                                size="small"
                                onClick={() => onSetTab(0)}
                                id={uiId('side-panel-search-btn')}
                            >
                                <SearchIcon
                                    color={tab === 0 ? 'primary' : 'inherit'}
                                />
                            </IconButton>
                        )}
                    </Box>

                    <Box
                        mb={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        {EXT_MODE && (
                            <>
                                <Box my={2}>
                                    <Badge
                                        badgeContent={
                                            showNotifications
                                                ? notificationsCount
                                                : null
                                        }
                                        color="primary"
                                        overlap="circular"
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        classes={{
                                            badge: classes.badge,
                                        }}
                                    >
                                        <IconButton
                                            size="small"
                                            onClick={() => onSetTab(1)}
                                            id={uiId(
                                                'side-panel-notifications-btn'
                                            )}
                                        >
                                            <NotificationsIcon
                                                color={
                                                    tab === 1
                                                        ? 'primary'
                                                        : 'inherit'
                                                }
                                            />
                                        </IconButton>
                                    </Badge>
                                </Box>

                                <IconButton
                                    size="small"
                                    ref={anchorRef}
                                    title={`${user.displayName}\n${user.email}`}
                                    onClick={() => {
                                        openExternalLink(null, {
                                            url: `home`,
                                        })
                                    }}
                                >
                                    <Avatar
                                        src={user?.photoURL}
                                        className={classes.avatar}
                                    />
                                </IconButton>
                            </>
                        )}

                        {SDK_MODE && (
                            <Link
                                href="https://www.guidde.com"
                                target="_blank"
                                underline="hover"
                            >
                                <Box className={classes.poweredByContainer}>
                                    <Typography
                                        className={classes.poweredByText}
                                    >
                                        Powered by
                                    </Typography>
                                    <GuiddeLogo width="50px" />
                                </Box>
                            </Link>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    )
}
