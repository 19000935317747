export const initialAppsState = []

export const appsReducer = (state = initialAppsState, action) => {
    const { type, payload } = action

    if (type === 'setApps') {
        return payload ?? []
    } else {
        return state
    }
}
