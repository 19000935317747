import clsx from 'clsx'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'
import { Typography } from '@mui/material'

import { TypeUseBoolean } from 'hooks'

import {
    openBroadcastLibrary,
    openLibrary,
} from '../../../services/swMessenger'

const useStyles = makeStyles(theme => ({
    footer: { padding: theme.spacing(1.5, 4) },
    qGFooter: { backgroundColor: '#EDEEEF' },
    regularFooter: { backgroundColor: '#ffffff' },
    btn: {
        backgroundColor: '#d5d5d5',
        '&:hover': { backgroundColor: '#c5c5c5' },
    },
}))

type Props = {
    isOpenBroadcastLibraryOnClick?: boolean
    showQGRecordOnly?: boolean
    loadingState: TypeUseBoolean
    onClose: () => void
}

export const RecSettingsFooter = ({
    isOpenBroadcastLibraryOnClick,
    showQGRecordOnly,
    loadingState,
    onClose,
}: Props) => {
    const classes = useStyles()

    const handleOpenLibrary = () => {
        loadingState.setTrue()

        const successCallback = () => {
            loadingState.setFalse()
            onClose()
        }

        if (isOpenBroadcastLibraryOnClick) {
            openBroadcastLibrary(successCallback)
            return
        }

        openLibrary(successCallback, { preOpen: true })
    }

    return (
        <Box
            className={clsx(
                classes.footer,
                showQGRecordOnly ? classes.qGFooter : classes.regularFooter
            )}
        >
            <Button
                fullWidth
                variant="contained"
                disableElevation
                className={classes.btn}
                onClick={handleOpenLibrary}
            >
                <Typography
                    color="textSecondary"
                    style={{ letterSpacing: '1.25px' }}
                >
                    QUICK SEARCH
                </Typography>
            </Button>
        </Box>
    )
}
