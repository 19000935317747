const initialTagsState = {}

export const tagsReducer = (state = initialTagsState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'setTags':
            return payload
        default:
            return state
    }
}
