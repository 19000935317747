import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, LinearProgress } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import {
    PageNotifications,
    PagePlaybooks,
    PagePlaybook,
    PagePlaylist,
} from 'UI/Pages'

import { SidePanel, NotificationsContext } from 'UI/Components'

import { AppContext } from 'app/AppProvider'
import { initialUserState } from 'ducks/reducers'
import { RootState } from 'ducks/rootReducer'
import { setApps, setSpaces } from 'ducks/actions'
import { useTagsParser } from 'hooks'

import {
    getApps,
    subscribeToFirebaseUser,
    getUserSpaces,
    logToAnalytics,
    markAllNotificationsAsRead,
    onSearchFromContext,
    onSearchFromKeyboard,
    searchFromSdkApi,
} from 'services/swMessenger'
import { getSelectionText } from 'services/utils'

import { sendMessageToParentScript } from './services/parentService'
import { setSentryUser } from './services/sentry'
import {
    composeGlobalProps,
    composeUserProps,
} from './services/utilsForAnalytics'

const useStyles = makeStyles({
    tabPlaybooks: {
        width: 'max(1000px, 50vw)',
    },
    innerContent: {
        height: '100%',
        backdropFilter: 'blur(5px)',
        boxShadow: '-4px -1px 20px 0 rgba(0, 0, 0, 0.1)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        transition: 'width 0.3s linear',
    },
    notificationsWidth: {
        width: '413px',
    },
    settingsWidth: {
        width: '450px',
    },
    tabsWrapper: {
        overflow: 'auto',
        flex: '1 1 0%',
        height: '100%',
    },
    extContainer: {
        height: '100vh',
        width: '100%',
        backdropFilter: 'blur(5px)',
        background: 'rgba(255, 255, 255, 0.9)',
    },
    playContainer: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        bottom: 0,
        right: 0,
        background: 'white',
        zIndex: 10,
    },
})

const TABS_MENU = {
    playbooks: 0,
    notifications: 1,
}

export const AppLibrary = () => {
    const classes = useStyles()
    const { playbookMode, playlistMode } = useContext(AppContext)

    const isSDKEmbed = useSelector((state: RootState) => state.sdkConfig.embed)

    const { notifications } = useContext(NotificationsContext)
    const [tab, setTab] = useState<number>(TABS_MENU.playbooks)

    const changeTab = (tabIndex: number) => {
        const closedOnNotifications = tab === TABS_MENU.notifications
        const leftNotificationsTab =
            tab === TABS_MENU.notifications &&
            tabIndex !== TABS_MENU.notifications
        const newNotificationsFound = notifications.find(
            ({ status }) => status === 'new'
        )

        if (
            (closedOnNotifications || leftNotificationsTab) &&
            newNotificationsFound
        ) {
            markAllNotificationsAsRead(null, {})
        }

        // Resize drawer
        if (tabIndex === TABS_MENU.notifications) {
            sendMessageToParentScript({ action: 'RESIZE_TO_NOTIFICATIONS' })
        } else {
            sendMessageToParentScript({
                action: 'RESIZE_TO_NORMAL',
            })
        }

        setTab(tabIndex)
    }

    const dispatch = useDispatch()

    useEffect(() => {
        subscribeToFirebaseUser(payload => {
            if (!payload) {
                // user logged out let's close ext related windows
                changeTab(TABS_MENU.playbooks)
                playbookMode.setFalse()
                playlistMode.setFalse()
            }

            dispatch({
                type: 'userChanged',
                payload: payload || initialUserState,
            })

            setSentryUser({
                ...composeGlobalProps(),
                ...composeUserProps(payload),
                id: payload?.user?.uid,
                username: payload?.user?.displayName,
                email: payload?.user?.email,
            })
        })

        if (EXT_MODE) {
            getUserSpaces(spaces => dispatch(setSpaces(spaces)), null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    useEffect(() => {
        // In SDK we do not have apps filter, and we do not display app info anywhere,
        // so we do not need to fetch apps
        if (EXT_MODE) {
            getApps(response => {
                dispatch(setApps(response.data))
            })
        }
    }, [dispatch])

    // Context menu and keyboard listeners
    const defaultFilters = useTagsParser()

    const [predefinedSearch, setPredefinedSearch] = useState('')
    const triggerSearch = useCallback(selection => {
        changeTab(TABS_MENU.playbooks)
        setPredefinedSearch(selection)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (EXT_MODE) {
            onSearchFromContext(({ selection }) => {
                triggerSearch(selection)
                sendMessageToParentScript({ action: 'OPEN_IFRAME' })
                logToAnalytics('search_from_context_menu', selection)
            })

            onSearchFromKeyboard(() => {
                const selectedText = getSelectionText() || ''
                triggerSearch(selectedText)
                sendMessageToParentScript({ action: 'OPEN_IFRAME' })
                logToAnalytics('search_from_keyboard_shortcut', selectedText)
            })
        }
    }, [triggerSearch])

    useEffect(() => {
        if (EXT_MODE) {
            // If playbook/playlist is opened, set tab to default
            if (playbookMode.isTrue || playlistMode.isTrue) {
                setTab(TABS_MENU.playbooks)
            }
        }
    }, [playbookMode.isTrue, playlistMode.isTrue])

    useEffect(() => {
        // Listen to messages from parent page
        window.addEventListener('message', event => {
            const { type, value } = event?.data

            if (type === 'ON_CLICK_AWAY') {
                sendMessageToParentScript({
                    action: 'RESIZE_TO_NORMAL',
                    params: { close: true },
                })

                changeTab(TABS_MENU.playbooks)
                playbookMode.setFalse()
                playlistMode.setFalse()
            }

            if (SDK_MODE) {
                if (type === 'G_BTN_CLICKED') {
                    logToAnalytics('guidde_button_clicked')
                }
            }

            if (type === 'SEARCH_FROM_PARENT_SDK') {
                searchFromSdkApi(
                    res => {
                        sendMessageToParentScript({
                            action: 'RETURN_SEARCH_RESPONSE',
                            params: { data: res },
                        })
                    },
                    {
                        isMainSpace: false,
                        method: 'search',
                        text: value?.query || '',
                        tags: value?.tags || [],
                        size: value?.length || 5,
                        page: value?.page || 0,
                    }
                )
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Message listener should only be installed once!

    if (defaultFilters.loading)
        return (
            <Box className={classes.extContainer}>
                <LinearProgress />
            </Box>
        )

    return (
        <Box className={classes.extContainer}>
            <Box display="flex" flexDirection="row" height="100%">
                <Box className={classes.tabsWrapper}>
                    {tab === TABS_MENU.playbooks && (
                        <PagePlaybooks
                            defaultFilters={defaultFilters}
                            predefinedSearch={predefinedSearch}
                        />
                    )}

                    {EXT_MODE && (
                        <>
                            {tab === TABS_MENU.notifications && (
                                <PageNotifications />
                            )}
                        </>
                    )}
                </Box>

                {EXT_MODE && <SidePanel tab={tab} onSetTab={changeTab} />}
                {SDK_MODE && !isSDKEmbed && (
                    <SidePanel tab={tab} onSetTab={changeTab} />
                )}
            </Box>

            {/* Play playbook page */}
            {playbookMode.isTrue && (
                <Box className={classes.playContainer}>
                    <PagePlaybook />
                </Box>
            )}

            {/* Play playlist page */}
            {playlistMode.isTrue && (
                <Box className={classes.playContainer}>
                    <PagePlaylist />
                </Box>
            )}
        </Box>
    )
}
