import { createStore } from 'redux'
import { rootReducer } from './rootReducer'

const initStore = () => {
    const initialState = {}

    return createStore(
        rootReducer,
        initialState,
        (window as any).__REDUX_DEVTOOLS_EXTENSION__?.()
    )
}

export const store = initStore()
