import { collection, onSnapshot } from 'firebase/firestore'

import { getUser } from './auth'
import firebase from './init'
import { captureSentryError } from '../services/sentry'
import { App } from '../types'

let isInitial: boolean = true
let isReceivedSubscriptionResponse: boolean = false

let appIds: Array<string> = [] // App IDs that are enabled for this org
let allApps: Array<App> = [] // All the apps that exist in the DB
let orgApps: Array<App> = [] // Apps that can be used by this org

let unsubFromOrgApps: null | (() => void) = null
let unsubFromAllApps: null | (() => void) = null

const recalculateOrgApps = () => {
    orgApps = allApps.filter(app => appIds.includes(app.id))
    return orgApps
}

const appsSubscription = () =>
    new Promise(resolve => {
        // onSnapshot will receive updates whenever an app is added/deleted/modified
        unsubFromAllApps = onSnapshot(
            collection(firebase.firestore(), `applications`),
            querySnapshot => {
                // Clear all previous results
                allApps = []

                querySnapshot.forEach(doc => {
                    allApps.push(<App>doc.data())
                })

                // App data changed, recalculate org apps
                recalculateOrgApps()
                resolve(allApps)
            },
            e => {
                captureSentryError(e)
            }
        )
    })

const orgAppsSubscription = orgId =>
    new Promise(resolve => {
        // onSnapshot will receive updates whenever an app is added/deleted/modified
        unsubFromOrgApps = onSnapshot(
            collection(
                firebase.firestore(),
                `usersPerOrg/${orgId}/applications`
            ),
            querySnapshot => {
                // Clear all previous results
                appIds = []

                querySnapshot.forEach(doc => {
                    appIds.push(doc.id)
                })

                // App data changed, recalculate org apps
                recalculateOrgApps()
                isReceivedSubscriptionResponse = true
                resolve(appIds)
            },
            e => {
                captureSentryError(e)
            }
        )
    })

export const getFirebaseApps: () => Promise<App[]> = async () => {
    const firebaseUser = getUser()

    const orgId = firebaseUser?.roles?.o
    if (!orgId) return Promise.resolve([])

    if (isInitial) {
        isInitial = false
        return Promise.all([appsSubscription(), orgAppsSubscription(orgId)])
            .then(() => orgApps)
            .catch(e => {
                isInitial = true // Something went wrong on app fetch, retry another time
                captureSentryError(e)
                return []
            })
    }

    // Means that subscription is being established, retry in 1 second
    if (!isReceivedSubscriptionResponse) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(getFirebaseApps())
            }, 1000)
        })
    }

    return Promise.resolve(orgApps)
}

// Garbage collector to remove all subscriptions and reset everything to initial
export const firebaseAppsGC = () => {
    isInitial = true
    isReceivedSubscriptionResponse = false
    appIds = []
    allApps = []
    orgApps = []

    unsubFromOrgApps?.()
    unsubFromAllApps?.()
}
