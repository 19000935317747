import { Dialog, Theme, Box, Divider, IconButton } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'

import captureSteps from 'assets/images/capture-steps.png'
import Button from '@mui/material/Button'
import { SpacedGroup } from './SpacedGroup'
import CloseIcon from '@mui/icons-material/Close'

type RecordingErrorDialogProps = {
    isModalOpen: boolean
    onClose: () => void
    onRestart: () => void
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontWeight: 700,
            fontSize: '34px',
            lineHeight: '42px',
            textAlign: 'center',
        },
        body: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',
        },
        divider: {
            background: 'rgba(9, 12, 16, 0.08)',
        },
        closeButton: {
            position: 'absolute',
            right: 0,
            top: 0,
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
    })
)

export const RecordingErrorDialog = ({
    isModalOpen,
    onClose,
    onRestart,
}: RecordingErrorDialogProps) => {
    const classes = useStyles()

    return (
        <Dialog open={isModalOpen} fullWidth maxWidth="sm" onClose={onClose}>
            <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
                size="large"
            >
                <CloseIcon />
            </IconButton>
            <SpacedGroup flexDirection="column" id="container">
                <Box p={4} id="containerBox">
                    <Box className={classes.title} my={3} id="headerText">
                        Uh oh, looks like <br /> something went wrong.
                    </Box>

                    <Box style={{ textAlign: 'center' }}>
                        <img
                            alt="logo"
                            title="Capture steps"
                            src={captureSteps}
                        />
                    </Box>

                    <Box className={classes.body} my={2} id="bodyText">
                        We’re sorry, there was an issue processing your video.
                        Please try again or contact support for assistance.
                    </Box>
                </Box>

                <Box width="100%" mt={0.5}>
                    <Divider classes={{ root: classes.divider }} />
                </Box>
            </SpacedGroup>
            <Box py={2} px={3}>
                <SpacedGroup justifyContent="flex-end">
                    <Button
                        onClick={onRestart}
                        color="primary"
                        variant="contained"
                        style={{
                            letterSpacing: '1.25px',
                            lineHeight: '16px',
                            fontSize: '14px',
                            fontWeight: '500',
                        }}
                    >
                        RESTART CAPTURE
                    </Button>
                </SpacedGroup>
            </Box>
        </Dialog>
    )
}
