import { useState, memo } from 'react'
import { useSelector } from 'react-redux'

import { Box, Tab, Tabs } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { RootState } from 'ducks/rootReducer'
import { InfoTab, Playlist } from './tabs'
import { Transcript } from 'UI/Components/Transcript/Transcript'
import theme from 'app/theme'
import { PlaybookType } from 'types'

const useStyles = makeStyles({
    tab: {
        minWidth: '140px',
    },
    tabPanel: {
        '& > div': {
            padding: theme.spacing(0, 3, 3, 3),
        },
    },
})

const TabPanel = props => {
    const { children, value, index, ...other } = props

    return (
        <Box hidden={value !== index} {...other}>
            <Box p={3}>{children}</Box>
        </Box>
    )
}

type Props = {
    playbook: PlaybookType
    startTime?: number
    transcript?: any
}

export const SidePanel = memo(({ transcript }: Props) => {
    const classes = useStyles()

    const [tabValue, setTabValue] = useState(0)

    const handleTabChange = (_, newValue) => {
        setTabValue(newValue)
    }

    const { playbookList, active } = useSelector(
        (state: RootState) => state.playlist
    )

    const playbook = playbookList?.[active]

    const { files, links, transcriptUrl } = playbook || {}

    const isInfoVisible = !!(files?.length || links?.length)

    return (
        <Box p={2} height="100%">
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant={isInfoVisible ? 'scrollable' : 'standard'}
            >
                <Tab label="Playlist" className={classes.tab} />
                <Tab label="Transcript" className={classes.tab} />
                {isInfoVisible && <Tab label="Info" className={classes.tab} />}
            </Tabs>

            <TabPanel value={tabValue} index={0}>
                <Playlist />
            </TabPanel>

            <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
                <Transcript
                    playbook={playbook}
                    transcriptUrl={transcriptUrl}
                    transcript={transcript}
                />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <InfoTab
                    files={files}
                    links={links}
                    isInfoVisible={isInfoVisible}
                />
            </TabPanel>
        </Box>
    )
})
