/* This is a firebase init script.
   Firebase libraries should be imported only here and later re-used across the project.

   IMPORTANT!
   Firebase was updated from v8 to v9 in compatibility (compat) mode.
   Compat mode will be removed in the future major version of firebase.
   Use new (modular) approach to create new functionality.

   TODO: Migrate from v9 compat mode to v9 modular syntax.
   Read: https://firebase.google.com/docs/web/modular-upgrade
*/

import firebase from 'firebase/compat/app'

/* Use only the modules you actually need in the project. */
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/database'

// IMPORTANT! Do not use 'firebase/storage' module.
// File upload uses XHR and you won't be able to use it in the background (service worker restrictions).
// Read: https://github.com/firebase/firebase-js-sdk/issues/6595

import Config from '../config'
import { captureSentryError } from '../services/sentry'
import {
    getFromExtLocalStorage,
    setToExtLocalStorage,
} from '../services/extServices'
import { FIREBASE_AUTHDOMAIN } from '../background/constants'
import { logoutFirebase } from './auth'

// By default, we initialize firebase with the main config (US region)
let defaultApp = firebase.initializeApp(Config.firebase)

if (EXT_MODE) {
    setToExtLocalStorage(FIREBASE_AUTHDOMAIN, Config.firebase.authDomain)
}

const reInitFirebaseApp = firebaseConfig =>
    new Promise<boolean>(resolve =>
        defaultApp
            .delete()
            .then(() => {
                defaultApp = firebase.initializeApp(firebaseConfig)
                setToExtLocalStorage(
                    FIREBASE_AUTHDOMAIN,
                    firebaseConfig.authDomain
                )
                resolve(true)
            })
            .catch(e => {
                alert(
                    'Could not log in to Guidde extension. Please contact support.'
                )
                captureSentryError(e)
                resolve(false)
            })
    )

export const validateFirebaseApp = (senderOrigin: string) =>
    new Promise<boolean>(resolve => {
        if (EXT_MODE) {
            const bgScriptUtils = require('../background/authUtils')
            const { onAuthStateChange } = bgScriptUtils

            getFromExtLocalStorage([FIREBASE_AUTHDOMAIN], result => {
                const prevAuthDomain = result[FIREBASE_AUTHDOMAIN]
                const tokenOriginChanged =
                    prevAuthDomain && !senderOrigin.includes(prevAuthDomain)

                // Case 1: user tries to log in from EU env
                // (or he was logged in with EU and tries to log in from US)
                if (tokenOriginChanged) {
                    const firebaseConfig =
                        senderOrigin === `https://${Config.eu.authDomain}`
                            ? Config.eu
                            : Config.firebase

                    // First, log out user to clear all firebase data on user
                    logoutFirebase().then(() => {
                        // Re-init firebase app with correct config
                        reInitFirebaseApp(firebaseConfig).then(reInitResult => {
                            onAuthStateChange()
                            resolve(reInitResult)
                        })
                    })
                } else {
                    // Case 2: user logs in from the same env
                    resolve(true)
                }
            })
        }
    })

export default firebase
