import { useEffect, useMemo, useState } from 'react'
import { sendMessageToParentScript } from '../services/parentService'

export const useParentLocation = () => {
    const [location, setLocation] = useState<Location | undefined>(undefined)

    useEffect(() => {
        window.addEventListener('message', event => {
            const { type, location } = event?.data

            if (type === 'URL_CHANGED' && location) {
                const parsedLocation = JSON.parse(location)
                setLocation(parsedLocation)
            }
        })

        // Request location for the first time in case event listener was established after the load
        sendMessageToParentScript({ action: 'GET_LOCATION' })
    }, [])

    return useMemo(() => location, [location])
}
