import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import makeStyles from '@mui/styles/makeStyles'

import select from 'assets/icons/qg-select.svg'
import click from 'assets/icons/qg-click.svg'
import stop from 'assets/icons/qg-stop.svg'

import { SpacedGroup } from './SpacedGroup'

const QG_STEPS = [
    { value: 'select', icon: select, text: 'Select', subText: 'a tab' },
    {
        value: 'click',
        icon: click,
        text: 'Click',
        subText: 'through your flow',
    },
    { value: 'stop', icon: stop, text: 'Stop', subText: 'when you finish' },
]

const useStyles = makeStyles({
    backdrop: { backdropFilter: 'blur(5px)' },
    title: {
        color: '#ffffff',
        fontSize: '48px',
        fontWeight: 700,
    },
    img: {
        width: '210px',
    },
    text: {
        marginTop: '24px',
        color: '#ffffff',
        fontSize: '32px',
        fontWeight: 700,
        lineHeight: '1',
    },
    subText: { color: '#ffffff', fontSize: '24px', fontWeight: 400 },
})

export const QuickGuiddeStart = ({ isFirstQG, onClose }) => {
    const classes = useStyles()

    return (
        <Backdrop open className={classes.backdrop} onClick={onClose}>
            <Box display="flex" flexDirection="column" alignItems="center">
                <span className={classes.title}>Capture is about to start</span>

                {isFirstQG && (
                    <SpacedGroup spacing={9} mt={8}>
                        {QG_STEPS.map(step => (
                            <Box
                                key={step.value}
                                display="flex"
                                flexDirection="column"
                                alignItems="center"
                            >
                                <img
                                    className={classes.img}
                                    src={step.icon}
                                    alt={step.value}
                                />
                                <span className={classes.text}>
                                    {step.text}
                                </span>
                                <span className={classes.subText}>
                                    {step.subText}
                                </span>
                            </Box>
                        ))}
                    </SpacedGroup>
                )}
            </Box>
        </Backdrop>
    )
}
