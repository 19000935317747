export const initialPlaylistState = {
    active: 0,
    playbookList: null,
}

export const playlistReducer = (state = initialPlaylistState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'setPlaybookList':
            return { ...state, playbookList: payload }
        case 'setActivePlaybook':
            return { ...state, active: payload }
        case 'resetPlaylist':
            return initialPlaylistState
        default:
            return state
    }
}
