import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'

import { DEV_EXT_ID } from 'background/constants'
import { isLocal } from 'services/environmentService'
import { captureSentryError } from 'services/sentry'

import { MicIcon } from './MicIcon'

const BTN_SIZE = 160
const RING_SIZE = 30

const useStyles = makeStyles(() => ({
    miccamWrapper: {
        position: 'fixed',
        left: '50px',
        bottom: '50px',
    },
    webcamPreview: {
        position: 'absolute',
        top: `${RING_SIZE}px`,
        left: `${RING_SIZE}px`,
        height: '160px',
        width: '160px',
        borderRadius: '50%',
        border: 'none',
    },
    micIcon: {
        position: 'absolute',
        top: '0',
        left: '0',
    },
    circle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        transition: 'background-color 0.3s',
        backgroundColor: `rgba(254, 210, 47, 0.1)`,
    },
    outerRing: {
        width: `${BTN_SIZE + 2 * RING_SIZE}px`,
        height: `${BTN_SIZE + 2 * RING_SIZE}px`,
    },
    grayscale: {
        filter: 'grayscale(1)',
    },
    middleRing: {
        width: `${BTN_SIZE + RING_SIZE}px`,
        height: `${BTN_SIZE + RING_SIZE}px`,
    },
    innerCircle: {
        width: `${BTN_SIZE}px`,
        height: `${BTN_SIZE}px`,
        fontWeight: 500,
        outline: 'none',
        border: 'none',
    },
}))

const CIRCLE_AMOUNT = 10
const CIRCLE_ARRAY = new Array(CIRCLE_AMOUNT).fill('')

export const MicCamPreview = ({ mic, webcam }) => {
    const classes = useStyles()
    const isMicActive = useMemo(() => mic && mic !== 'mute', [mic])
    const isWebcamActive = useMemo(
        () => webcam && webcam !== 'noCamera',
        [webcam]
    )

    const streamRef = useRef<MediaStream | null>(null)
    const [volume, setVolume] = useState(0)

    const connectToMic = useCallback(() => {
        removeTracks()

        if (!isMicActive) return

        // Connect to microphone and use the volume value on VolumeMeter component
        navigator.mediaDevices
            .getUserMedia({
                audio: { deviceId: mic },
            })
            .then(stream => {
                streamRef.current = stream

                const audioContext = new AudioContext()
                const analyser = audioContext.createAnalyser()
                const microphone = audioContext.createMediaStreamSource(stream)
                const scriptProcessor = audioContext.createScriptProcessor(
                    2048,
                    1,
                    1
                )

                analyser.smoothingTimeConstant = 0.8
                analyser.fftSize = 1024

                microphone.connect(analyser)
                analyser.connect(scriptProcessor)
                scriptProcessor.connect(audioContext.destination)
                scriptProcessor.onaudioprocess = function () {
                    const array = new Uint8Array(analyser.frequencyBinCount)
                    analyser.getByteFrequencyData(array)
                    const arraySum = array.reduce((a, value) => a + value, 0)
                    const average = arraySum / array.length
                    setVolume(Math.round((average * 100) / 127))
                }
            })
            .catch(function (err) {
                captureSentryError(err)
            })
    }, [isMicActive, mic])

    // Disconnect from microphone track
    const removeTracks = () => {
        streamRef?.current?.getTracks().forEach(track => {
            track.stop()
        })

        streamRef.current = null
    }

    useEffect(() => {
        connectToMic()

        return () => {
            removeTracks()
        }
    }, [connectToMic])

    if (!isMicActive && !isWebcamActive) return null

    const extId = isLocal ? DEV_EXT_ID : chrome?.runtime?.id

    return (
        <div className={classes.miccamWrapper}>
            <div
                className={clsx(classes.circle, classes.outerRing, {
                    [classes.grayscale]: !isMicActive,
                })}
            >
                <div className={clsx(classes.circle, classes.middleRing)}>
                    <div
                        className={clsx(classes.circle, classes.innerCircle)}
                    ></div>
                </div>
            </div>

            {isMicActive &&
                CIRCLE_ARRAY.map((_, index) => {
                    const size =
                        BTN_SIZE + index * ((2 * RING_SIZE) / CIRCLE_AMOUNT)
                    const isActive = volume / CIRCLE_AMOUNT > index

                    return (
                        <div
                            key={index}
                            style={{
                                position: 'absolute',
                                top: `calc(50% - ${size / 2}px)`,
                                left: `calc(50% - ${size / 2}px)`,
                                width: `${size}px`,
                                height: `${size}px`,
                                backgroundColor: isActive
                                    ? `rgba(254, 210, 67, 0.3)`
                                    : `rgba(254, 210, 67, 0)`,
                                borderRadius: '50%',
                                transition: 'background-color 0.2s',
                            }}
                        />
                    )
                })}

            {isWebcamActive ? (
                <iframe
                    className={classes.webcamPreview}
                    title="webcam"
                    src={`chrome-extension://${extId}/webcam.html?mode=webcam&deviceId=${webcam}`}
                    allow={`camera chrome-extension://${extId};microphone chrome-extension://${extId}`}
                />
            ) : (
                <MicIcon className={classes.micIcon} />
            )}
        </div>
    )
}
