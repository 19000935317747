import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useRef,
    useState,
    memo,
    useDeferredValue,
} from 'react'
import { useSelector } from 'react-redux'

import {
    Box,
    ClickAwayListener,
    Divider,
    FormControl,
    List,
    ListItem,
    Paper,
    Popper,
    TextField,
    Typography,
    Link as MaterialLink,
    IconButton,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { RootState } from 'ducks/rootReducer'

import { useBoolean, useTextDebounce } from 'hooks'
import { getPlaybooks, logToAnalytics } from 'services/swMessenger'
import { AppContext } from 'app/AppProvider'

import GifIcon from '@mui/icons-material/Gif'

import { isMainSpace, generateSpaceIds } from 'UI/Pages/playbooks/PagePlaybooks'
import { TranscriptList } from './TranscriptList'

import { uiId } from 'services/utils'
import { sendMessageToParentScript } from 'services/parentService'
import { playbookToAnalyticsProps } from 'services/analytics'
import { copyGif } from 'services/copyGif'

const useStyles = makeStyles(theme => ({
    popper: {
        width: '100%',
        maxWidth: '1200px',
        zIndex: 100001,
    },
    paper: {
        maxHeight: '600px',
        overflow: 'overlay',
    },
    label: {
        color: '#78909c',
        lineHeight: 1,
        marginBottom: theme.spacing(1),
        fontSize: theme.typography.pxToRem(14),
    },
    listItemLink: {
        color: '#212121',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        cursor: 'pointer',
        background: 'transparent',
        minHeight: 26,
        marginBottom: '-10px',
        '& #gif-btn': {
            visibility: 'hidden',
        },
        '&:hover #gif-btn': {
            visibility: 'visible',
        },
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    resultTitle: {
        verticalAlign: 'middle',
    },
    inputBase: {
        flexDirection: 'row',
    },
    inputRoot: {
        '& input': {
            width: '100%',
        },
    },
    flexCenter: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputLabel: {
        padding: 0,
        margin: 0,
    },
    creatorLabel: {
        color: '#78909c',
        fontSize: theme.typography.pxToRem(12),
        marginLeft: '8px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '130px',
    },
    timeLabel: {
        color: '#78909c',
        marginRight: '8px',
        whiteSpace: 'nowrap',
        fontSize: theme.typography.pxToRem(10),
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '630px',
        fontWeight: 'bold',
    },
}))

const pageSize = 5

type SearchPageProps = {
    index: number
    text: string
    hasMore?: {
        isTrue: boolean
        isFalse: boolean
        setTrue: () => void
        setFalse: () => void
    }
    applicationId: string
    spaceIds: string[]
    playbookClicked: () => void
}

const SearchPage = memo(
    ({
        index,
        text,
        hasMore,
        applicationId,
        spaceIds,
        playbookClicked,
    }: SearchPageProps) => {
        const classes = useStyles()

        const deferredText = useDeferredValue(text)

        const { user } = useSelector((state: RootState) => state.user)

        const { openVideoPlayer } = useContext(AppContext)

        const [data, setData] = useState<any>(null)

        useEffect(() => {
            getPlaybooks(setData, {
                size: pageSize,
                page: index,
                text: deferredText,
                ...(EXT_MODE ? { apps: applicationId } : {}),
                method: 'search',
                isMainSpace: isMainSpace(spaceIds),
                spaceIds: generateSpaceIds(spaceIds),
                sendLogEvent: true,
                sortBy: 'date',
            })
        }, [index, deferredText, applicationId, spaceIds, user.uid])

        const playbooks = data?.playbooks
        const hitsNumber = data?.nbHits

        const handleClick = playbook => {
            openVideoPlayer(playbook)
            playbookClicked()
        }

        const handleCopyGif = (e, playbook) => {
            e.stopPropagation()
            e.preventDefault()

            copyGif({
                playbook,
                uid: user.uid,
            }).then(() => {
                logToAnalytics('copyLink', {
                    context: 'copy-linked-gif',
                    ...playbookToAnalyticsProps(playbook),
                })

                playbookClicked()
                sendMessageToParentScript({ action: 'CLOSE_IFRAME' })
            })
        }

        const renderResultBox = () => {
            if (playbooks?.length === 0) {
                return (
                    <Box p={index === 0 ? 0 : 2}>
                        <Typography>No Match Found</Typography>
                    </Box>
                )
            }

            if (playbooks?.length) {
                return (
                    <>
                        {index === 0 && (
                            <Typography className={classes.label}>
                                Videos ({hitsNumber})
                            </Typography>
                        )}
                        <List component="div">
                            {playbooks.map(playbook => {
                                if (
                                    playbook._snippetResult?.transcript
                                        ?.matchLevel !== 'none'
                                ) {
                                    return (
                                        <ListItem
                                            className={classes.listItem}
                                            key={playbook.id}
                                        >
                                            <Box display="flex">
                                                <Box
                                                    display="flex"
                                                    alignItems="flex-start"
                                                >
                                                    <MaterialLink
                                                        key={playbook.id}
                                                        className={
                                                            classes.listItemLink
                                                        }
                                                        onClick={() => {
                                                            handleClick(
                                                                playbook
                                                            )
                                                        }}
                                                        underline="hover"
                                                    >
                                                        <Box mr={2}>
                                                            <img
                                                                style={{
                                                                    width: '100px',
                                                                }}
                                                                src={
                                                                    playbook.img
                                                                }
                                                                alt="thumbnail"
                                                            />
                                                        </Box>
                                                    </MaterialLink>
                                                    <Box
                                                        display="flex"
                                                        alignItems="left"
                                                        flexDirection="column"
                                                        mr={2}
                                                    >
                                                        <MaterialLink
                                                            key={playbook.id}
                                                            className={
                                                                classes.listItemLink
                                                            }
                                                            onClick={() => {
                                                                handleClick(
                                                                    playbook
                                                                )
                                                            }}
                                                            underline="hover"
                                                        >
                                                            <Box>
                                                                <Box
                                                                    display="flex"
                                                                    alignItems="center"
                                                                >
                                                                    <Typography
                                                                        noWrap
                                                                        className={
                                                                            classes.title
                                                                        }
                                                                        component="span"
                                                                    >
                                                                        {
                                                                            playbook.title
                                                                        }
                                                                    </Typography>
                                                                    <Typography
                                                                        noWrap
                                                                        className={
                                                                            classes.creatorLabel
                                                                        }
                                                                        component="span"
                                                                    >
                                                                        {
                                                                            playbook.creator_name
                                                                        }
                                                                    </Typography>
                                                                </Box>
                                                                <IconButton
                                                                    size="small"
                                                                    id="gif-btn"
                                                                    onClick={e =>
                                                                        handleCopyGif(
                                                                            e,
                                                                            playbook
                                                                        )
                                                                    }
                                                                >
                                                                    <GifIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </MaterialLink>
                                                        <Box>
                                                            <TranscriptList
                                                                playbook={
                                                                    playbook
                                                                }
                                                                classes={
                                                                    classes
                                                                }
                                                                onPbClick={
                                                                    playbookClicked
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <MatchedTags playbook={playbook} />
                                        </ListItem>
                                    )
                                } else {
                                    return (
                                        <ListItem key={playbook.id}>
                                            <MaterialLink
                                                key={playbook.id}
                                                className={classes.listItemLink}
                                                onClick={() => {
                                                    handleClick(playbook)
                                                }}
                                                underline="hover"
                                            >
                                                <Box>
                                                    <Box display="flex">
                                                        <Box
                                                            mr={2}
                                                            display="inline"
                                                        >
                                                            <img
                                                                style={{
                                                                    width: '100px',
                                                                }}
                                                                src={
                                                                    playbook.img
                                                                }
                                                                alt="thumbnail"
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Typography
                                                                noWrap
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: playbook
                                                                        ._snippetResult
                                                                        ?.title
                                                                        ?.value,
                                                                }}
                                                                component="div"
                                                            />
                                                            <IconButton
                                                                size="small"
                                                                id="gif-btn"
                                                                onClick={e =>
                                                                    handleCopyGif(
                                                                        e,
                                                                        playbook
                                                                    )
                                                                }
                                                            >
                                                                <GifIcon />
                                                            </IconButton>
                                                        </Box>

                                                        <Typography
                                                            noWrap
                                                            className={
                                                                classes.creatorLabel
                                                            }
                                                            style={{
                                                                marginTop:
                                                                    '3px',
                                                            }}
                                                            component="span"
                                                        >
                                                            {
                                                                playbook.creator_name
                                                            }
                                                        </Typography>
                                                    </Box>

                                                    <MatchedTags
                                                        playbook={playbook}
                                                    />
                                                </Box>
                                            </MaterialLink>
                                        </ListItem>
                                    )
                                }
                            })}
                        </List>
                        <Box my={1}>
                            <Divider />
                        </Box>
                    </>
                )
            }

            return (
                <Box p={index === 0 ? 0 : 2}>
                    <Typography>Loading...</Typography>
                </Box>
            )
        }

        useEffect(() => {
            if (hasMore !== undefined) {
                if (hitsNumber && hitsNumber > (index + 1) * pageSize) {
                    hasMore.setTrue()
                } else {
                    hasMore.setFalse()
                }
            }
        }, [hasMore, hitsNumber, index])

        return <>{renderResultBox()}</>
    }
)

type MatchedTagsProps = {
    playbook: {
        _snippetResult?: {
            tags?: Array<{
                matchLevel: string
                value: string
            }>
        }
    }
}
const MatchedTags = memo((props: MatchedTagsProps) => {
    const matchedResult = props.playbook._snippetResult?.tags?.find(
        tag => tag.matchLevel === 'full'
    )?.value

    if (!matchedResult) return null

    return (
        <Box ml={1} mr={2}>
            <Typography
                style={{
                    fontWeight: 'normal',
                }}
                dangerouslySetInnerHTML={{
                    __html: `#${matchedResult}`,
                }}
                component="span"
            />
        </Box>
    )
})

type Props = {
    predefinedSearch: string
    appFilter: {
        state: string
        defaultState: string | null
    }
    onSearchChange?: Dispatch<SetStateAction<null | string>>
    spaceIds: string[]
}

export const PlaybookSearch = memo(
    ({ predefinedSearch, appFilter, onSearchChange, spaceIds }: Props) => {
        const classes = useStyles()
        const anchorRef = useRef(null)
        const inputRef = useRef<HTMLInputElement>(null)
        const { isLibraryOpened } = useContext(AppContext)

        const [text, setText] = useState('')
        const debouncedText = useTextDebounce(text)

        const open = useBoolean()

        const hasMore = useBoolean()

        const [page, setPage] = useState(0)

        const getAppId = () => appFilter.state || appFilter.defaultState || ''

        const pages: Array<JSX.Element> = []

        const handlePlaybookClick = () => {
            open.setFalse()
        }

        for (let i = 0; i <= page; i++) {
            if (debouncedText?.length > 2) {
                pages.push(
                    <SearchPage
                        index={i}
                        key={i}
                        text={debouncedText}
                        hasMore={i === page ? hasMore : undefined}
                        applicationId={getAppId()}
                        spaceIds={spaceIds}
                        playbookClicked={handlePlaybookClick}
                    />
                )
            }
        }

        const handleChange = e => {
            e.persist()
            setText(e.target?.value)
            setPage(0)
        }

        const handleClick = () => {
            if (debouncedText?.length > 2) {
                open.setTrue()
            }
        }

        useEffect(() => {
            onSearchChange?.(debouncedText)
        }, [debouncedText, onSearchChange])

        useEffect(() => {
            if (isLibraryOpened) {
                inputRef?.current?.focus()
            } else {
                inputRef?.current?.blur()
            }
        }, [isLibraryOpened])

        useEffect(() => {
            setText(predefinedSearch || '')
        }, [predefinedSearch])

        const { setTrue, setFalse } = open

        useEffect(() => {
            if (debouncedText?.length > 2) {
                setTrue()
            } else {
                setFalse()
            }
        }, [debouncedText, setFalse, setTrue])

        return (
            <Box mb={2} style={{ position: 'relative' }}>
                <FormControl variant="standard" fullWidth>
                    <TextField
                        variant="standard"
                        ref={anchorRef}
                        inputRef={inputRef}
                        value={text}
                        id={uiId('search')}
                        placeholder="Search for anything"
                        onClick={handleClick}
                        onChange={handleChange}
                        InputProps={{
                            classes: {
                                root: classes.inputRoot,
                                formControl: classes.inputBase,
                            },
                        }}
                        InputLabelProps={{
                            className: classes.inputLabel,
                        }}
                    />
                </FormControl>

                <Popper
                    open={open.isTrue}
                    anchorEl={anchorRef?.current}
                    placement="bottom-start"
                    disablePortal
                    className={classes.popper}
                    modifiers={[
                        {
                            name: 'flip',
                            enabled: false,
                        },
                        {
                            name: 'preventOverflow',
                            enabled: true,
                            options: { boundariesElement: 'scrollParent' },
                        },
                    ]}
                >
                    <ClickAwayListener
                        onClickAway={() => {
                            setPage(0)
                            open.setFalse()
                        }}
                    >
                        <Paper elevation={3} className={classes.paper}>
                            <Box p={2} pb={1}>
                                {pages}
                            </Box>
                            {debouncedText?.length > 2 && hasMore.isTrue && (
                                <Box ml={4} mb={2} display="inline-block">
                                    <MaterialLink
                                        className={classes.listItemLink}
                                        onClick={event => {
                                            event.preventDefault()
                                            setPage(prevState => prevState + 1)
                                        }}
                                        underline="hover"
                                    >
                                        <Typography component="span">
                                            Show more
                                        </Typography>
                                    </MaterialLink>
                                </Box>
                            )}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </Box>
        )
    }
)
