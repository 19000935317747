import { registerGCItem } from './contentGarbageCollector'

// Set maxRetries to "-1" if you don't want to clear interval
export const awaitDOMElement = (selector: string, maxRetries?: number) =>
    new Promise<HTMLElement | null>(resolve => {
        const element = document.querySelector<HTMLElement>(selector)

        if (element) {
            resolve(element)
            return
        }

        const maxAttempts = maxRetries || 5
        let attempt = 0

        const interval = window.setInterval(() => {
            const element = document.querySelector<HTMLElement>(selector)

            if (element) {
                resolve(element)
                clearInterval(interval)
            }

            if (attempt === maxAttempts) {
                resolve(null)
                clearInterval(interval)
            }

            attempt = attempt + 1
        }, 1000)

        registerGCItem('intervals', interval)
    })

export const createShadowRoot = (
    tagName: string
): [HTMLElement, ShadowRoot] => {
    const shadowContainer: HTMLElement = document.createElement(tagName)
    const shadow: ShadowRoot = shadowContainer.attachShadow({ mode: 'open' })

    return [shadowContainer, shadow]
}

type WrapElementInShadowProps = {
    element: HTMLElement
    shadowTagName: string
    shadowId: string
}
export const wrapElementInShadow = ({
    element,
    shadowId,
    shadowTagName,
}: WrapElementInShadowProps) => {
    const [shadowContainer, shadowRoot] = createShadowRoot(shadowTagName)
    shadowContainer.id = shadowId
    shadowRoot.appendChild(element)

    return { shadowContainer, shadowRoot }
}

type AttachStylesheetToShadowProps = {
    css: string
    shadowRoot: ShadowRoot
}
export const attachStyleSheetToShadow = ({
    shadowRoot,
    css,
}: AttachStylesheetToShadowProps) => {
    const styleSheet = new CSSStyleSheet()
    // Current project ts version doesn't contain proper type for CSSStyleSheet
    ;(styleSheet as any).replaceSync(css)
    ;(shadowRoot as any).adoptedStyleSheets.push(styleSheet)
}

type InjectDOMElementIntoHtmlProps = {
    html: HTMLElement
    element: HTMLElement
}
export const injectDOMElementIntoHtml = ({
    html,
    element,
}: InjectDOMElementIntoHtmlProps) => {
    html.appendChild(element)
    registerGCItem('injections', element)
}

export const injectElementAsShadowIntoHtml = ({
    html,
    element,
    shadowId,
    shadowTagName,
    css,
}: WrapElementInShadowProps &
    InjectDOMElementIntoHtmlProps & {
        css?: string
    }) => {
    const { shadowContainer, shadowRoot } = wrapElementInShadow({
        element,
        shadowId,
        shadowTagName,
    })

    if (css) attachStyleSheetToShadow({ css, shadowRoot })

    injectDOMElementIntoHtml({ html, element: shadowContainer })

    return { shadowContainer, shadowRoot }
}

export const convertPixelsToViewportHeightUnits = (pixels: number) => {
    const viewportHeight = (pixels / window.innerHeight) * 100

    return `${viewportHeight}vh`
}
