import Alert from '@mui/material/Alert'
import makeStyles from '@mui/styles/makeStyles'

import { PermissionStatusE } from 'types'

const useStyles = makeStyles(() => ({
    alert: {
        width: '100%',
        position: 'fixed',
        top: '0',
        left: '0',
        textAlign: 'center',
        justifyContent: 'center',
        borderRadius: '0',
        background: '#FED243',
        color: '#090C10',
        fontSize: '18px',
        fontWeight: 400,
    },
}))

type Props = {
    permissions?: `${PermissionStatusE}`
}

export const PermissionAlerts = ({ permissions }: Props) => {
    const classes = useStyles()

    if (
        !permissions ||
        permissions === PermissionStatusE.PERMISSIONS_GRANTED ||
        permissions === PermissionStatusE.QG_BLOCKED // For this case we have a separate screen
    ) {
        return null
    }

    return (
        <Alert className={classes.alert} severity="warning" variant="filled">
            Guidde can not use your camera
            {permissions === PermissionStatusE.PERMISSIONS_DENIED ||
            permissions === PermissionStatusE.UNKNOWN_ERROR
                ? ' and microphone'
                : ''}
            , please check your browser permissions
        </Alert>
    )
}
