import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'

import { getKeyboardShortcuts } from '../../../services/swMessenger'
import { RECORD_COMMAND } from '../../../background/constants'
import { SpacedGroup } from '../SpacedGroup'
import keyboard from '../../../assets/icons/keyboard.svg'

const useStyles = makeStyles(() => ({
    subText: {
        fontSize: '12px',
        fontWeight: 400,
        color: 'rgba(9, 12, 16, 0.5)',
    },
}))

export const RecordingKeyboard = () => {
    const classes = useStyles()
    const [recShortcut, setRecShortcut] = useState<{
        isLoading: boolean
        shortcut: string
    }>({ isLoading: true, shortcut: '' })

    useEffect(() => {
        getKeyboardShortcuts(commands => {
            const recCommand = commands.find(c => c.name === RECORD_COMMAND)
            if (recCommand) {
                setRecShortcut({
                    isLoading: false,
                    shortcut: recCommand.shortcut || '',
                })
            }
        })
    }, [])

    if (recShortcut.isLoading || !recShortcut.shortcut) {
        return null
    }

    return (
        <SpacedGroup justifyContent="center" alignItems="center" pt={2}>
            <img src={keyboard} alt="keyboard" />
            <Box className={classes.subText}>{recShortcut.shortcut}</Box>
        </SpacedGroup>
    )
}
