import { doc, onSnapshot } from 'firebase/firestore'

import firebase from './init'
import { getUser } from './auth'
import { captureSentryError } from '../services/sentry'
import { OrganizationType } from '../types'
import { isEmpty } from '../services/lodash'

let isInitial: boolean = true
let orgData: OrganizationType | {} = {} // Apps that can be used by this org
let unsubFromOrgData: null | (() => void) = null

const organizationSubscription: (
    orgId: string
) => Promise<OrganizationType> = orgId =>
    new Promise(resolve => {
        const orgRef = doc(firebase.firestore(), `organizations/${orgId}`)

        // onSnapshot will receive updates whenever an organization is modified
        unsubFromOrgData = onSnapshot(
            orgRef,
            querySnapshot => {
                const orgSnap = querySnapshot.data() as OrganizationType
                orgData = orgSnap
                resolve(orgSnap)
            },
            e => {
                isInitial = true // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getFirebaseOrgData: () => Promise<OrganizationType | {}> = () => {
    const firebaseUser = getUser()
    const email = firebaseUser?.user?.email
    const orgId = firebaseUser?.roles?.o

    if (!email || !orgId) return Promise.resolve({})

    if (isInitial) {
        isInitial = false
        return organizationSubscription(orgId)
    }

    // Means that subscription is being established, retry in 1 second
    if (isEmpty(orgData)) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(getFirebaseOrgData())
            }, 1000)
        })
    }

    return Promise.resolve(orgData)
}

// Garbage collector to remove all subscriptions and reset everything to initial
export const firebaseOrgGC = () => {
    isInitial = true
    orgData = {}
    unsubFromOrgData?.()
}
