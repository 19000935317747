/* Listens to messages from parent script, ignores empty messages.
 * Makes sure there's only one listener in the component and removes it on component unmount. */

import { useCallback, useEffect } from 'react'
import {
    LibraryIframeAction,
    onMessageFromParent,
    RecordingIframeAction,
    removeMessageFromParentListener,
} from '../services/parentService'

type MessageCallback = (
    type: LibraryIframeAction | RecordingIframeAction,
    params: any
) => void

export const useParentListener = (messageCallback: MessageCallback) => {
    const parentListener = useCallback(
        event => {
            const type = event?.data?.type
            if (!type) return

            const params = event?.data?.params
            messageCallback(type, params)
        },
        [messageCallback]
    )

    useEffect(() => {
        onMessageFromParent(parentListener)

        return () => {
            removeMessageFromParentListener(parentListener)
        }
    }, [parentListener])
}
