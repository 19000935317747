const initialState = {
    color: null,
    icon: null,
    position: null,
    embed: false,
}

export const sdkConfigReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'setSdkConfig':
            return payload
        default:
            return state
    }
}
