if (SDK_MODE) {
    const sdkContentScript = require('./index.sdk')
    sdkContentScript?.default()
}

if (EXT_MODE) {
    const extContentScript = require('./index.ext')
    extContentScript?.default()
}

export {}
