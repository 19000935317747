import React, { useState, useCallback } from 'react'

import { Box, IconButton, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Autocomplete } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'

import { tagRegex } from 'services/tagsParser'
import { uiId } from 'services/utils'

import { useBoolean } from 'hooks'

const FORBIDDEN_CHARS = ['#', '<', '>', ' ', '=', ';']
const validateChars = e => {
    const key = e.key
    if (FORBIDDEN_CHARS.includes(key)) {
        e.preventDefault()
    }
}

const useStyles = makeStyles(() => ({
    popper: {
        position: 'absolute',
        top: '32px',
    },
}))

type Props = {
    autocompleteOptions: { value: string; label: string }[]
    onApply: (tag: string) => void
}

export const AddTag = ({ autocompleteOptions, onApply }: Props) => {
    const autocompleteOpen = useBoolean()
    const classes = useStyles()

    const [inputValue, onInputChange] = useState<string>('')

    const applyTag = useCallback(
        tag => {
            onApply(tag)
            onInputChange('')
        },
        [onApply]
    )

    return (
        <>
            <Box ml={1}>
                <Autocomplete
                    id={uiId('add-tags-input')}
                    open={autocompleteOpen.isTrue}
                    autoHighlight
                    multiple={false}
                    style={{ width: 150 }}
                    classes={{ popper: classes.popper }}
                    blurOnSelect
                    freeSolo
                    inputValue={inputValue}
                    onChange={(_event, newValue: any) => {
                        if (newValue) {
                            applyTag(newValue.value || newValue)
                        }
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'clear' || reason === 'reset') {
                            onInputChange('')
                        }
                    }}
                    onBlur={autocompleteOpen.setFalse}
                    popupIcon={null}
                    options={autocompleteOptions}
                    disablePortal
                    renderInput={({ InputProps, ...params }) => (
                        <TextField
                            variant="standard"
                            {...params}
                            InputProps={{
                                ...InputProps,
                                placeholder: '+ Add tag',
                                onChange: e => {
                                    if (tagRegex.test(e.target.value)) {
                                        onInputChange(e.target.value.trim())
                                    }
                                },
                                onFocus: autocompleteOpen.setTrue,
                                disableUnderline: true,
                                style: {
                                    flexDirection: 'row',
                                    fontSize: '14px',
                                },
                                onKeyDown: validateChars,
                            }}
                        />
                    )}
                    getOptionLabel={o =>
                        o?.label?.replace(' ', '').split('(')[0] || ''
                    }
                    renderOption={(props, option) => (
                        <li {...props}>{option.label}</li>
                    )}
                />
            </Box>
            {inputValue && (
                <IconButton
                    onClick={() => {
                        applyTag(inputValue)
                    }}
                    size="small"
                >
                    <DoneIcon />
                </IconButton>
            )}
        </>
    )
}
