import moment from 'moment'
import {
    getPlaybookList,
    getPlaybook,
    getPlaybooks,
    getTagConditions,
    fetchTranscript,
    sendViewNotification,
} from '../api'
import { logEvent, logToRemoteDebugLogs } from './analytics'
import { getUser } from '../firebase/auth'

const getSearchSnippet = playbook => {
    const title = playbook?._snippetResult.title || ''
    const description = playbook?.description || ''
    const tags = playbook?._snippetResult.tags || []
    const transcript = playbook.sections.map(section => {
        return {
            value: section?._snippetResult?.transcript?.value || '',
            matchLevel: section?._snippetResult?.transcript?.matchLevel || '',
            start: section?.start || '',
        }
    })

    return {
        title,
        description,
        tags,
        transcript,
    }
}

const sdkScript = async ({ action, params }) => {
    switch (action) {
        case 'searchFromSdkApi': {
            /*
                get playbooks from api with search parameters
                query: string,  - The text to search - Mandatory
                tags: string[], - This parameter filters the query by the specified tags - Optional
                length: number, - This parameter controls the number of hits to retrieve - Optional (default 5)
                page: number    - This parameter specifies the page to retrieve - Optional (default 0)
            */
            const res = await getPlaybooks({ sendLogEvent: true, ...params })
            // loop results and map response fields
            const videos = res.playbooks.map(video => {
                return {
                    createdAt:
                        moment(video.created * 1000).format(
                            'YYYY-MM-DDTHH:mm:ss'
                        ) || null,
                    creator: {
                        avatarUrl: video?.creator_img || null,
                        name: video?.creator_name || null,
                    },
                    language: video?.currentTranscriptLang || null,
                    duration: video?.duration || 0,
                    id: video?.id || '',
                    imageUrl: video?.img || null,
                    pageUrl: video?.link || null,
                    tags: video?.tags || [],
                    title: video?.title || 'No Title',
                    searchSnippet: getSearchSnippet(video) || [],
                }
            })
            return { nbHits: res.nbHits, videos }
        }
        case 'getFirebaseUser':
            return getUser()

        case 'getTagConditions':
            return await getTagConditions(params.domain)

        case 'logToAnalytics':
            return logEvent(params.event, params.params)

        case 'logToRemoteDebugLogs':
            return logToRemoteDebugLogs(params.event, params.params)

        case 'getPlaybook':
            return getPlaybook(params.playbookId)

        case 'getPlaybooks':
            return getPlaybooks(params)

        case 'getPlaybookList':
            return getPlaybookList(params.playbookId)

        case 'getTranscript':
            return fetchTranscript(params.transcriptUrl)

        case 'sendViewNotification':
            return sendViewNotification({ params })
        default:
            return
    }
}

export default sdkScript
