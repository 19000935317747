import React, { createContext, useCallback, useEffect, useState } from 'react'
import { Snackbar, IconButton, SnackbarCloseReason } from '@mui/material'

import CloseIcon from '@mui/icons-material/Close'
import MuiAlert from '@mui/material/Alert'

const messageTypes = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    DEFAULT: 'info',
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

export const NotificationContext = createContext({
    showSuccessNotification: message => message,
    showWarningNotification: message => message,
    showErrorNotification: message => message,
})

export const NotificationProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState({
        type: messageTypes.DEFAULT,
        text: '',
    })

    const showSuccessNotification = useCallback(
        value => setMessage({ type: messageTypes.SUCCESS, text: value }),
        []
    )

    const showWarningNotification = useCallback(
        value => setMessage({ type: messageTypes.WARNING, text: value }),
        []
    )

    const showErrorNotification = useCallback(
        value => setMessage({ type: messageTypes.ERROR, text: value }),
        []
    )

    const handleClose = (_event, reason?: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const contextValue = {
        showSuccessNotification,
        showErrorNotification,
        showWarningNotification,
    }

    useEffect(() => {
        if (message.text) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [message])

    return (
        <NotificationContext.Provider value={contextValue}>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ zIndex: 10011 }}
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                {/* Why <div> is needed: https://github.com/mui/material-ui/issues/28918 */}
                <div>
                    <Alert onClose={handleClose} severity={message.type}>
                        {message.text}
                    </Alert>
                </div>
            </Snackbar>
            {children}
        </NotificationContext.Provider>
    )
}
