export type ParentMessageAction =
    | 'RESIZE_TO_PLAYER'
    | 'RESIZE_TO_NORMAL'
    | 'RESIZE_TO_NOTIFICATIONS'
    | 'OPEN_MODAL_MODE'
    | 'CLOSE_MODAL_MODE'
    | 'GET_LOCATION'
    | 'GET_DEFAULT_TAGS'
    | 'CLOSE_IFRAME'
    | 'OPEN_IFRAME'
    | 'OPEN_REC_IFRAME'
    | 'CLOSE_REC_IFRAME'
    | 'RETURN_SEARCH_RESPONSE'
    | 'START_PLAYBOOK_REC'
    | 'GET_LIBRARY_STATE'

type ParentMessage = {
    action: ParentMessageAction
    params?: any
}

export const sendMessageToParentScript = (message: ParentMessage) => {
    window.parent.postMessage(message, '*')
}

export enum RecordingIframeActionE {
    OPEN_SETTINGS = 'OPEN_SETTINGS',
    OPEN_NO_STEPS_MESSAGE = 'OPEN_NO_STEPS_MESSAGE',
    OPEN_RECORDING_ERROR_MESSAGE = 'OPEN_RECORDING_ERROR_MESSAGE',
    OPEN_PIN_EXT_CTA = 'OPEN_PIN_EXT_CTA',
}

export type RecordingIframeAction = `${RecordingIframeActionE}`

export enum LibraryIframeActionE {
    URL_CHANGED = 'URL_CHANGED',
    LIB_OPENED = 'LIB_OPENED',
    LIB_CLOSED = 'LIB_CLOSED',
    INIT_LIBRARY_STATE = 'INIT_LIBRARY_STATE',
}

export type LibraryIframeAction = `${LibraryIframeActionE}`

export enum BroadcastIframeActionE {
    URL_CHANGED = 'URL_CHANGED',
    SET_LIBRARY_MODE = 'SET_LIBRARY_MODE',
    TOGGLE_OPEN_STATE = 'TOGGLE_OPEN_STATE',
    OPEN = 'OPEN',
    OPEN_VIDEO = 'OPEN_VIDEO',
    OPEN_PLAYLIST = 'OPEN_PLAYLIST',
    OPEN_QUERY_SEARCH = 'OPEN_QUERY_SEARCH',
}

export const sendMessageToIframe = (
    iframe: HTMLIFrameElement,
    message:
        | RecordingIframeAction
        | LibraryIframeAction
        | BroadcastIframeActionE,
    params?: any
) => {
    iframe.contentWindow?.postMessage({ type: message, params }, iframe.src)
}

// !!! MAKE SURE LISTENER IS ESTABLISHED ONCE !!!
// E.g. useEffect dependencies must be an empty array []
export const onMessageFromParent = (
    listener: (event: {
        data?: {
            type: RecordingIframeAction | LibraryIframeAction
            params?: any
            value?: any
        }
    }) => void
) => {
    window.addEventListener('message', listener)
}

export const removeMessageFromParentListener = listener => {
    window.removeEventListener('message', listener)
}
