import { useEffect, useMemo, useState } from 'react'

import { FeatureFlagsType, orgFeatures } from '../types'
import { getOrganizationData } from '../services/swMessenger'
import { useBoolean } from './use-boolean'

export const useOrgFeatureFlags = () => {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlagsType>()
    const flagsLoading = useBoolean()

    useEffect(() => {
        if (SDK_MODE) return

        flagsLoading.setTrue()

        getOrganizationData(({ featureFlags }) => {
            flagsLoading.setFalse()

            const newFlags = {
                ...Object.fromEntries(
                    Object.keys(orgFeatures).map(flagName => [
                        flagName,
                        Boolean((featureFlags || {})?.[flagName]),
                    ])
                ),
            } as FeatureFlagsType

            setFeatureFlags(newFlags)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return useMemo(
        () => ({ ...featureFlags, isFlagsLoading: flagsLoading.isTrue }),
        [featureFlags, flagsLoading]
    )
}
