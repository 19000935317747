import { firebaseAppsGC } from './apps'
import { firebaseOrgGC } from './organization'
import { firebaseFeatureFlagsGC } from './featureFlags'
import { firebaseUserAchievementsGC } from './userAchievements'
import { firebaseUserSpacesGC } from './userSpaces'
import { firebaseOrgUsersGC } from './orgUsers'
import { servicesUsageGC } from './servicesUsage'
import { firebaseUserQuickActionsGC } from './quickActionUserSettings'
import { firebaseFeatureFlagsGlobalGC } from './featureFlagsGlobal'
import { firebaseBroadcastWidgetGC } from './broadcastWidgets'

export const runFirebaseGarbageCollector = () => {
    firebaseAppsGC()
    firebaseOrgGC()
    firebaseFeatureFlagsGC()
    firebaseFeatureFlagsGlobalGC()
    firebaseUserAchievementsGC()
    firebaseUserSpacesGC()
    firebaseOrgUsersGC()
    servicesUsageGC()
    firebaseUserQuickActionsGC()
    firebaseBroadcastWidgetGC()
}
