declare global {
    const SDK_MODE: boolean
    const SDK_BROADCAST_MODE: boolean
    const EXT_MODE: boolean
    const LIB_MODE: boolean
    const REC_MODE: boolean
    const IS_CUSTOM_WEBPACK_BUILD: boolean
}

const buildTarget = process.env.REACT_APP_BUILD_TARGET

export const isLocal =
    process.env.NODE_ENV === 'development' ||
    (!chrome?.runtime?.getURL?.('') && Boolean(process.env.GUIDDE_LOCAL_SERVER))

// Function below is a candidate for deprecation. Use EXT_MODE variable instead.
export const checkIsExtension = () => buildTarget === 'ext' && !isLocal
