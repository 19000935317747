const initialScreenCaptureState = {
    status: 'stopped',
}

export const screenCaptureReducer = (
    state = initialScreenCaptureState,
    action
) => {
    const { type, payload } = action

    switch (type) {
        case 'setRecordingStatus':
            return { status: payload }

        default:
            return state
    }
}
