import firebase from './init'
import { captureSentryError } from '../services/sentry'
import { getUser } from './auth'
import { onSnapshot } from 'firebase/firestore'
import { BroadcastWidgetConfig } from '../types/broadcast'

let isInitial: boolean = true
let isReceivedSubscriptionResponse: boolean = false
let broadcastWidget: BroadcastWidgetConfig | null = null
let unsubscribeFromBroadcastWidget: null | (() => void) = null

const broadcastWidgetSubscription = orgId =>
    new Promise(resolve => {
        const broadcastWidgetQuery = firebase
            .firestore()
            .collection('broadcastWidgets')
            .where('organizationId', '==', orgId)

        unsubscribeFromBroadcastWidget = onSnapshot(
            broadcastWidgetQuery,
            querySnapshot => {
                broadcastWidget = null

                const broadcastWidgetSnap =
                    querySnapshot.docs[0]?.data() as BroadcastWidgetConfig

                if (!broadcastWidgetSnap) {
                    isReceivedSubscriptionResponse = true
                    resolve(null)
                    return
                }

                broadcastWidget = broadcastWidgetSnap
                isReceivedSubscriptionResponse = true

                resolve(broadcastWidgetSnap)
            },
            e => {
                isInitial = false
                captureSentryError(e)
            }
        )
    })

export const getBroadcastWidget = () => {
    const firebaseUser = getUser()

    const orgId = firebaseUser?.roles?.o

    if (!orgId) return Promise.resolve(null)

    if (isInitial) {
        isInitial = false
        return broadcastWidgetSubscription(orgId)
    }

    if (!isReceivedSubscriptionResponse) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(getBroadcastWidget())
            }, 1000)
        })
    }

    return Promise.resolve(broadcastWidget)
}

export const firebaseBroadcastWidgetGC = () => {
    isInitial = true
    isReceivedSubscriptionResponse = false
    broadcastWidget = null
    unsubscribeFromBroadcastWidget?.()
}
