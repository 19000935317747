import { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { getNotifications, removeNotification } from 'services/swMessenger'
import {
    NotificationsT,
    SUPPORTED_NOTIFICATION_TYPES,
} from 'types/notifications'

import { AppContext } from 'app/AppProvider'

export const NotificationsContext = createContext<{
    notifications: NotificationsT
    clearNotifications: () => void
    clearNotification: (id: string) => {}
}>({
    notifications: [],
    clearNotifications: () => {},
    clearNotification: _id => ({}),
})

export const NotificationsProvider = ({ children }) => {
    const dispatch = useDispatch()

    const [notifications, setNotifications] = useState<NotificationsT>([])

    const setFilteredNotifications = useMemo(
        () => (notifications: NotificationsT) =>
            setNotifications(
                notifications.filter(({ type }) =>
                    SUPPORTED_NOTIFICATION_TYPES.includes(type)
                )
            ),
        []
    )

    const clearNotifications = async () => {
        if (!notifications.length) {
            return
        }

        await removeNotification(null, { uid: notifications[0].uid })
    }

    const clearNotification = async id => {
        await removeNotification(null, { id })
    }

    const sortByDate = (notifications: NotificationsT) =>
        notifications.sort((a, b) => b.dateCreated - a.dateCreated)

    const { openVideoPlayer } = useContext(AppContext)

    useEffect(() => {
        if (EXT_MODE) {
            getNotifications(
                notifications =>
                    setFilteredNotifications(sortByDate(notifications)),
                {}
            )
        }
    }, [dispatch, setFilteredNotifications])

    // We have a scenario when we want to share a playbook/playlist with a new user. User will get an email with a link to install ext.
    // After installation and first open, he will receive a hidden notification with type: 'newUser', which contains playbookId and isPlaylist properties.
    // In this case we should open current playbook/playlist (from notification).
    useEffect(() => {
        if (!notifications.length) {
            return
        }
        // get 'newUser' notification
        const newUserNotification = notifications.find(
            e => e?.type === 'newUser' && e?.status === 'new'
        )

        if (!newUserNotification) {
            return
        }

        // we don't need to show 'newUser' as a common notification
        const filteredNotifications = notifications.filter(
            e => e.type !== 'newUser'
        )
        setFilteredNotifications(sortByDate(filteredNotifications))

        // user invited via regular invite or installed ext by himself also receives 'newUser' but with { playbookId: null }
        if (newUserNotification.playbook?.id) {
            // get playbook from notification and run play mode
            openVideoPlayer(newUserNotification.playbook)
        }

        // Remove 'newUser' as we don't need it anymore
        removeNotification(() => {}, {
            id: newUserNotification.id,
        })
    }, [notifications, setFilteredNotifications, openVideoPlayer])

    const contextValue = {
        notifications,
        clearNotifications,
        clearNotification,
    }

    if (SDK_MODE) {
        return children
    }

    return (
        <NotificationsContext.Provider value={contextValue}>
            {children}
        </NotificationsContext.Provider>
    )
}
