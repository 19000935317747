import { createTheme } from '@mui/material/styles'

const inputStyles = {
    background: 'initial',
    backgroundColor: 'transparent !important' as any,
    border: 'none !important' as any,
    boxSizing: 'initial !important' as any,
    padding: '6px 0 7px 0 !important' as any,
    boxShadow: 'none !important' as any,
    fontSize: 'inherit !important' as any,
    margin: '0 !important' as any,
}

const theme = createTheme({
    overrides: {
        MuiTypography: {
            body1: {
                color: '#212121',
            },
            body2: {
                color: '#212121',
                fontSize: '12px',
            },
            subtitle1: {
                fontWeight: 'bold',
            },
            h2: {
                fontSize: '16px',
                fontWeight: 'bold',
                lineHeight: '1.5',
            },
            // Recording modal typography
            h1: {
                color: '#FBFDFF',
                fontSize: '52px',
                fontWeight: 500,
                letterSpacing: '0.15px',
                textAlign: 'center',
            },
            caption: {
                color: '#FFFFFF',
                lineHeight: '24px',
                fontSize: '18px',
                fontWeight: 500,
                letterSpacing: '0.1px',
                textAlign: 'center',
            },
        },
        MuiFormHelperText: {
            root: {
                color: '#cd0000',
            },
        },
        MuiInput: {
            root: {
                '& input': inputStyles,
            },
        },
        MuiTextField: {
            root: {
                '& input': inputStyles,
            },
        },
        MuiButton: {
            root: {
                width: 'auto',
            },
            containedPrimary: {
                color: 'white !important',
            },
        },
        MuiIconButton: {
            root: {
                width: 'auto',
            },
        },
    },
    palette: {
        primary: {
            main: '#cd0000',
        },
        secondary: {
            main: '#1976d2',
        },
        info: {
            main: '#1C1B1F',
        },
        text: {
            primary: '#212121',
            secondary: '#212121',
        },
    },
    typography: {
        // @ts-ignore
        pxToRem: size => `${size}px`,
        fontFamily: 'Roboto, sans-serif',
        button: {
            fontFamily: 'Roboto, sans-serif',
            fontSize: '14px !important',
            lineHeight: '24px !important',
            textTransform: 'none',
        },
    },
    props: {
        MuiButton: {
            variant: 'contained',
            color: 'primary',
        },
    },
    zIndex: {
        modal: 10010,
        tooltip: 10010,
    },
})

// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from '@mui/material/styles'

declare module '@mui/styles/defaultTheme' {
    interface DefaultTheme extends Theme {}
}

export default theme
