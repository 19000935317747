import React from 'react'
import clsx from 'clsx'

import { Chip, Tooltip } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
    tag: {
        border: 'none',
        fontSize: '14px',
        flexDirection: 'row',
    },
    defaultTag: {
        color: theme.palette.primary.main,
    },
    popper: {
        position: 'absolute',
        top: '32px',
    },
}))

type Props = {
    tag: string
    isHighlighted?: boolean
    onDelete: () => void
}

export const Tag = ({ isHighlighted, tag, onDelete }: Props) => {
    const classes = useStyles()

    const shouldTruncate = tag.length > 16
    const label = !shouldTruncate ? tag : `${tag.slice(0, 16) + '...'}`

    return (
        <Tooltip title={shouldTruncate ? tag : ''}>
            <Chip
                className={clsx(classes.tag, {
                    [classes.defaultTag]: isHighlighted,
                })}
                variant="outlined"
                size="small"
                label={`#${label}`}
                onDelete={onDelete}
            />
        </Tooltip>
    )
}
