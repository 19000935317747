import { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { ServicesUsageType } from '@guidde/common-types/build/fsdb'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import makeStyles from '@mui/styles/makeStyles'

import { REC_MODE_SETTING } from 'background/constants'
import { getAllSitePermissions, getServiceUsage } from 'services/swMessenger'
import { isLocal } from 'services/environmentService'

import { AppContext } from 'app/AppProvider'
import { RootState } from 'ducks/rootReducer'
import { useStorageSetting } from 'hooks'

import { StartQuickGuidde } from './StartQuickGuidde'
import { StartPlaybook } from './StartPlaybook'
import { AllowAccess } from './AllowAccess'
import { ViewOnly } from './ViewOnly'
import { UpgradeBanner } from './UpgradeBanner'
import { Upgrade } from './Upgrade'

enum RecModesE {
    QUICK_GUIDDE = 'quickGuidde',
    PLAYBOOK = 'playbook',
}

type RecModes = `${RecModesE}`

const TABS = [
    { value: RecModesE.QUICK_GUIDDE, label: 'Step by step' },
    { value: RecModesE.PLAYBOOK, label: 'Screen recorder' },
]

const useStyles = makeStyles(() => ({
    tab: {
        color: '#ffffff',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '16px',
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        opacity: 0.5,
    },
    indicator: { backgroundColor: 'unset' },
    tabPanel: { minHeight: '400px' },
}))

export const RecordingWizard = ({ showQGRecordOnly, startQG, startPB }) => {
    const classes = useStyles()
    const [allSitesPermission, setAllSitesPermission] = useState<
        boolean | null
    >(null)

    const {
        serviceUsage: { isRecordingLocked },
    } = useContext(AppContext)

    const [usedQGStatus, setUsedQGStatus] = useState<{
        usedMC: number
        quotaMC: number | null
    } | null>(null)

    const { roles } = useSelector((state: RootState) => state.user)

    const userCannotCreateContent = useMemo(() => {
        const isViewer = roles.a === 10
        return isRecordingLocked || isViewer
    }, [isRecordingLocked, roles.a])

    const { storageSetting: recMode, changeSetting: setRecMode } =
        useStorageSetting(REC_MODE_SETTING)

    useEffect(() => {
        getAllSitePermissions(isAllowed => {
            setAllSitesPermission(isAllowed)
        })

        getServiceUsage((serviceUsage: ServicesUsageType) => {
            const { usedPlaybooksSlots, playbooksQuota } = serviceUsage

            setUsedQGStatus({
                usedMC: usedPlaybooksSlots,
                quotaMC: playbooksQuota,
            })
        })

        // If it is a local build, set the recording mode (locally storage will be undefined)
        if (isLocal) {
            setRecMode(RecModesE.QUICK_GUIDDE)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const hasQGQuota = useMemo(() => {
        if (!usedQGStatus) return false

        // If quota is null, allow unlimited capture
        return usedQGStatus.quotaMC
            ? usedQGStatus.usedMC < usedQGStatus.quotaMC
            : true
    }, [usedQGStatus])

    if (!recMode || allSitesPermission === null || !usedQGStatus) return null

    if (userCannotCreateContent) {
        return <ViewOnly />
    }

    if (!allSitesPermission) {
        return <AllowAccess />
    }

    if (!hasQGQuota) {
        return (
            <>
                <UpgradeBanner usedQGStatus={usedQGStatus} />
                <Upgrade />
            </>
        )
    }

    if (showQGRecordOnly) {
        return (
            <StartQuickGuidde startQG={startQG} usedQGStatus={usedQGStatus} />
        )
    }

    return (
        <TabContext value={recMode}>
            <Tabs
                centered
                value={recMode}
                classes={{ indicator: classes.indicator }}
                onChange={(_, value: RecModes) => {
                    setRecMode(value)
                }}
            >
                {TABS.map(tab => (
                    <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                        classes={{ root: classes.tab }}
                    />
                ))}
            </Tabs>

            <TabPanel
                value={RecModesE.QUICK_GUIDDE}
                className={classes.tabPanel}
            >
                <StartQuickGuidde
                    startQG={startQG}
                    usedQGStatus={usedQGStatus}
                />
            </TabPanel>

            <TabPanel value={RecModesE.PLAYBOOK} className={classes.tabPanel}>
                <StartPlaybook startPB={startPB} />
            </TabPanel>
        </TabContext>
    )
}
