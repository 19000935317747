import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { Badge, Box, Button, Paper, MenuList, MenuItem } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import LinkIcon from '@mui/icons-material/Link'
import CloseIcon from '@mui/icons-material/Close'
import ShareIcon from '@mui/icons-material/Share'
import LaunchIcon from '@mui/icons-material/Launch'
import EditIcon from '@mui/icons-material/Edit'
import FastForwardIcon from '@mui/icons-material/FastForward'

import { AppContext } from 'app/AppProvider'

import { setActivePlaybook } from 'ducks/actions'
import { RootState } from 'ducks/rootReducer'

import {
    sendViewNotification,
    openExternalLink,
    logToAnalytics,
} from 'services/swMessenger'
import { playbookToAnalyticsProps } from 'services/analytics'

import { VideoPlayer } from 'UI/Components'
import { useAuthDomain, useBoolean, useRealtimeVideoTime } from 'hooks'

import { generatePlayerId } from 'services/video'
import { sendMessageToParentScript } from 'services/parentService'
import { getFromSessionStorage, setToSessionStorage } from 'services/utils'

const useStyles = makeStyles({
    videoWrapper: {
        position: 'relative',
        width: '100%',
        height: '100%',
        paddingBottom: '330px',
        background: 'black',
    },
    controlBtn: {
        position: 'absolute',
        right: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(38 50 56 / 0.50);',
        minWidth: '40px !important',
        minHeight: '40px',
        borderRadius: '50%',
        padding: 0,
        opacity: 0,
        transform: 'translateX(30px)',
        transition: 'opacity 0.1s linear, transform 0.1s linear',
        '&:hover': {
            backgroundColor: 'rgb(38 50 56 / 80%)',
        },
        '& svg': {
            color: 'white',
        },
    },
    controlBtnVisible: {
        opacity: 1,
        transform: 'translateX(0)',
    },
    closeBtn: {
        top: '8px',
    },
    transcriptBtn: {
        top: '56px',
        transitionDelay: '0.1s !important',
    },
    shareBtn: {
        top: '104px',
        transitionDelay: '0.2s !important',
    },
    speedBtn: {
        transitionDelay: '0.3s !important',
        top: '150px',
    },
    copyBtn: {
        top: '196px',
        transitionDelay: '0.4s !important',
    },
    editBtn: {
        transitionDelay: '0.5s !important',
        top: '242px',
    },
    copyList: {
        top: '200px',
        position: 'absolute',
        right: '8px',
        zIndex: 2,
    },
    videoSwitchBtn: {
        backgroundColor: '#dcdbdb',
        color: '#1e1e1e',
        '&:hover': {
            backgroundColor: '#dcdbdb',
        },
        '&.Mui-disabled': {
            pointerEvents: 'auto',
            backgroundColor: '#ffffff80',
            '&:hover': {
                backgroundColor: '#ffffff80',
            },
        },
    },
    badge: {
        left: '-4px',
        top: '-4px',
        fontWeight: 600,
        fontSize: '10px',
    },
})

export const VideoPanel = ({
    hoverState,
    showSidePanel,
    showShareDialog,
    playbook,
}) => {
    const authDomain = useAuthDomain()
    const dispatch = useDispatch()
    const copyDropdown = useBoolean()
    const realVideoTime = useRealtimeVideoTime()

    const videoRef: any = useRef()

    const { playlistMode } = useContext(AppContext)

    const classes = useStyles()

    const { active, playbookList } = useSelector(
        (state: RootState) => state.playlist
    )
    const isSDKEmbed = useSelector((state: RootState) => state.sdkConfig.embed)
    const { user, roles } = useSelector((state: RootState) => state.user)
    const playlist = useSelector((state: RootState) => state.playbook || {})
    const { uid } = user

    const mixpanelPayload = playbookToAnalyticsProps(playbook)

    useEffect(() => {
        if (videoRef.current && videoRef?.current?.src !== playbook?.url) {
            videoRef.current.load()
        }
    }, [playbook.url])

    const isOwner = playbook?.creator_uid === user.uid

    // Send email notification to the video owner every time when someone watches playbook
    useEffect(() => {
        if (
            playbook &&
            Object.values(playbook).length > 0 &&
            user.uid &&
            roles?.o &&
            !roles?.s &&
            !isOwner
        ) {
            const viewKey = `playbookViews/${user.uid}/${playbook.id}`
            const isViewed = !!getFromSessionStorage(viewKey)

            if (isViewed) {
                return
            }

            sendViewNotification(() => {}, {
                orgId: roles?.o,
                uid: user.uid,
                playbookId: playbook.id,
            })
            logToAnalytics('videoPlay', playbookToAnalyticsProps(playbook))

            setToSessionStorage(viewKey, Date.now().toString())
        }
    }, [playbook, roles?.o, roles?.s, user.uid, isOwner, playlist.id])

    const [videoSpeed, changeSpeed] = useState(1)
    const increaseVideoSpeed = useCallback(() => {
        changeSpeed(prevSpeed => {
            const newSpeed = prevSpeed === 2 ? 1 : prevSpeed + 0.25
            const playerId = generatePlayerId()
            const player = document.getElementById(playerId) as HTMLVideoElement
            player.playbackRate = newSpeed

            return newSpeed
        })
    }, [])

    const canEditPlaybook =
        isOwner ||
        roles?.s ||
        (roles?.a === 100 && roles?.o === playbook.organizationId)

    const videoCopyLink = useMemo(() => {
        const path =
            (playlist.isPublic ? '/share/playlists/' : '/playbooks/playlist/') +
            playlist.id +
            '?origin=' +
            uid

        return authDomain + path
    }, [authDomain, playlist.id, playlist.isPublic, uid])

    const onCopyLink = useCallback(() => {
        logToAnalytics('copyLink', {
            context: 'video-player',
            ...playbookToAnalyticsProps(playlist),
        })
    }, [playlist])

    if (Object.values(playbook).length === 0) {
        return null
    }

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Box className={classes.videoWrapper}>
                <VideoPlayer
                    defaultSpeed={videoSpeed}
                    playbookURL={playbook.url}
                />
            </Box>

            <Button
                title="Close video"
                onClick={() => {
                    playlistMode.setFalse()
                    logToAnalytics('videoClose', mixpanelPayload)

                    sendMessageToParentScript({
                        action: 'RESIZE_TO_NORMAL',
                        params: { close: true, isSDKEmbed },
                    })
                }}
                size="small"
                className={clsx(
                    classes.controlBtn,
                    classes.closeBtn,
                    hoverState.isTrue && classes.controlBtnVisible
                )}
            >
                <CloseIcon />
            </Button>
            <Button
                title="Toggle side panel"
                onClick={showSidePanel.toggle}
                size="small"
                className={clsx(
                    classes.controlBtn,
                    classes.transcriptBtn,
                    hoverState.isTrue && classes.controlBtnVisible
                )}
            >
                <LaunchIcon />
            </Button>

            {EXT_MODE && (
                <>
                    <Button
                        title="Share playlist"
                        onClick={showShareDialog.toggle}
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.shareBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                        style={{ pointerEvents: 'auto' }}
                    >
                        <ShareIcon />
                    </Button>

                    <Button
                        title="Change video speed"
                        onClick={increaseVideoSpeed}
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.speedBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                    >
                        <Badge
                            overlap="rectangular"
                            badgeContent={`${(
                                Math.round(videoSpeed * 100) / 100
                            ).toFixed(2)}x`}
                            color="primary"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            classes={{
                                badge: classes.badge,
                            }}
                        >
                            <FastForwardIcon />
                        </Badge>
                    </Button>

                    <Button
                        title="Copy video link"
                        size="small"
                        className={clsx(
                            classes.controlBtn,
                            classes.copyBtn,
                            hoverState.isTrue && classes.controlBtnVisible
                        )}
                        onClick={copyDropdown.toggle}
                    >
                        <LinkIcon />
                    </Button>

                    {copyDropdown.isTrue && hoverState.isTrue && (
                        <Paper className={classes.copyList}>
                            <MenuList
                                id="split-button-menu"
                                onClick={copyDropdown.setFalse}
                                onMouseLeave={copyDropdown.setFalse}
                            >
                                <CopyToClipboard
                                    text={videoCopyLink}
                                    onCopy={onCopyLink}
                                >
                                    <MenuItem>Copy Link</MenuItem>
                                </CopyToClipboard>
                                <CopyToClipboard
                                    text={`${videoCopyLink}&active=${playbook.id}&t=${realVideoTime}`}
                                    onCopy={onCopyLink}
                                >
                                    <MenuItem>
                                        Copy Link at current time
                                    </MenuItem>
                                </CopyToClipboard>
                            </MenuList>
                        </Paper>
                    )}

                    {canEditPlaybook && (
                        <Button
                            title="Edit video"
                            size="small"
                            className={clsx(
                                classes.controlBtn,
                                classes.editBtn,
                                hoverState.isTrue && classes.controlBtnVisible
                            )}
                            onClick={() => {
                                openExternalLink(null, {
                                    url: `playbooks/playlist/${playlist.id}/edit`,
                                })
                            }}
                        >
                            <EditIcon />
                        </Button>
                    )}
                </>
            )}

            <Box display="flex" justifyContent="center" mt={1} mb={1}>
                <Button
                    classes={{ root: classes.videoSwitchBtn }}
                    size="small"
                    variant="contained"
                    disabled={active === 0}
                    onClick={() => dispatch(setActivePlaybook(active - 1))}
                >
                    Prev
                </Button>
                <Box ml={1} />
                <Button
                    classes={{ root: classes.videoSwitchBtn }}
                    size="small"
                    variant="contained"
                    disabled={active === playbookList.length - 1}
                    onClick={() => dispatch(setActivePlaybook(active + 1))}
                >
                    Next
                </Button>
            </Box>
        </Box>
    )
}
