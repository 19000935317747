import { getApps, getTagConditions } from './swMessenger'

export type TagsParserResponse = {
    app: string
    appName: string
    domain: string
    tags: string[]
    querySelectors: string[]
}

type CustomLocation = {
    host: string
    href: string
    pathname: string
}

type Operation =
    | { type: 'INCLUDES'; param: string }
    | { type: 'INCLUDES_SOME'; param: string[] }
    | { type: 'SPLIT'; param: string }
    | { type: 'TAKE_N_TH_ELEMENT'; param: number }
    | { type: 'TAKE_N_TH_ELEMENT_FROM_TAIL'; param: number }
    | { type: 'QUERY_SELECTOR'; param: string }
    | { type: 'QUERY_SELECTOR_ALL'; param: string }
    | { type: 'FILTER_BY_INNER_TEXT'; param: string }
    | { type: 'INNER_TEXT'; param: any }
    | { type: 'DELETE_FROM_STRING'; param: string }
    | { type: 'EQUALS'; param: number | string }
    | { type: 'NOT_EQUALS'; param: number | string }
    | { type: 'MODIFY_TAG'; param: string }

type OperationChain = {
    operand: string
    operations: Operation[]
}

type TagResult = string | OperationChain | OperationChain[]

export type TagCondition = {
    condition: 'DEFAULT' | OperationChain
    result: TagResult
}

const OPERAND_VALUES = {
    href: location => location.href,
    host: location => location.host,
    pathname: location => location.pathname,
    path: location => location.href.split('?')[0],
    document: _ => document,
}

const getOperandValue = (
    operandName: string,
    customLocation?: CustomLocation
) =>
    OPERAND_VALUES[operandName]
        ? OPERAND_VALUES[operandName](customLocation || window.location)
        : null

const sortConditions = (a: TagCondition, b: TagCondition) => {
    if (a.condition === 'DEFAULT') {
        return 1
    }
    if (b.condition === 'DEFAULT') {
        return -1
    }
    return 0
}

/* IMPORTANT! Be careful, when using this function - it relies on external API being filled in correctly.
 * Always use it within try-catch statement. A small typo can lead to content script crash.
 * */
const performOperation = (operand, operation: Operation) => {
    // In case something went wrong with the previous op and we should break the chain
    if (!operand) {
        return null
    }

    const { type, param } = operation
    switch (type) {
        case 'INCLUDES':
            return operand.includes(param)

        case 'INCLUDES_SOME':
            return param.some(str => operand.includes(str))

        case 'SPLIT':
            return operand.split(param)

        case 'TAKE_N_TH_ELEMENT':
            return operand[param]

        case 'TAKE_N_TH_ELEMENT_FROM_TAIL': {
            const lastElementIndex = operand.length - 1
            return operand[lastElementIndex - param]
        }

        case 'QUERY_SELECTOR':
            return operand.querySelector(param)

        case 'QUERY_SELECTOR_ALL':
            return Array.from(operand.querySelectorAll(param))

        case 'FILTER_BY_INNER_TEXT':
            return operand.filter(node => node?.innerText?.includes(param))

        case 'INNER_TEXT':
            return operand.innerText

        case 'DELETE_FROM_STRING':
            return String(operand)
                .toLowerCase()
                .replace(param.toLowerCase(), '')

        case 'EQUALS':
            return operand === param

        case 'NOT_EQUALS':
            return operand !== param

        case 'MODIFY_TAG':
            return String(param)
                .toLowerCase()
                .replaceAll('{tag}', String(operand).toLowerCase())

        default:
            throw new Error('Unsupported operation')
    }
}

// Returns null if smth went wrong
const computeOperations = (
    operand,
    operations,
    customLocation?: CustomLocation
) => {
    const parsedOperand = getOperandValue(operand, customLocation)

    if (!parsedOperand) {
        return null
    }

    return operations.reduce((currentOperand, currentOperation) => {
        try {
            return performOperation(currentOperand, currentOperation)
        } catch (error: any) {
            return null
        }
    }, parsedOperand)
}

export const tagRegex = new RegExp(/^[a-zA-Z0-9_.-]*$/)

export const trimResultTag = tag => {
    let temp = tag
    temp = temp.toString()
    temp = temp.trim()
    temp = temp.toLowerCase()
    // Typeof check is required because of https://sentry.io/share/issue/2ffbd90d1c1e443fb605eace0db54cf6/
    temp = typeof temp === 'string' ? temp.replaceAll(/[^\w\s-_]/gi, '') : ''
    return temp.replaceAll(' ', '-')
}

const computeTags = (result: TagResult, customLocation?: CustomLocation) => {
    if (typeof result === 'string') {
        return [result]
    }

    if (Array.isArray(result)) {
        return result
            .map(res =>
                computeOperations(res.operand, res.operations, customLocation)
            )
            .filter(tag => tag ?? false)
    }

    const computedValue = computeOperations(
        result.operand,
        result.operations,
        customLocation
    )
    return computedValue ? [computedValue] : []
}

const composeQuerySelectors = (conditions: TagCondition[]) => {
    const selectors = conditions.reduce(
        (acc: string[], { condition, result }: TagCondition) => {
            const conditionOps =
                typeof condition === 'string' ||
                condition.operand !== 'document'
                    ? []
                    : condition.operations

            const resultOps =
                typeof result !== 'string' &&
                (Array.isArray(result)
                    ? result.flatMap(({ operations }) => operations)
                    : result.operations)

            const selectors: string[] = (conditionOps || [])
                .concat(resultOps || [])
                .filter(
                    operation =>
                        operation.type === 'QUERY_SELECTOR' ||
                        operation.type === 'QUERY_SELECTOR_ALL'
                )
                .map(operation => operation.param)

            return acc.concat(selectors)
        },
        []
    )

    return Array.from(new Set(selectors))
}

export const tagsParser = (customLocation?: CustomLocation) =>
    new Promise<TagsParserResponse>(resolve => {
        const getTags = apps => {
            const host = customLocation
                ? customLocation.host
                : window.location.host

            const currentApp = (apps || []).reduce(
                (acc, currentApp) => {
                    // Match has already been found
                    if (acc.app) {
                        return acc
                    }

                    const domainMatch = currentApp.domains.find(domain =>
                        host.includes(domain)
                    )

                    return domainMatch
                        ? { app: currentApp, domain: domainMatch }
                        : acc
                },
                { app: undefined, domain: undefined }
            )

            const { app, domain } = currentApp

            getTagConditions(
                (tagConditions: TagCondition[]) => {
                    // So default condition is the last
                    const sortedConditions = tagConditions.sort(sortConditions)
                    const querySelectors: string[] =
                        composeQuerySelectors(sortedConditions)

                    // Use <for> loop instead of <forEach> to break it once the match is found
                    let i
                    for (i = 0; i < sortedConditions.length; i++) {
                        const { condition, result } = sortedConditions[i]

                        const isConditionTruthy: boolean =
                            condition === 'DEFAULT' ||
                            Boolean(
                                computeOperations(
                                    condition.operand,
                                    condition.operations,
                                    customLocation
                                )
                            )

                        if (isConditionTruthy) {
                            const computedResult: string[] = computeTags(
                                result,
                                customLocation
                            )

                            // If no computed results, proceed to the next condition
                            if (computedResult.length !== 0) {
                                resolve({
                                    app: app?.id,
                                    appName: app?.title,
                                    domain: domain,
                                    tags: computedResult
                                        .map(tag => trimResultTag(tag))
                                        .filter(tag => tag), // Empty strings are possible after trimming
                                    querySelectors,
                                })

                                break
                            }
                        }
                    }

                    resolve({
                        domain: domain || host,
                        app: app?.id,
                        appName: app?.title,
                        tags: [],
                        querySelectors,
                    })
                },
                { domain: domain || host }
            )
        }

        if (EXT_MODE) {
            getApps(response => {
                getTags(response.data)
            })
        }

        // In SDK we do not use apps
        if (SDK_MODE) {
            getTags([])
        }
    })
