import { ChangeEvent, useMemo, useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'

import {
    Box,
    Chip,
    ClickAwayListener,
    FormControl,
    Paper,
    Popper,
    TextField,
    Typography,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import { useBoolean, useTextDebounce } from 'hooks'

import { formatTime, uiId } from 'services/utils'
import { playbookToAnalyticsProps } from 'services/analytics'
import { logToAnalytics } from 'services/swMessenger'

import { Subtitle, VideoRefType, PlaybookType } from 'types'

type Props = {
    subtitles: Array<Subtitle>
    playbook: PlaybookType
    videoRef: VideoRefType | null
    onItemClick: (item: Subtitle, videoRef: VideoRefType | null) => void
}

const useStyles = makeStyles(theme => ({
    popper: {
        width: '100%',
        maxWidth: '445px',
        zIndex: 100,
    },
    paper: {
        maxHeight: '200px',
        overflow: 'overlay',
    },
    label: {
        color: '#78909c',
        lineHeight: 1,
        margin: theme.spacing(1),
        fontSize: theme.typography.pxToRem(14),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2, 2, 0),
            fontSize: theme.typography.pxToRem(12),
        },
    },
    transcriptText: {
        lineHeight: 2,
        fontSize: '12px',
        margin: '8px, 0',
        padding: '8px, 8px',
        cursor: 'pointer',
    },
    chip: {
        cursor: 'pointer',
        fontSize: '10px',
        marginRight: '5px',
    },
}))

export const SearchTranscript = ({
    subtitles,
    playbook,
    videoRef,
    onItemClick,
}: Props) => {
    const classes = useStyles()

    const inputRef = useRef<HTMLInputElement>()
    const open = useBoolean()

    const [searchValue, setSearchValue] = useState<Array<string>>([])
    const debouncedValue = useTextDebounce(searchValue?.[0], 200)

    const validLength = useMemo(
        () => debouncedValue?.length >= 2,
        [debouncedValue]
    )
    /*
     Filter the transcript to include the search term
 */
    const result = useMemo(() => {
        if (!debouncedValue?.length || !validLength) return []

        const res = subtitles
            .filter(item =>
                item.text.toLowerCase().includes(debouncedValue.toLowerCase())
            )
            .slice(0, 50)

        logToAnalytics(res?.length ? 'search' : 'search-no-results', {
            text: debouncedValue.toLowerCase(),
            hits: res?.length,
            inVideo: true,
            ...playbookToAnalyticsProps(playbook),
        })

        return res
    }, [debouncedValue, subtitles, validLength, playbook])

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const text = e.target.value?.trim()

        const newArray = text ? [text] : []
        setSearchValue(newArray)
    }

    return (
        <Box pb={2} style={{ position: 'relative' }}>
            <FormControl variant="standard" fullWidth>
                <TextField
                    variant="standard"
                    inputRef={inputRef}
                    label={<Typography>Search transcript</Typography>}
                    onClick={open.setTrue}
                    onChange={handleChange}
                />
            </FormControl>
            <Popper
                style={{ position: 'fixed' }}
                open={open.isTrue && validLength}
                anchorEl={inputRef?.current}
                placement="bottom-start"
                disablePortal
                className={classes.popper}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false,
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: { boundariesElement: 'viewport' },
                    },
                    { name: 'hide', enabled: true },
                ]}
            >
                <ClickAwayListener onClickAway={open.setFalse}>
                    <Paper elevation={3} className={classes.paper}>
                        <Box
                            p={{ md: 2, sm: 1 }}
                            pb={1}
                            id={uiId('search-wrapper')}
                        >
                            <Typography className={classes.label}>
                                {result?.length
                                    ? `${result?.length} result${
                                          result.length > 1 ? 's' : ''
                                      }`
                                    : 'No Match Found'}
                            </Typography>
                            {/*Search results*/}
                            {result.map((item: Subtitle, idx: number) => (
                                <Box
                                    key={idx}
                                    className={classes.transcriptText}
                                    mr={1.8}
                                    display="flex"
                                    onClick={() => {
                                        open.setFalse()
                                        onItemClick(item, videoRef)
                                    }}
                                >
                                    <Box>
                                        <Chip
                                            size="small"
                                            label={formatTime(item.start)}
                                            className={classes.chip}
                                        />
                                    </Box>
                                    <Box>
                                        <Highlighter
                                            highlightStyle={{
                                                backgroundColor: '#FFFF00',
                                            }}
                                            searchWords={searchValue}
                                            textToHighlight={item?.text}
                                            className={classes.transcriptText}
                                        />
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Paper>
                </ClickAwayListener>
            </Popper>
        </Box>
    )
}
