type OrganizationT = {
    logo: string
    name: string
}

export type UserT = {
    displayName: string
    photoURL: string
    organization?: OrganizationT
}

type ApplicationT = {
    logo: string
    title: string
}

type PBRequestT = {
    id: string
    title: string
    application?: ApplicationT
}

export type PlaybookT = {
    id: string
    title: string
    user: UserT
    screenshotUrl: string
    link?: string
    isRecording?: boolean
    isPlaylist?: boolean
    applications: ApplicationT[]
}

export type SpaceT = {
    id: string
    name: string
    logo: string
}

export const SUPPORTED_NOTIFICATION_TYPES = [
    'share',
    'request',
    'ready',
    'newUser',
    'resolveRequest',
    'requestShare',
    'view',
    'addToSpace',
    'batchUpload',
] as const

export type NotificationTypeT = typeof SUPPORTED_NOTIFICATION_TYPES[number]

export type NotificationT = {
    dateCreated: number
    id: string
    playbook?: PlaybookT
    appName?: string
    status: 'new' | 'viewed'
    type: NotificationTypeT
    uid: string
    from?: UserT
    pbRequest?: PBRequestT
    space?: SpaceT
    percentage: number | null
    batchUploadSize: number | null
}

export type NotificationsT = NotificationT[]
