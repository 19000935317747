import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Button from '@mui/material/Button'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
    actionBtn: {
        minHeight: '54px',
        padding: spacing(0, 3),
        color: '#FFFFFF',
        backgroundColor: '#090C10',
        fontSize: '14px',
        fontWeight: 500,
        letterSpacing: '1.25px',
        textTransform: 'uppercase',
        '&:hover': { backgroundColor: '#000000' },
    },
    disabledBtn: { opacity: 0.5, cursor: 'not-allowed', pointerEvents: 'all' },
}))

type Props = {
    title?: string
    disabled?: boolean
    onClick?: () => void
}

export const CaptureBtn = ({
    title = 'Capture',
    disabled = false,
    onClick = () => {},
}: Props) => {
    const classes = useStyles()

    return (
        <Button
            classes={{
                root: classes.actionBtn,
                disabled: classes.disabledBtn,
            }}
            disabled={disabled}
            onClick={onClick}
            startIcon={<ArrowForwardIcon />}
        >
            {title}
        </Button>
    )
}
