export const initialPlaybookState = {}

export const playbookReducer = (state = initialPlaybookState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'setPlaybook':
            return payload
        case 'resetPlaybook':
            return initialPlaybookState
        default:
            return state
    }
}
