import { ServicesUsageType } from '@guidde/common-types/fsdb'
import { doc, onSnapshot } from 'firebase/firestore'
import { captureSentryError } from '../services/sentry'
import { PermanentListenerE } from '../types'
import { getUser } from './auth'
import firebase from './init'
import { fetchQuickActionConfig } from '../api/quickActions'
import { sendMsgToAllTabs } from '../background/tabs'

let isInitial: boolean = true
let servicesUsage: ServicesUsageType | {} = {}
let unsubFromServicesUsage: null | (() => void) = null
let subscribedOrgId = ''

const serviceUsageSubscription: (
    orgId: string
) => Promise<ServicesUsageType> = orgId =>
    new Promise(resolve => {
        const usageRef = doc(firebase.firestore(), `servicesUsage/${orgId}`)

        // onSnapshot will receive updates whenever service usages are modified
        unsubFromServicesUsage = onSnapshot(
            usageRef,
            async querySnapshot => {
                const data = querySnapshot.data() as ServicesUsageType
                servicesUsage = data

                sendMsgToAllTabs({
                    action: PermanentListenerE.LISTEN_SERVICE_USAGE_UPDATE,
                    response: servicesUsage,
                })

                if (!isInitial) {
                    // On user settings update, re-fetch quick actions config
                    await fetchQuickActionConfig()
                }

                resolve(data)
            },
            e => {
                isInitial = true // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getFirebaseServiceUsage: () => Promise<
    ServicesUsageType | {}
> = async () => {
    const firebaseUser = getUser()
    const orgId = firebaseUser?.roles?.o

    if (!orgId || orgId !== subscribedOrgId) {
        servicesUsageGC()
    }

    if (orgId && isInitial) {
        subscribedOrgId = orgId
        await serviceUsageSubscription(orgId)
        isInitial = false
    }

    return Promise.resolve(servicesUsage)
}

// Garbage collector to remove all subscriptions and reset everything to initial
export const servicesUsageGC = () => {
    isInitial = true
    subscribedOrgId = ''
    servicesUsage = {} as ServicesUsageType
    unsubFromServicesUsage?.()
}
