import { ChangeEvent } from 'react'

import Input from '@mui/material/Input'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(({ spacing }) => ({
    input: {
        minWidth: '610px',
        padding: spacing(1.8, 3),
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.10)',
        background: 'rgba(0, 0, 0, 0.40)',
    },
    placeholder: {
        color: '#FFFFFF',
        fontSize: '18px',
        fontWeight: 400,
    },
    charCounter: {
        marginTop: spacing(1),
        display: 'flex',
        justifyContent: 'flex-end',
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 400,
        '& > span': { marginLeft: spacing(0.5), opacity: 0.5 },
    },
}))

type Props = {
    value: string
    onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onEnter: () => void
}

export const DescriptionInput = ({ value, onChange, onEnter }: Props) => {
    const classes = useStyles()

    return (
        <>
            <Input
                autoFocus
                required
                fullWidth
                disableUnderline
                multiline
                placeholder="Share what you want to capture"
                maxRows={3}
                classes={{
                    root: classes.input,
                    input: classes.placeholder,
                }}
                value={value}
                onChange={onChange}
                onKeyDown={e => {
                    if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
                        onEnter()
                        e.preventDefault()
                    }
                }}
            />

            <Box className={classes.charCounter}>
                {value.length}
                <span>/150</span>
            </Box>
        </>
    )
}
