import { useEffect, useState, createContext } from 'react'
import { useSelector } from 'react-redux'

import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { PlaybookSearch } from 'UI/Components'

import {
    isAllSpacesSelected,
    PlaybookFilters,
    useFilterState,
} from './PlaybookFilters'
import { PaginatedPlaybooks } from './PaginatedPlaybooks'

import { RootState } from 'ducks/rootReducer'

import { useBoolean, useOrgFeatureFlags } from 'hooks'

import { getFromExtLocalStorage } from 'services/extServices'
import { SPACE_FILTER_STATE } from 'background/constants'

const useStyles = makeStyles(() => ({
    playbooksTabWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    playbooksTabBody: {
        overflow: 'auto',
        flexGrow: 1,
    },
}))

export const SearchByAppContext = createContext({
    setSearchResult: (_result: boolean) => {},
    setSearchWithDefaultTagsResult: (_result: boolean) => {},
})

export const isMainSpace = spaces => {
    if (spaces.includes('ALL')) {
        return false
    }

    return spaces.some(it => it === 'mainSpace')
}
export const generateSpaceIds = (spaces: string[]) => {
    if (spaces.includes('ALL')) {
        return []
    }

    return spaces.filter(it => it !== 'mainSpace')
}

export const PagePlaybooks = ({ predefinedSearch, defaultFilters }) => {
    const classes = useStyles()
    const resultIsEmpty = useBoolean()
    const resultWithDefaultTagsIsEmpty = useBoolean()
    const { isFlagsLoading } = useOrgFeatureFlags()

    // TODO: Investigate why search text does not have usages, since it should be a core variable for search
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [searchText, setSearchText] = useState<null | string>(null)

    const appFilter = useFilterState<string>('')
    const tagsFilter = useFilterState<Array<string>>([])
    const spaceFilter = useFilterState<string[]>([])

    const apps = useSelector((state: RootState) => state.apps)
    const userSpaces = useSelector((state: RootState) => state.spaces)

    const selectedAppName = apps.find(
        app => app.id === appFilter.state || app.id === appFilter.defaultState
    )?.title

    const handleSpaceSelection = selectedSpaces => {
        if (isAllSpacesSelected(selectedSpaces)) {
            return
        }

        appFilter.resetState()
        tagsFilter.resetState()
    }

    useEffect(() => {
        const userSpacesIds = ['ALL', ...Object.keys(userSpaces || {})]

        getFromExtLocalStorage([SPACE_FILTER_STATE], result => {
            if (!result[SPACE_FILTER_STATE]) return

            const filteredSpaces = result[SPACE_FILTER_STATE].filter(
                savedSpaceId => userSpacesIds.includes(savedSpaceId)
            )

            spaceFilter.updateState(filteredSpaces)
            handleSpaceSelection(filteredSpaces)
        })

        if (!chrome?.storage) {
            return
        }

        const listener = (storage, storageType) => {
            const selectedSpaces = storage[SPACE_FILTER_STATE]?.newValue

            if (storageType !== 'local' || !selectedSpaces) {
                return
            }

            spaceFilter.updateState(selectedSpaces)
            handleSpaceSelection(selectedSpaces)
        }

        chrome.storage.onChanged.addListener(listener)

        return () => chrome.storage.onChanged.removeListener(listener)

        // we set default value as last selected only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSpaces])

    useEffect(() => {
        tagsFilter.initDefaultState(defaultFilters.tags, [])
        handleSpaceSelection(spaceFilter.state)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultFilters.tags]) // Ignore effect on callback changes

    if (isFlagsLoading) return null

    return (
        <Box className={classes.playbooksTabWrapper}>
            <Box p={3} className={classes.playbooksTabBody}>
                <PlaybookSearch
                    predefinedSearch={predefinedSearch}
                    appFilter={appFilter}
                    onSearchChange={setSearchText}
                    spaceIds={spaceFilter.state}
                />

                <Box my={2}>
                    <PlaybookFilters
                        appFilter={appFilter}
                        tagsFilter={tagsFilter}
                        spaceFilter={spaceFilter}
                    />
                </Box>

                <Box>
                    <Typography>
                        <b>
                            {(selectedAppName && resultIsEmpty.isTrue) ||
                            (selectedAppName &&
                                resultWithDefaultTagsIsEmpty.isTrue)
                                ? `Top results for ${selectedAppName}`
                                : 'Latest Videos'}
                        </b>
                    </Typography>

                    <SearchByAppContext.Provider
                        value={{
                            setSearchResult: resultIsEmpty.set,
                            setSearchWithDefaultTagsResult:
                                resultWithDefaultTagsIsEmpty.set,
                        }}
                    >
                        <PaginatedPlaybooks
                            appFilter={appFilter}
                            tagsFilter={tagsFilter}
                            spaceFilter={spaceFilter}
                        />
                    </SearchByAppContext.Provider>
                </Box>
            </Box>
        </Box>
    )
}
