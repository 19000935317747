import { request } from './utils'

const fetchTags = async (domain: string) => {
    try {
        const data = await request('/k/v1/getTagConditions', 'POST', { domain })
        return data?.tagConditions || []
    } catch (e) {
        return []
    }
}

export const getTagConditions = async (domain: string) => {
    // Try-catch is needed for cases when session storage not found (incognito)
    try {
        // Saving tagConditions to session storage to avoid re-fetching every time tagsParser runs
        const sessionStorageKey = `GUIDDE_TAG_CONDITIONS_${domain}`
        const cachedResponse = sessionStorage.getItem(sessionStorageKey)

        if (cachedResponse) {
            return JSON.parse(cachedResponse)
        }

        const conditions = await fetchTags(domain)
        sessionStorage?.setItem(sessionStorageKey, JSON.stringify(conditions))
        return conditions
    } catch (e) {
        return await fetchTags(domain)
    }
}
