import { useCallback, useEffect, useState, memo } from 'react'
import { useSelector } from 'react-redux'

import { ShareOnlyDialog } from '@guidde-co/shared.share-only-dialog'
import { useBoolean, useNotification, useRealtimeVideoTime } from 'hooks'

import { playbookToAnalyticsProps } from 'services/analytics'

import {
    getPlaybookOptions,
    getPlaybookSteps,
    sharePlaybookOnly,
    logToAnalytics,
} from 'services/swMessenger'

import { RootState } from 'ducks/rootReducer'
import { PlaybookType } from 'types'
import { useAuthDomain } from 'hooks'

export const validateEmail = (email: string) => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

type ShareDialogProps = {
    onClose: () => void
    playbook: PlaybookType
}

type ShareableSpacesUserType = {
    displayName: string
    email: string
    isSelected: boolean
    logo: string
    uid: string
}
type PendingEmailType = {
    email: string
    isSelected: boolean
}

type OptionsType = {
    spaces: Array<{
        id: string
        name: string
        logo: string
        isSelected: boolean
        isSuggested?: boolean
        isDisabled: boolean
    }>
    internalUsers: Array<ShareableSpacesUserType>
    externalUsers: Array<ShareableSpacesUserType>
    pendingEmails: Array<PendingEmailType>
}

export const ShareDialog = memo(({ onClose, playbook }: ShareDialogProps) => {
    const { id, isPublic, isPlaylist } = playbook
    const authDomain = useAuthDomain()
    const { showSuccessNotification, showErrorNotification } = useNotification()

    const activeTime = useRealtimeVideoTime()
    const { user } = useSelector((state: RootState) => state.user)

    const shareLoading = useBoolean()
    const stepsLoading = useBoolean()
    const optionsLoading = useBoolean()

    const [steps, setSteps] = useState([])
    const [options, setOptions] = useState<OptionsType>({
        spaces: [],
        internalUsers: [],
        externalUsers: [],
        pendingEmails: [],
    })

    const setStepsLoading = stepsLoading.set
    const setOptionsLoading = optionsLoading.set

    const getSteps = useCallback(async () => {
        setStepsLoading(true)

        getPlaybookSteps(data => {
            setSteps(data.steps)
            setStepsLoading(false)
        }, id)
    }, [id, setSteps, setStepsLoading])

    const getShareOptions = useCallback(async () => {
        setOptionsLoading(true)

        getPlaybookOptions(
            data => {
                setOptions(data)
                setOptionsLoading(false)
            },
            {
                playbooksIds: [id],
                spaces: true,
                internalUsers: true,
                externalUsers: true,
                pendingEmails: true,
            }
        )
    }, [id, setOptionsLoading, setOptions])

    useEffect(() => {
        getShareOptions()
    }, [id, getShareOptions])

    useEffect(() => {
        if (steps.length) return

        getSteps()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, getSteps])

    const playbookPath = !isPublic
        ? `/playbooks/${id}`
        : `/share/playbooks/${id}`

    const playlistPath = !isPublic
        ? `/playbooks/playlist/${id}`
        : `/share/playlists/${id}`

    const copyLinkPath = isPlaylist ? playlistPath : playbookPath
    const link = `${authDomain}${copyLinkPath}`

    return (
        <ShareOnlyDialog
            isOpen={true}
            showFacebookButton={true}
            gifInProcessing={false}
            facebookAppId="3155065591456766"
            guiddeWebsiteUrl="https://www.guidde.com"
            socialBtnTooltip="Adjust video visibility to 'Public' for social sharing"
            url={link}
            copyLinkPath={link}
            origin={authDomain}
            uid={user.uid}
            onClose={onClose}
            onShare={({ emails, userIds }) => {
                const includeInvalidEmail = emails.some(
                    email => !validateEmail(email)
                )

                if (includeInvalidEmail) {
                    showErrorNotification('Some email is invalid')
                    return
                }
                shareLoading.setTrue()
                sharePlaybookOnly(
                    () => {
                        shareLoading.setFalse()
                        onClose()
                        showSuccessNotification('Video shared successfully')
                    },
                    {
                        emails,
                        userIds,
                        isPublic,
                        playbooksIds: [id],
                    }
                ).then(() => {
                    logToAnalytics('share_playbook', {
                        sharedByUid: user.uid,
                        sentTo: emails,
                        playbookIds: [id],
                    })
                })
            }}
            title={`Share your ${playbook.isPlaylist ? 'playlist' : 'video'}`}
            steps={steps}
            playbook={playbook}
            isLoading={
                optionsLoading.isTrue ||
                stepsLoading.isTrue ||
                shareLoading.isTrue
            }
            options={options}
            currentTime={activeTime || 0}
            onCopySharedLink={() => {
                showSuccessNotification('Link copied to the clipboard')
                logToAnalytics('copyLink', playbookToAnalyticsProps(playbook))
            }}
            onCopyGifLink={() => {
                showSuccessNotification(
                    'Linked GIF was copied to the clipboard'
                )

                const context =
                    playbook.mode === 'quickGuidde'
                        ? 'copy-linked-gif-qg'
                        : 'copy-linked-gif'

                logToAnalytics('copyHtmlClicked', {
                    context,
                    ...playbookToAnalyticsProps(playbook),
                })
            }}
        />
    )
})
