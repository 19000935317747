export const initialState = {}

export const spaceReducer = (state = initialState, action) => {
    const { type, payload } = action

    switch (type) {
        case 'setSpaces':
            return payload
        default:
            return state
    }
}
