import { getUser } from './auth'
import firebase from './init'
import { FeatureFlagsType } from '../types'
import { doc, onSnapshot } from 'firebase/firestore'
import { captureSentryError } from '../services/sentry'
import { isEmpty } from '../services/lodash'

let isInitial: boolean = true
let featureFlags: FeatureFlagsType | {} = {} // Apps that can be used by this org
let unsubFromFeatureFlags: null | (() => void) = null

const featureFlagsSubscription: (
    orgId: string
) => Promise<Partial<FeatureFlagsType>> = orgId =>
    new Promise(resolve => {
        const ffRef = doc(firebase.firestore(), `featureFlags/${orgId}`)

        // onSnapshot will receive updates whenever feature flags are modified
        unsubFromFeatureFlags = onSnapshot(
            ffRef,
            querySnapshot => {
                const ffSnap = querySnapshot.data() as Partial<FeatureFlagsType>
                featureFlags = ffSnap
                resolve(ffSnap)
            },
            e => {
                isInitial = false // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getFirebaseOrgFeatureFlags: () => Promise<
    Partial<FeatureFlagsType> | {}
> = () => {
    const firebaseUser = getUser()
    const orgId = firebaseUser?.roles?.o

    if (!orgId) return Promise.resolve({})

    if (isInitial) {
        isInitial = false
        return featureFlagsSubscription(orgId)
    }

    // Means that subscription is being established, retry in 1 second
    if (isEmpty(featureFlags)) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(getFirebaseOrgFeatureFlags())
            }, 1000)
        })
    }

    return Promise.resolve(featureFlags)
}

// Garbage collector to remove all subscriptions and reset everything to initial
export const firebaseFeatureFlagsGC = () => {
    isInitial = true
    featureFlags = {}
    unsubFromFeatureFlags?.()
}
