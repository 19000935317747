import { useState, useEffect, useMemo } from 'react'
import { generatePlayerId } from 'services/video'

export const useRealtimeVideoTime = () => {
    const [activeTime, setActiveTime] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            const el = document.querySelector<HTMLVideoElement>(
                `#${generatePlayerId()}`
            )
            if (el) {
                setActiveTime(el.currentTime)
            }
            return
        }, 1500)

        return () => {
            if (interval) {
                clearInterval(interval)
            }
        }
    }, [])

    return useMemo((): number => activeTime, [activeTime])
}
