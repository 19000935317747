import { useEffect, useMemo, useState } from 'react'

import { getAuthDomain } from '../services/getAuthDomain'

export const useAuthDomain = () => {
    const [authDomain, setAuthDomain] = useState<string>('')

    useEffect(() => {
        getAuthDomain().then(authDomain => {
            setAuthDomain(`https://${authDomain}`)
        })
    }, [])

    return useMemo(() => authDomain, [authDomain])
}
