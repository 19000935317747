import React, { useContext, useEffect } from 'react'

import { useSelector } from 'react-redux'
import { useBoolean, useStorageSetting } from 'hooks'

import { PlaybookSettings } from 'UI/Components/VideoRecorder/PlaybookSettings'
import { ServiceUsageController } from 'UI/Components'
import { AppContext } from 'app/AppProvider'
import { RootState } from 'ducks/rootReducer'

import LinearProgress from '@mui/material/LinearProgress'
import DialogTitle from '@mui/material/DialogTitle'
import { RecSettingsTitle } from './RecSettingsTitle'
import { DialogContent } from '@mui/material'
import { Actions } from './DialogActions'
import DialogActions from '@mui/material/DialogActions'
import makeStyles from '@mui/styles/makeStyles'
import { WEBCAM_SETTING } from '../../../background/constants'
import { VideoOptions } from './VideoOptions'
import { QGSettings } from './QGSettings'
import { RecordingKeyboard } from './RecordingKeyboard'
import { RecSettingsFooter } from './RecSettingsFooter'

const useStyles = makeStyles(theme => ({
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'hidden',
        '&::-webkit-scrollbar': {
            width: '20px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#D8D8D8',
            borderRadius: '20px',
            border: '6px solid transparent',
            backgroundClip: 'content-box',
        },
    },
    dialogTitle: {
        padding: theme.spacing(1, 0.5),
        '& h2': {
            fontSize: '12px',
            fontWeight: 400,
            LineHeight: '16px',
            color: theme.palette.text.primary,
        },
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
    },
    description: {
        fontSize: '20px',
        fontWeight: 500,
    },
}))

export const VideoRecorder = ({
    showGtvQuickSearch,
    startCapture,
    onClose,
    onStartRecording,
}) => {
    const classes = useStyles()
    const recSettingsLoadingState = useBoolean(false)
    const {
        serviceUsage: { isRecordingLocked, hasScreenRecording },
    } = useContext(AppContext)
    const { roles } = useSelector((state: RootState) => state.user)

    const isAdmin = roles.a === 100
    const isViewer = roles.a === 10

    const showOnlyQG: boolean = !hasScreenRecording
    const screenCamSetting = useStorageSetting(WEBCAM_SETTING)
    const isQG = screenCamSetting.storageSetting === 'stepByStep'

    // Set value to QG if for some reason screenCamSetting is empty in storage
    useEffect(() => {
        const isStorageEmpty =
            !screenCamSetting.isInitialLoading &&
            !screenCamSetting.storageSetting

        if (isStorageEmpty) {
            screenCamSetting.changeSetting('stepByStep')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenCamSetting.isInitialLoading])

    // Set value to QG if user has no access to screen recording
    useEffect(() => {
        if (showOnlyQG) {
            screenCamSetting.changeSetting('stepByStep')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOnlyQG])

    if (isAdmin && isRecordingLocked) {
        return <ServiceUsageController onClose={onClose} />
    }

    const isLoading =
        screenCamSetting.isInitialLoading || recSettingsLoadingState.isTrue
    const userCannotCreateContent = isRecordingLocked || isViewer

    return (
        <>
            {isLoading && <LinearProgress />}

            <DialogTitle className={classes.dialogTitle}>
                <RecSettingsTitle />
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {screenCamSetting.storageSetting && (
                    <>
                        {showOnlyQG ? (
                            <span className={classes.description}>
                                Create how-to videos
                            </span>
                        ) : (
                            <VideoOptions screenCamSetting={screenCamSetting} />
                        )}

                        {/* QG settings are separated to avoid requesting mic/cam permissions */}
                        {isQG ? (
                            <QGSettings
                                startQGCapture={onStartRecording}
                                isRecordingLocked={userCannotCreateContent}
                            />
                        ) : (
                            <PlaybookSettings
                                isRecordingLocked={userCannotCreateContent}
                                loadingState={recSettingsLoadingState}
                                startCapture={startCapture}
                            />
                        )}

                        <RecordingKeyboard />
                    </>
                )}
            </DialogContent>

            <DialogActions className={classes.actions}>
                <Actions isQG={isQG} />
            </DialogActions>

            <RecSettingsFooter
                isOpenBroadcastLibraryOnClick={showGtvQuickSearch}
                showQGRecordOnly={showOnlyQG}
                loadingState={recSettingsLoadingState}
                onClose={onClose}
            />
        </>
    )
}
