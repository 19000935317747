import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import clsx from 'clsx'

import makeStyles from '@mui/styles/makeStyles'

import {
    NoStepsMessageDialog,
    RecordingErrorDialog,
    QuickGuiddeStart,
    VideoRecorder,
} from './UI/Components'
import {
    subscribeToFirebaseUser,
    getQGRecordingState,
    subscribeToRecordingStatus,
    getUserAchievements,
    logToAnalytics,
    onQGScreenshotRemote,
    onRecordFromBackground,
    onRecordFromContext,
    onRecordFromKeyboard,
    onRecordFromWebapp,
    onRestartPB,
    onRestartQG,
    sendManualQGStep,
    startQGRecording,
    startScreenCapture,
    stopQGRecordingNow,
    stopScreenCapture,
    onPauseResumeRemote,
    pauseResumeQGRecording,
    onQGStatusChange,
    startParallelCapture,
} from './services/swMessenger'
import { setRecordingStatus } from './ducks/actions'
import { useDispatch, useSelector } from 'react-redux'
import { trimResultTag } from './services/tagsParser'
import { getSelectionText } from './services/utils'
import { RootState } from './ducks/rootReducer'
import { AppContext } from './app/AppProvider'
import { Dialog } from '@mui/material'
import {
    useBoolean,
    useOrgFeatureFlags,
    useParentListener,
    useParentLocation,
    useStorageSetting,
    useTagsParser,
} from './hooks'
import { initialUserState } from './ducks/reducers'
import { setSentryUser } from './services/sentry'
import {
    composeGlobalProps,
    composeUserProps,
} from './services/utilsForAnalytics'
import { QG_STATE, QG_STATE_UNION } from './types/quickGuidde'
import { getDimensions } from './services/qgService'
import { RecordingIframeActionE } from './services/parentService'
import { MagicCaptureType, RecordingStatusE } from './types'
import { AlmostThereDialog } from 'UI/Components/AlmostThereDialog'
import { REC_MODE_SETTING, WEBCAM_SETTING } from './background/constants'
import { PinExtDialog } from './UI/Components/PinExtDialog'
import { RecPanel } from './UI/Components/RecPanel'
import { ProgressState } from './UI/Components/ProgressState'
import { RecordingWizard } from './UI/Components/RecordingWizard'

const useStyles = makeStyles({
    mainOld: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        padding: '1vh 3vw',
    },
    modalOld: {
        width: '320px',
        margin: '0',
        fontFamily: 'Roboto, sans-serif',
    },
    qgOnlyModal: {
        backgroundColor: '#ffffff',
    },
    regularModal: {
        backgroundColor: '#F5F5F5',
    },
    main: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        height: 'auto',
    },
    modal: {
        overflow: 'visible',
        maxWidth: 'unset',
        margin: '0',
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: 'unset',
        boxShadow: 'none',
    },
    backdrop: {
        backdropFilter: 'blur(2px)',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background:
                'radial-gradient(50% 50% at 50% 50%, rgba(236, 0, 212, 0.60) 0%, rgba(110, 7, 146, 0.60) 47.11%, rgba(0, 0, 0, 0.60) 100%)',
            strokeWidth: '1px',
            stroke: '#000',
            filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
            backgroundSize: '300% 300%',
            animation: '$gradientAnimation 30s ease infinite',
            opacity: 0.8, // Adjust opacity if needed
        },
    },
    '@keyframes gradientAnimation': {
        '0%': {
            backgroundPosition: '0% 50%',
        },
        '50%': {
            backgroundPosition: '100% 50%',
        },
        '100%': {
            backgroundPosition: '0% 50%',
        },
    },
})

type MixpanelRecordingSource =
    | ''
    | 'start_recording'
    | 'start_recording_from_webapp'
    | 'start_recording_from_context_menu'
    | 'start_recording_from_keyboard_shortcut'
    | 'start_recording_from_browserAction'

export const AppRecording = () => {
    const { showGtvQuickSearch, showExtWizard, tryIdeal4k } =
        useOrgFeatureFlags()
    const classes = useStyles()
    const dispatch = useDispatch()

    const { openModalMode, closeModalMode, serviceUsage } =
        useContext(AppContext)

    const showOnlyQG: boolean = !serviceUsage.hasScreenRecording

    const [mixpanelSource, setMixpanelSource] =
        useState<MixpanelRecordingSource>('')
    const [textSelection, setTextSelection] = useState<string>('')
    const { status: pbStatus } = useSelector(
        (state: RootState) => state.screenCapture
    )
    const [isFirstQG, setQGStatus] = useState<boolean | null>(null)
    const [qgState, setQGState] = useState<QG_STATE_UNION | null>(null)

    const { roles } = useSelector((state: RootState) => state.user)
    const almostThereDialog = useBoolean()
    const showRecSettingsDialog = useBoolean()
    const showMessageDialog = useBoolean()
    const showRecordingErrorDialog = useBoolean()
    const showQGStartPage = useBoolean()
    const showPinExtDialog = useBoolean()

    const isPbRecording = pbStatus === RecordingStatusE.RECORDING
    const isQGRecording =
        qgState === QG_STATE.RECORDING || qgState === QG_STATE.PAUSED

    const isRecording = isPbRecording || isQGRecording
    const isProcessing =
        pbStatus === RecordingStatusE.PROCESSING ||
        qgState === QG_STATE.PROCESSING

    const remoteRecControls = useCallback(
        (source: 'contextMenu' | 'keyboardShortcut', selection?: string) => {
            getQGRecordingState(qgState => {
                // If playbook is in progress, stop it
                if (isPbRecording) {
                    stopScreenCapture({ mxpMeta: { stopSource: source } })
                    return
                }

                const isQGRecording = qgState.state !== QG_STATE.READY

                // If QG is in progress, stop it
                if (isQGRecording) {
                    stopQGRecordingNow(false)
                    return
                }

                // QG and Playbook are not in progress, show rec UI
                const textSelection = selection || getSelectionText()
                const newTag = trimResultTag(textSelection)
                setTextSelection(newTag)

                setMixpanelSource(
                    source === 'contextMenu'
                        ? 'start_recording_from_context_menu'
                        : 'start_recording_from_keyboard_shortcut'
                )

                logToAnalytics('open_recording_panel', {
                    source,
                })

                openModalMode()
            })
        },
        [isPbRecording, openModalMode]
    )

    useEffect(() => {
        onRecordFromBackground(() => {
            if (!isPbRecording) {
                openModalMode()
                setMixpanelSource('start_recording_from_browserAction')
            }
        })

        onRecordFromWebapp(() => {
            if (!isPbRecording) {
                openModalMode()
                setMixpanelSource('start_recording_from_webapp')
            }
        })

        onRecordFromContext(({ selection }) =>
            remoteRecControls('contextMenu', selection)
        )

        onRecordFromKeyboard(() => remoteRecControls('keyboardShortcut'))
    }, [isPbRecording, openModalMode, remoteRecControls])

    useEffect(() => {
        // sendManualQGStep in background has a check whether a QG is in progress
        onQGScreenshotRemote(() => {
            sendManualQGStep(true)
        })

        onPauseResumeRemote(() => {
            closeModalMode()
            pauseResumeQGRecording()
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Permanent listener should be established once, keep this empty!

    useEffect(() => {
        subscribeToFirebaseUser(payload => {
            dispatch({
                type: 'userChanged',
                payload: payload || initialUserState,
            })

            setSentryUser({
                ...composeGlobalProps(),
                ...composeUserProps(payload),
                id: payload?.user?.uid,
                username: payload?.user?.displayName,
                email: payload?.user?.email,
            })
        })

        subscribeToRecordingStatus(payload => {
            dispatch(setRecordingStatus(payload.status))
        })

        getUserAchievements(data => {
            setQGStatus(!data?.createdFirstQuickGuidde)
        })

        getQGRecordingState(({ state }) => {
            setQGState(state)
        })

        onQGStatusChange(({ newState }) => {
            setQGState(newState)
        })
    }, [dispatch])

    const hideRecordingSettings = useCallback(() => {
        closeModalMode()
        setTextSelection('')
        setMixpanelSource('')
        showRecSettingsDialog.setFalse()
    }, [closeModalMode, showRecSettingsDialog])

    const hideNoStepsMessageDialog = useCallback(() => {
        closeModalMode()
        showMessageDialog.setFalse()
    }, [closeModalMode, showMessageDialog])

    const hideRecordingErrorDialog = useCallback(() => {
        closeModalMode()
        showRecordingErrorDialog.setFalse()
    }, [closeModalMode, showRecordingErrorDialog])

    const hideQGStartPage = useCallback(() => {
        startParallelCapture() // Video capture should start after the start screen is closed
        closeModalMode()
        showQGStartPage.setFalse()
    }, [closeModalMode, showQGStartPage])

    const openQGStartPage = useCallback(() => {
        showQGStartPage.setTrue()

        if (!isFirstQG) {
            setTimeout(() => {
                hideQGStartPage()
            }, 3000)
        }
    }, [hideQGStartPage, isFirstQG, showQGStartPage])

    // QuickGuidde start/restart capture
    const prevQGSettings = useRef<MagicCaptureType | undefined>(undefined)
    const startRecordingQG = useCallback(
        async (magicCapture?: MagicCaptureType, isRestart?: boolean) => {
            showRecSettingsDialog.setFalse()
            // QG start page should be shown before recording or on restart iframe dimensions are 0
            openQGStartPage()

            const dimensions = await getDimensions()

            prevQGSettings.current = magicCapture

            startQGRecording({
                dimensions,
                isCreatePlaybook: true,
                devicePixelRatio: window?.devicePixelRatio,
                ...(magicCapture ? { magicCapture } : {}),
                isRestart,
            })
        },
        [openQGStartPage, showRecSettingsDialog]
    )

    const restartRecordingQG = useCallback(() => {
        showMessageDialog.setFalse()
        showRecordingErrorDialog.setFalse()
        startRecordingQG(prevQGSettings?.current, true)
    }, [showMessageDialog, showRecordingErrorDialog, startRecordingQG])

    useEffect(() => {
        onRestartQG(() => {
            openModalMode()
            restartRecordingQG()
        })
    }, [openModalMode, restartRecordingQG])

    // Playbook start/restart capture
    const defaultFilters = useTagsParser()
    const location = useParentLocation()
    const startCapture = useCallback(
        (description?: string, isWizard?: boolean) => {
            hideRecordingSettings()
            const params = {
                defaultFilters: {
                    app: defaultFilters.app,
                    appName: defaultFilters.appName,
                    domain: defaultFilters.domain,
                    tags: (defaultFilters.tags || []).concat(
                        textSelection ? [textSelection] : []
                    ),
                },
                link: location?.href || '',
                description,
                tryIdeal4k,
                isWizard,
            }

            startScreenCapture(() => {
                logToAnalytics(mixpanelSource || 'start_recording', params)
                setTextSelection('')
                setMixpanelSource('')
            }, params)
        },
        [
            hideRecordingSettings,
            defaultFilters.app,
            defaultFilters.appName,
            defaultFilters.domain,
            defaultFilters.tags,
            textSelection,
            location?.href,
            mixpanelSource,
            tryIdeal4k,
        ]
    )

    useEffect(() => {
        onRestartPB(() => {
            startCapture()
        })
    }, [startCapture])

    useParentListener(msgType => {
        if (msgType === RecordingIframeActionE.OPEN_SETTINGS) {
            // if the user has no organization id it is a new user (did not finish onboarding)
            // block the user from recording a QG
            if (!roles?.o || roles?.n) {
                almostThereDialog.setTrue()
                return
            }

            showRecSettingsDialog.setTrue()
        }

        if (msgType === RecordingIframeActionE.OPEN_NO_STEPS_MESSAGE) {
            openModalMode()
            showMessageDialog.setTrue()
        }

        if (msgType === RecordingIframeActionE.OPEN_RECORDING_ERROR_MESSAGE) {
            openModalMode()
            showRecordingErrorDialog.setTrue()
        }

        if (msgType === RecordingIframeActionE.OPEN_PIN_EXT_CTA) {
            openModalMode()
            showPinExtDialog.setTrue()
        }
    })

    // Set quick guidde as default recording mode
    const {
        storageSetting: isScreenCamSet,
        changeSetting: setDefaultScreenCam,
    } = useStorageSetting(WEBCAM_SETTING, true)

    useEffect(() => {
        // If the setting is undefined, it is not loaded.
        // If it is false, it is loaded but not set, so we set it to default value.
        if (isScreenCamSet === false) {
            setDefaultScreenCam('stepByStep')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isScreenCamSet]) // Only run on setting change

    const { storageSetting: isRecModeSet, changeSetting: setRecMode } =
        useStorageSetting(REC_MODE_SETTING, true)

    useEffect(() => {
        // If the setting is undefined, it is not loaded.
        // If it is false, it is loaded but not set, so we set it to default value.
        if (isRecModeSet === false) {
            setRecMode('quickGuidde')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecModeSet]) // Only run on setting change

    const useNewStyles = useMemo(() => {
        return showExtWizard && !isRecording && !isProcessing
    }, [isProcessing, isRecording, showExtWizard])

    return (
        <>
            <Dialog
                open={showRecSettingsDialog.isTrue}
                onClose={hideRecordingSettings}
                scroll="paper"
                classes={{
                    root: useNewStyles ? classes.main : classes.mainOld,
                    paper: clsx(
                        useNewStyles ? classes.modal : classes.modalOld,
                        !useNewStyles && showOnlyQG
                            ? classes.qgOnlyModal
                            : classes.regularModal
                    ),
                    container: classes.container,
                }}
                BackdropProps={
                    useNewStyles
                        ? { classes: { root: classes.backdrop } }
                        : { style: { backdropFilter: 'blur(5px)' } }
                }
            >
                {isRecording && (
                    <RecPanel
                        mode={isQGRecording ? 'quickGuidde' : 'playbook'}
                        qgState={qgState}
                        closeModal={closeModalMode}
                    />
                )}
                {isProcessing && <ProgressState />}
                {!isRecording &&
                    !isProcessing &&
                    (showExtWizard ? (
                        <RecordingWizard
                            showQGRecordOnly={showOnlyQG}
                            startQG={startRecordingQG}
                            startPB={startCapture}
                        />
                    ) : (
                        <VideoRecorder
                            showGtvQuickSearch={showGtvQuickSearch}
                            startCapture={startCapture}
                            onClose={hideRecordingSettings}
                            onStartRecording={startRecordingQG}
                        />
                    ))}
            </Dialog>

            <NoStepsMessageDialog
                isModalOpen={showMessageDialog.isTrue}
                onClose={hideNoStepsMessageDialog}
                onRestart={restartRecordingQG}
            />

            <RecordingErrorDialog
                isModalOpen={showRecordingErrorDialog.isTrue}
                onClose={hideRecordingErrorDialog}
                onRestart={restartRecordingQG}
            />

            {showPinExtDialog.isTrue && (
                <PinExtDialog
                    onClose={() => {
                        showPinExtDialog.setFalse()
                        closeModalMode()
                    }}
                />
            )}

            {almostThereDialog.isTrue && (
                <AlmostThereDialog
                    onClose={() => {
                        almostThereDialog.setFalse()
                        closeModalMode()
                    }}
                />
            )}

            {showQGStartPage.isTrue && (
                <QuickGuiddeStart
                    isFirstQG={isFirstQG}
                    onClose={hideQGStartPage}
                />
            )}
        </>
    )
}
