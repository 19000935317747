type Transcript = {
    time: string
    text: string
    startTime: number
}

type TranscriptRange = {
    start: number
    finish: number | 'none'
}

export const generateTranscriptRanges = (transcript): TranscriptRange[] => {
    if (!transcript) return []

    return transcript.reduce((acc: TranscriptRange[], it: Transcript, i) => {
        return [
            ...acc,
            {
                start: +it.startTime,
                finish: +transcript[i + 1]?.startTime || 'none',
            },
        ]
    }, [])
}

export const generatePlayerId = () => {
    return process.env.NODE_ENV === 'development'
        ? 'GUIDDE-PLAYER_DEV'
        : 'GUIDDE-PLAYER'
}
