export enum PermanentListenerE {
    LISTEN_SERVICE_USAGE_UPDATE = 'LISTENER/SERVICE_USAGE_UPDATE',
    SET_QUICK_ACTION_LIB_MODE = 'LISTENER/SET_QUICK_ACTION_LIB_MODE',
    OPEN_CONFIRM_SHARE_DIALOG = 'LISTENER/OPEN_CONFIRM_SHARE_DIALOG',
    INSERT_QUICK_ACTION_RESULT = 'LISTENER/INSERT_QUICK_ACTION_RESULT',
    QG_STATE_CHANGED = 'QG_STATE_CHANGED',
    REMOVE_BROADCAST_FROM_DOM = 'REMOVE_BROADCAST_FROM_DOM',
    RETURN_BROADCAST_INTO_DOM = 'RETURN_BROADCAST_INTO_DOM',
}

export enum ParentAPIMessageE {
    RUN_REC = 'RUN_REC',
    RUN_LIB = 'RUN_LIB',
    START_WEBCAM_AND_COUNTDOWN = 'START_WEBCAM_AND_COUNTDOWN',
    INJECT_REC_CONTROLS = 'INJECT_REC_CONTROLS',
    URL_CHANGED = 'URL_CHANGED',
}
