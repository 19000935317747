import React, { useCallback } from 'react'
import clsx from 'clsx'

import FormControlLabel from '@mui/material/FormControlLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import makeStyles from '@mui/styles/makeStyles'

import stepByStepOn from '../../../assets/icons/stepByStepOn.svg'
import stepByStepOff from '../../../assets/icons/stepByStepOff.svg'
import cameraOn from '../../../assets/icons/cameraOn.svg'
import cameraOff from '../../../assets/icons/cameraOff.svg'

import { WEBCAM_OPTIONS } from '../../../background/constants'

const useStyles = makeStyles(theme => ({
    checked: {}, // overrides the check style to nothing, please do not remove
    radioGroup: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    selected: { background: `#FFFFFF !important`, color: '#090C10 !important' },
    label: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'inherit',
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
    selectionBoxImage: {
        width: '25px',
        height: '25px',
        marginRight: theme.spacing(1),
    },
    radio: {
        display: 'none',
    },
    root: {
        margin: '0',
        minHeight: '31px',
        width: '46%',
        color: '#828282',
        backgroundColor: '#EDEEEF',
        borderRadius: '4px',
        border: `2px solid transparent`,
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
    },
}))

const images = {
    stepByStepOn: stepByStepOn,
    stepByStepOff: stepByStepOff,
    cameraOn: cameraOn,
    cameraOff: cameraOff,
}

export const VideoOptions = ({ screenCamSetting }) => {
    const classes = useStyles()

    const getSelectionImage = useCallback(
        option => {
            return option.value === screenCamSetting.storageSetting
                ? images[option?.onImage]
                : images[option?.offImage]
        },
        [screenCamSetting.storageSetting]
    )

    return (
        <RadioGroup
            className={classes.radioGroup}
            value={screenCamSetting.storageSetting || ''}
            onChange={event => {
                const { value } = event.target
                screenCamSetting.changeSetting(value)
            }}
        >
            {WEBCAM_OPTIONS.map(option => (
                <FormControlLabel
                    key={option.value}
                    value={option.value}
                    classes={{
                        root: clsx(classes.root, {
                            [classes.selected]:
                                screenCamSetting.storageSetting === 'stepByStep'
                                    ? option.value === 'stepByStep'
                                    : option.value !== 'stepByStep',
                        }),
                        label: classes.label,
                    }}
                    control={<Radio className={classes.radio} />}
                    label={
                        <>
                            <img
                                alt={option.label}
                                src={getSelectionImage(option)}
                                className={classes.selectionBoxImage}
                            />
                            <span>{option.label}</span>
                        </>
                    }
                />
            ))}
        </RadioGroup>
    )
}
