import firebase from './init'
import { FeatureFlagsGlobalType } from '../types'
import { doc, onSnapshot } from 'firebase/firestore'
import { captureSentryError } from '../services/sentry'
import { isEmpty } from '../services/lodash'

let isInitial: boolean = true
let globalFeaturesFlags: FeatureFlagsGlobalType | {} = {}
let unsubFromFeatureFlags: null | (() => void) = null

const featureFlagsGlobalSubscription: () => Promise<
    Partial<FeatureFlagsGlobalType>
> = () =>
    new Promise(resolve => {
        const ffRef = doc(
            firebase.firestore(),
            `featureFlags/globalFeatureFlags`
        )

        // onSnapshot will receive updates whenever feature flags are modified
        unsubFromFeatureFlags = onSnapshot(
            ffRef,
            querySnapshot => {
                const ffSnap =
                    querySnapshot.data() as Partial<FeatureFlagsGlobalType>
                globalFeaturesFlags = ffSnap
                resolve(ffSnap)
            },
            e => {
                isInitial = false // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getFirebaseGlobalFeatureFlags: () => Promise<
    Partial<FeatureFlagsGlobalType> | {}
> = () => {
    if (isInitial) {
        isInitial = false
        return featureFlagsGlobalSubscription()
    }

    // Means that subscription is being established, retry in 1 second
    if (isEmpty(globalFeaturesFlags)) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(getFirebaseGlobalFeatureFlags())
            }, 1000)
        })
    }

    return Promise.resolve(globalFeaturesFlags)
}

// Garbage collector to remove all subscriptions and reset everything to initial
export const firebaseFeatureFlagsGlobalGC = () => {
    isInitial = true
    globalFeaturesFlags = {}
    unsubFromFeatureFlags?.()
}
