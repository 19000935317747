import { useEffect } from 'react'
import clsx from 'clsx'

import MicNoneIcon from '@mui/icons-material/MicNone'
import MicOffIcon from '@mui/icons-material/MicOff'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import makeStyles from '@mui/styles/makeStyles'

import { useMiccamChecker, useStorageSetting } from 'hooks'
import { useMediaDevices } from 'hooks/use-media-devices'
import { PERMISSIONS_GRANTED } from 'background/constants'
import { PermissionStatusE } from 'types'

import { MediaDevice } from './MediaDevice'
import { PermissionAlerts } from './PermissionAlerts'
import { MicCamPreview } from './MicCamPreview'

const useStyles = makeStyles(({ spacing }) => ({
    iconWrapper: {
        marginRight: spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        maxHeight: 'unset',
        width: '54px',
        height: '54px',
        borderRadius: '30px',
        color: '#FFFFFF',
        backgroundColor: 'rgba(0, 0, 0, 0.20)',
    },
    blocked: {
        color: '#090C1080',
        background: 'rgba(255, 255, 255, 0.50)',
    },
}))

export const MicCamController = () => {
    // Check permissions for microphone and webcam
    useMiccamChecker()
    const classes = useStyles()

    const { storageSetting: permissions } =
        useStorageSetting(PERMISSIONS_GRANTED)

    const {
        chosenMic,
        micOptions,
        onMicChange,
        chosenWebcam,
        webcamOptions,
        onWebcamChange,
    } = useMediaDevices()

    const isLoading: boolean = !permissions
    const isMicBlocked: boolean =
        isLoading ||
        permissions === PermissionStatusE.PERMISSIONS_DENIED ||
        permissions === PermissionStatusE.UNKNOWN_ERROR
    const isWebcamBlocked: boolean =
        isMicBlocked ||
        permissions === PermissionStatusE.WEBCAM_BLOCKED ||
        permissions === PermissionStatusE.MIC_ONLY

    useEffect(() => {
        if (!isLoading && isMicBlocked) {
            onMicChange('mute')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isMicBlocked])

    useEffect(() => {
        if (!isLoading && isWebcamBlocked) {
            onWebcamChange('noCamera')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading, isWebcamBlocked])

    return (
        <>
            <PermissionAlerts permissions={permissions} />

            <MediaDevice
                loading={isLoading}
                disabled={isMicBlocked}
                title="Microphone"
                value={chosenMic || ''}
                options={micOptions}
                onChange={onMicChange}
                icon={
                    <div
                        className={clsx(
                            classes.iconWrapper,
                            isMicBlocked && classes.blocked
                        )}
                    >
                        {chosenMic === 'mute' ? (
                            <MicOffIcon />
                        ) : (
                            <MicNoneIcon />
                        )}
                    </div>
                }
            />

            <MediaDevice
                loading={isLoading}
                disabled={isWebcamBlocked}
                title="Camera"
                value={chosenWebcam || ''}
                options={webcamOptions}
                onChange={onWebcamChange}
                icon={
                    <div
                        className={clsx(
                            classes.iconWrapper,
                            isWebcamBlocked && classes.blocked
                        )}
                    >
                        {chosenWebcam === 'noCamera' ? (
                            <VideocamOffIcon />
                        ) : (
                            <VideocamIcon />
                        )}
                    </div>
                }
            />

            <MicCamPreview mic={chosenMic} webcam={chosenWebcam} />
        </>
    )
}
