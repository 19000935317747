import { useCallback, useMemo, useState } from 'react'

export type UseFilterState<T> = {
    state: T
    defaultState: T | null
    updateState: (value: T) => void
    updateDefaultState: (value: T) => void
    initDefaultState: (defaultValue: T, value: T) => void
    resetState: () => void
    resetDefaultState: () => void
    resetToDefaultState: () => void
}

export const useFilterState = <T>(initialState: T): UseFilterState<T> => {
    const [state, setState] = useState<T>(initialState)
    const [defaultState, setDefaultState] = useState<T | null>(null)
    const [tempState, setTempState] = useState<T | null>(null) // in case of resetToDefault

    const updateState = useCallback((value: T) => {
        setState(value)
    }, [])

    const updateDefaultState = useCallback((value: T) => {
        setDefaultState(value)
    }, [])

    const initDefaultState = useCallback((defaultValue: T, value: T) => {
        setState(value)
        setDefaultState(defaultValue)
        setTempState(defaultValue)
    }, [])

    const resetState = useCallback(() => {
        setState(initialState)
        setDefaultState(initialState)
    }, [initialState])

    const resetDefaultState = useCallback(() => {
        setDefaultState(null)
    }, [])

    const resetToDefaultState = useCallback(() => {
        setState(tempState || initialState)
        setDefaultState(tempState || initialState)
    }, [initialState, tempState, setDefaultState])

    return useMemo(
        () => ({
            state,
            defaultState,
            updateState,
            updateDefaultState,
            initDefaultState,
            resetState,
            resetDefaultState,
            resetToDefaultState,
        }),
        [
            state,
            defaultState,
            updateState,
            updateDefaultState,
            initDefaultState,
            resetState,
            resetDefaultState,
            resetToDefaultState,
        ]
    )
}
