/* Sentry integration.
 * https://docs.sentry.io/platforms/javascript/guides/react/ */

/* Console statements are allowed here, cause the errors/logs are proxied to Sentry */
/* eslint-disable no-console */
import Config from '../config'
import { isLocal } from './environmentService'

/* We do not load Sentry files to SDK parent script, because it conflicts with our customer's Sentry package.
 * Relevant JIRA: https://guidde.atlassian.net/browse/GD-6478 */
export const Sentry =
    SDK_MODE && IS_CUSTOM_WEBPACK_BUILD ? null : require('@sentry/react')
const BrowserTracing =
    SDK_MODE && IS_CUSTOM_WEBPACK_BUILD
        ? () => {}
        : require('@sentry/tracing')?.BrowserTracing

const noop = () => {}

const isDevExtension = (() => {
    if (SDK_MODE) {
        return isLocal
    }

    if (EXT_MODE) {
        return process.env.GUIDDE_ENV !== 'prod'
    }
})()

const checkIsDev = func => (isDevExtension ? noop : func)
const sentryDsn: string = SDK_MODE
    ? 'https://75a3636be2544413a4d644ec404f5fcd@o999875.ingest.sentry.io/6157574'
    : 'https://650ad5569e6b44168c6750dad16368a8@o999875.ingest.sentry.io/6006079'

const getSentryEnvName = () => {
    const temp = Config?.firebase?.projectId
    if (temp === 'guidde-dev-staging') return 'staging'
    if (temp === 'guidde-production') return 'production'
    if (temp === 'guidde-production-eu') return 'production-eu'
    return temp
}

const ERRORS_BLACKLIST = [
    'Non-Error promise rejection captured', // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
    'ResizeObserver loop limit exceeded', // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
]

const releaseName = process.env.VERSION || 'unknown'

export const initSentry = (defaultIntegrations: boolean = true) =>
    checkIsDev(() => {
        Sentry?.init({
            dsn: sentryDsn,
            tracesSampleRate: 0,
            environment: getSentryEnvName(),
            integrations: [new BrowserTracing()],
            ignoreErrors: ERRORS_BLACKLIST,
            release: releaseName,
            /* Disable default integrations for scripts that run directly
             * in the host page (e.g. parent script) to avoid logging host page errors.
             * But don't forget to wrap your script in try-catch and capture errors manually.
             * Details: https://docs.sentry.io/platforms/javascript/configuration/integrations/default/ */
            ...(defaultIntegrations ? {} : { defaultIntegrations: false }),
        })

        Sentry?.setTag('project-id', Config.firebase.projectId)
    })()

type SentryUserInfo = {
    id: string
    username: string
    email: string
}

export const setSentryUser = checkIsDev((userInfo: SentryUserInfo) => {
    Sentry?.setUser(userInfo)
})

// All Chrome extension callbacks should be wrapped manually with Sentry, otherwise the error won't be logged
export const establishListener = (chromeAPI, callback) => {
    const wrappedCallback = isDevExtension
        ? callback
        : (...args) => Sentry?.wrap(() => callback(...args))

    chromeAPI?.addListener?.(wrappedCallback)

    return wrappedCallback
}

export const captureSentryError = isDevExtension
    ? (error, extraData?: any, _withLog?: boolean) => {
          console.error(
              'Sentry Error Placeholder:',
              error.message || error,
              extraData
          )
      }
    : (error, extraData?: any, withLog?: boolean) => {
          if (withLog) console.error(error.message || error)

          Sentry?.captureException(error, extraData ?? { extra: extraData })
      }

export const captureSentryEvent = isDevExtension
    ? (event: string, withLog?: boolean, extraData?: any) => {
          console.warn('Sentry Message Placeholder: ', event, extraData)
      }
    : (event: string, withLog?: boolean, extraData?: any) => {
          if (withLog) console.warn(event)

          Sentry?.captureMessage(event, extraData ?? { extra: extraData })
      }
