import React, { useContext } from 'react'

import {
    Box,
    List,
    ListItem,
    Typography,
    Link as MaterialLink,
} from '@mui/material'

import { AppContext } from 'app/AppProvider'

import { formatTime } from 'services/utils'

export const TranscriptList = ({ playbook, classes, onPbClick }) => {
    const { openVideoPlayer } = useContext(AppContext)

    return (
        <List>
            {playbook?.sections?.map(o => {
                const result = o._snippetResult.transcript || null
                if (result && result.matchLevel !== 'none') {
                    return (
                        <ListItem key={o.id}>
                            <MaterialLink
                                key={playbook.id}
                                className={classes.listItemLink}
                                onClick={() => {
                                    openVideoPlayer(
                                        playbook,
                                        'bottomRight',
                                        Math.round(o.start)
                                    )
                                    onPbClick()
                                }}
                                underline="hover"
                            >
                                <Box display="flex" alignItems="center">
                                    <Typography className={classes.timeLabel}>
                                        {formatTime(o.start)}
                                    </Typography>
                                    <Typography
                                        noWrap
                                        style={{
                                            fontWeight: 'normal',
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: `...${result.value}...`,
                                        }}
                                        component="span"
                                    />
                                </Box>
                            </MaterialLink>
                        </ListItem>
                    )
                }
                return null
            })}
        </List>
    )
}
