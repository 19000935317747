import { getAuthDomain } from '../services/getAuthDomain'
import { getResourceURL } from '../services/extServices'

const EXT_ID = getResourceURL('')
const BUILD_MODE = EXT_MODE ? 'EXT' : 'SDK'

// Main HTML Element IDs
export const LIB_IFRAME_SHADOW_CONTAINER_ID = 'GUIDDE-JS-LIB-CONTAINER'
export const LIB_IFRAME_ID = EXT_MODE ? 'GUIDDE-JS-EXT' : 'GUIDDE-JS-SDK'
export const REC_IFRAME_SHADOW_CONTAINER_ID = 'GUIDDE-JS-REC-CONTAINER'
export const REC_IFRAME_ID = 'GUIDDE-JS-EXT-REC'
export const REC_CONTROLS_ID = 'GUIDDE-JS-REC-CONTROLS'
export const BROADCAST_IFRAME_SHADOW_CONTAINER_ID = `GUIDDE-JS-${BUILD_MODE}-BROADCAST-CONTAINER`
export const BROADCAST_IFRAME_ID = 'GUIDDE-JS-BROADCAST'

// Minor HTML Element IDs
export const REC_PANEL_CONTAINER_ID = 'GUIDDE-JS-RecPanelContainer'
export const STOP_REC_ID = 'GUIDDE-JS-STOP-BTN'
export const COUNTDOWN_ID = 'GUIDDE-JS-TimeToRecEnd'
export const ADD_CHAPTER_ID = 'GUIDDE-JS-Add-Chapter'
export const DONE_REC_ID = 'GUIDDE-JS-Done'
export const CHAPTER_COUNT_ID = 'GUIDDE-JS-ChapterCount'
export const CHAPTER_COUNT_CIRCLE_ID = 'GUIDDE-JS-ChapterCountCircle'
export const CHANGE_REC_PANEL_MODE_ID = 'GUIDDE-JS-ChangeRecPanelMode'
export const FLIP_REC_PANEL_ID = 'GUIDDE-JS-FlipRecPanel'
export const RESTART_REC_ID = 'GUIDDE-JS-Restart'
export const DELETE_REC_ID = 'GUIDDE-JS-Delete'
export const PAUSE_QG_ID = 'GUIDDE-JS-Pause'
export const RESUME_QG_ID = 'GUIDDE-JS-Resume'

// On this website src is overriden by the host page,
// to make extension work we need to inject the iframe in the shadow DOM
// See: https://guidde.atlassian.net/browse/GD-9203
export const shouldInjectIframeInShadow = () => {
    return window.location.href.startsWith('https://www.delta.com')
}

// Getters for main HTML elements
export const getLibIframe: () => HTMLIFrameElement | null = () => {
    if (shouldInjectIframeInShadow()) {
        const container = document.getElementById(
            LIB_IFRAME_SHADOW_CONTAINER_ID
        )
        return container?.shadowRoot?.getElementById(
            LIB_IFRAME_ID
        ) as HTMLIFrameElement
    }

    return document.getElementById(LIB_IFRAME_ID) as HTMLIFrameElement
}

export const getBroadcastIframe: () => HTMLIFrameElement | null = () => {
    const container = document.getElementById(
        BROADCAST_IFRAME_SHADOW_CONTAINER_ID
    )

    if (!container?.shadowRoot) return null

    return container.shadowRoot.getElementById(
        BROADCAST_IFRAME_ID
    ) as HTMLIFrameElement | null
}

export const getRecIframe: () => HTMLIFrameElement | null = () => {
    if (shouldInjectIframeInShadow()) {
        const container = document.getElementById(
            REC_IFRAME_SHADOW_CONTAINER_ID
        )
        return container?.shadowRoot?.getElementById(
            REC_IFRAME_ID
        ) as HTMLIFrameElement
    }

    return document.getElementById(REC_IFRAME_ID) as HTMLIFrameElement
}

export const getRecControls: () => HTMLElement | null = () =>
    document.getElementById(REC_CONTROLS_ID)

// Iframe sources and utils
const LIB_LOCAL_SRC = 'http://localhost:3101'
const REC_LOCAL_SRC = 'http://localhost:3102'
const BROADCAST_LOCAL_SRC = 'http://localhost:5173'
const LIB_BUILD_SRC = getResourceURL('cs/lib/index.html')
const REC_BUILD_SRC = getResourceURL('cs/rec/index.html')

export const EXT_LIB_IFRAME_SRC = Boolean(process.env.GUIDDE_LOCAL_SERVER)
    ? LIB_LOCAL_SRC
    : LIB_BUILD_SRC

export const EXT_REC_IFRAME_SRC = Boolean(process.env.GUIDDE_LOCAL_SERVER)
    ? REC_LOCAL_SRC
    : REC_BUILD_SRC

export const getBroadcastIframeBaseOriginSrc = async () => {
    const BROADCAST_BUILD_BASE_ORIGIN_SRC = `broadcast.${await getAuthDomain()}`

    return Boolean(process.env.IS_BROADCAST_LOCAL_SERVER)
        ? BROADCAST_LOCAL_SRC
        : BROADCAST_BUILD_BASE_ORIGIN_SRC
}

export const EXT_IFRAME_ALLOW = `camera ${EXT_ID};microphone ${EXT_ID};clipboard-read;clipboard-write`
export const BROADCAST_IFRAME_ALLOW = `autoplay;clipboard-write`

const SDK_PREFIX = 'GUIDDE SDK: '
export const SDK_ERRORS = {
    WRONG_SNIPPET: SDK_PREFIX + 'Missing core variables.',
    CHUNK_LOADING:
        SDK_PREFIX +
        'Can not execute play(), the main chunk is not loaded yet.',
    INVALID_TENANT: SDK_PREFIX + 'Invalid tenant',
    EMPTY_PB_ID:
        SDK_PREFIX +
        'Could not execute play() - only non-empty strings are allowed as a playbookId parameter.',
    WRONG_USER_TYPE:
        SDK_PREFIX +
        'Could not process user information. Only objects are allowed, SDK is launched anonymously.',
    USER_TOO_LARGE:
        SDK_PREFIX +
        `GUIDDE SDK: User data exceeds allowed size of 512 characters. SDK is launched anonymously.`,
    INCORRECT_OPEN_PARAMS:
        SDK_PREFIX + 'Could not execute open API - only object is allowed',
    INCORRECT_OPEN_VIDEO_ID:
        SDK_PREFIX +
        'Could not execute openVideo() - only non-empty strings are allowed as a playbookId parameter.',
    INCORRECT_OPEN_PLAYLIST_ID:
        SDK_PREFIX +
        'Could not execute openPlaylist() - only non-empty strings are allowed as a playlistId parameter.',
    INCORRECT_OPEN_QUERY_SEARCH:
        SDK_PREFIX +
        'Could not execute openQuerySearch() - only non-empty strings are allowed as a query parameter.',
    INCORRECT_SET_BUTTON_VIEW_PARAMS:
        SDK_PREFIX +
        'Could not execute setControlButtonView() - only object is allowed.',
    WIDGET_NOT_INITIALIZED:
        SDK_PREFIX +
        'Could not execute API call - widget is not initialized yet',
}
