import { useCallback, useEffect, useRef, useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import { SpacedGroup } from '../SpacedGroup'
import { captureSentryError } from '../../../services/sentry'

const useStyles = makeStyles(() => ({
    grayDot: {
        width: '8px',
        height: '8px',
        background: '#E0E0E0',
        borderRadius: '50%',
    },
}))

const DOTS_AMOUNT = 13
const DOTS_ARRAY = new Array(DOTS_AMOUNT).fill('')

export const VolumeMeter = ({ chosenMic }) => {
    const streamRef = useRef<MediaStream | null>(null)
    const [volume, setVolume] = useState(0)
    const classes = useStyles()

    const connectToMic = useCallback(() => {
        removeTracks()

        // Connect to microphone and use the volume value on VolumeMeter component
        navigator.mediaDevices
            .getUserMedia({
                audio: { deviceId: chosenMic },
            })
            .then(stream => {
                streamRef.current = stream
                const audioContext = new AudioContext()
                const analyser = audioContext.createAnalyser()
                const microphone = audioContext.createMediaStreamSource(stream)
                const scriptProcessor = audioContext.createScriptProcessor(
                    2048,
                    1,
                    1
                )

                analyser.smoothingTimeConstant = 0.8
                analyser.fftSize = 1024

                microphone.connect(analyser)
                analyser.connect(scriptProcessor)
                scriptProcessor.connect(audioContext.destination)
                scriptProcessor.onaudioprocess = function () {
                    const array = new Uint8Array(analyser.frequencyBinCount)
                    analyser.getByteFrequencyData(array)
                    const arraySum = array.reduce((a, value) => a + value, 0)
                    const average = arraySum / array.length
                    setVolume(Math.round(average))
                }
            })
            .catch(function (err) {
                /* handle the error */
                captureSentryError(err)
            })
    }, [chosenMic])

    // Disconnect from microphone track
    const removeTracks = () => {
        streamRef?.current?.getTracks().forEach(track => {
            track.stop()
        })

        streamRef.current = null
    }

    useEffect(() => {
        connectToMic()

        return () => {
            removeTracks()
        }
    }, [connectToMic])

    return (
        <SpacedGroup mb={0.5} spacing={1} justifyContent="space-between">
            {DOTS_ARRAY.map((dot, index) => (
                <div
                    style={{
                        backgroundColor:
                            volume / (100 / DOTS_AMOUNT) > index
                                ? '#CB0000'
                                : '#E0E0E0',
                    }}
                    key={index}
                    className={classes.grayDot}
                ></div>
            ))}
        </SpacedGroup>
    )
}
