import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import LinearProgress from '@mui/material/LinearProgress'
import makeStyles from '@mui/styles/makeStyles'

import { logToAnalytics, openExternalLink } from 'services/swMessenger'

const useStyles = makeStyles(({ spacing }) => ({
    alert: {
        width: '100%',
        position: 'fixed',
        top: '0',
        left: '0',
        textAlign: 'center',
        justifyContent: 'center',
        borderRadius: '0',
        background: 'rgba(0, 0, 0, 0.80)',
        color: '#ffffff',
        fontSize: '18px',
        fontWeight: 400,
    },
    root: {
        marginLeft: spacing(2.5),
        height: spacing(1),
        width: 100,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: '#FFFFFF4D',
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#FED243',
    },
    btn: {
        marginLeft: spacing(2),
        color: '#ffffff',
        fontSize: '18px',
        fontWeight: 400,
        textDecoration: 'underline',
    },
}))

type Props = {
    usedQGStatus: {
        usedMC: number
        quotaMC: number | null
    }
}

export const UpgradeBanner = ({ usedQGStatus }: Props) => {
    const classes = useStyles()

    // User has unlimited quota
    if (usedQGStatus.quotaMC === null) return null

    // If user did not record a video yet, do not show the banner
    if (usedQGStatus.usedMC === 0) return null

    const progress = (usedQGStatus.usedMC / usedQGStatus.quotaMC) * 100

    return (
        <Alert className={classes.alert} icon={false} variant="filled">
            <Box display="flex" alignItems="center">
                <span>
                    {progress < 100
                        ? 'Your account has almost reached its limit • '
                        : 'Your account has reached its limit • '}
                    {usedQGStatus.usedMC}/{usedQGStatus.quotaMC} videos
                </span>

                <LinearProgress
                    variant="determinate"
                    value={progress}
                    classes={{
                        root: classes.root,
                        colorPrimary: classes.colorPrimary,
                        bar: classes.bar,
                    }}
                />

                <Button
                    variant="text"
                    //classes={{ label: classes.btn }}
                    onClick={() => {
                        logToAnalytics(
                            progress < 100
                                ? 'upgradeMessage_seePlansButton_clicked'
                                : 'upgradeMessage_upgradePlanButton_clicked',
                            {
                                page: 'recording_wizard',
                            }
                        )

                        openExternalLink(null, {
                            url: `account-settings?showPlans=true`,
                        })
                    }}
                >
                    {progress < 100 ? 'See plans' : 'Upgrade your plan'}
                </Button>
            </Box>
        </Alert>
    )
}
