import { useEffect, useMemo, useState } from 'react'

import { tagsParser, TagsParserResponse } from 'services/tagsParser'
import { sendMessageToParentScript } from '../services/parentService'
import { useParentLocation } from './use-parent-location'
import { useSelector } from 'react-redux'
import { RootState } from '../ducks/rootReducer'

export const useTagsParser = () => {
    const [defaultFilters, setDefaultFilters] = useState<
        TagsParserResponse & {
            loading: boolean
        }
    >({
        app: '',
        appName: '',
        domain: '',
        tags: [],
        querySelectors: [],
        loading: true,
    })

    const isSDKEmbed = useSelector((state: RootState) => state.sdkConfig.embed)
    const customLocation = useParentLocation()
    const apps = useSelector((state: RootState) => state.apps)
    const href = customLocation?.href || ''

    useEffect(() => {
        // In extension, we get tags directly in parent (so querySelectors work)
        if (EXT_MODE) {
            window.addEventListener('message', event => {
                const { type, data } = event?.data

                if (type === 'DEFAULT_TAGS_CHANGED' && data) {
                    const parsedResponse = JSON.parse(data)
                    setDefaultFilters({ ...parsedResponse, loading: false })

                    // For mixpanel events to resolve by default
                    window._GUIDDE = {
                        CURRENT_APP: data.app,
                        CURRENT_DOMAIN: data.domain,
                        DEFAULT_TAGS: data.tags,
                    }
                }
            })

            // Request location for the first time in case event listener was established after the load
            sendMessageToParentScript({ action: 'GET_DEFAULT_TAGS' })
        }
    }, [])

    useEffect(() => {
        // do not set any default tags if SDK is running in embed mode
        if (isSDKEmbed) {
            setDefaultFilters(prev => ({ ...prev, loading: false }))
            return
        }

        if (SDK_MODE || process.env.NODE_ENV === 'development') {
            // In SDK we do not have apps, so tagsParser works without them
            if (customLocation) {
                tagsParser(customLocation).then(res => {
                    setDefaultFilters({ ...res, loading: false })

                    // For mixpanel events to resolve by default
                    window._GUIDDE = {
                        CURRENT_APP: res.app,
                        CURRENT_DOMAIN: res.domain,
                        DEFAULT_TAGS: res.tags,
                    }
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [href, apps]) // We check href, not customLocation to act only on URL change, not state change

    return useMemo(() => defaultFilters, [defaultFilters])
}
