import short from 'short-uuid'
import { captureSentryEvent } from './sentry'

export const option = (value: any, label: string) => ({ value, label })

export const uuid = () => short.generate()

export const formatTime = seconds => {
    const format = val => `0${Math.floor(val)}`.slice(-2)

    const hours = seconds / 3600
    const minutes = (seconds % 3600) / 60
    const secs = seconds % 60

    return [...(hours >= 1 ? [hours] : []), minutes, secs].map(format).join(':')
}

export const getSelectionText = () => window.getSelection?.()?.toString()

export const uiId = id => `ui-t-ext-${id}`

export const getFromSessionStorage = (key: string) => {
    try {
        return sessionStorage.getItem(key)
    } catch (e) {
        // In incognito mode access is going to be denied
        return null
    }
}

export const setToSessionStorage = (key, value) => {
    try {
        sessionStorage.setItem(key, value)
    } catch (e) {
        captureSentryEvent('Could not set value to sessionStorage', false, {
            key,
            value,
        })
    }
}
