import firebase from './init'
import { captureSentryError } from '../services/sentry'
import { getUser } from './auth'
import { doc, onSnapshot } from 'firebase/firestore'

let isInitial: boolean = true
let userAchievements = {}
let unsubFromUserAchievements: null | (() => void) = null

const userAchievementsSubscription = uid =>
    new Promise(resolve => {
        // onSnapshot will receive updates whenever user achievements are updated
        unsubFromUserAchievements = onSnapshot(
            doc(firebase.firestore(), `userAchievements/${uid}`),
            querySnapshot => {
                const userAchievementsSnapshot = querySnapshot.data() || {}
                userAchievements = userAchievementsSnapshot
                resolve(userAchievementsSnapshot)
            },
            e => {
                isInitial = false // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getUserAchievements = () => {
    const firebaseUser = getUser()
    const uid = firebaseUser?.user.uid

    if (!uid) return Promise.resolve({})

    if (isInitial) {
        isInitial = false
        return userAchievementsSubscription(uid)
    }

    return Promise.resolve(userAchievements)
}

// Garbage collector to remove all subscriptions and reset everything to initial
export const firebaseUserAchievementsGC = () => {
    isInitial = true
    userAchievements = {}
    unsubFromUserAchievements?.()
}

export const setUserAchievement = (
    uid: string,
    payload: { [key: string]: boolean }
) =>
    firebase
        .firestore()
        .doc(`userAchievements/${uid}`)
        .set(payload, { merge: true })
        .catch(e => captureSentryError(e, payload))
