import { ReactElement } from 'react'

import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'

const DOT_SIZE = 6
const DOT_GAP = 4

const useStyles = makeStyles(({ spacing }) => ({
    label: {
        display: 'flex',
        color: '#FFFFFF',
        lineHeight: '24px',
        fontSize: '18px',
        fontWeight: 700,
    },
    value: {
        color: '#FFFFFF',
        lineHeight: '20px',
        fontSize: '14px',
        fontWeight: 400,
        width: '120px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    disabled: { opacity: 0.5 },
    dotFlashing: {
        marginTop: spacing(1),
        marginLeft: `${DOT_SIZE + DOT_GAP}px`,
        position: 'relative',
        width: `${DOT_SIZE}px`,
        height: `${DOT_SIZE}px`,
        borderRadius: '50%',
        backgroundColor: 'rgba(255,255,255,0.2)',
        animation: '$dot-flashing 1s infinite linear alternate',
        animationDelay: '0.5s',
        '&:before, &:after': {
            content: '""',
            display: 'inline-block',
            position: 'absolute',
            top: '0',
        },
        '&:before': {
            left: `${-(DOT_SIZE + DOT_GAP)}px`,
            width: `${DOT_SIZE}px`,
            height: `${DOT_SIZE}px`,
            borderRadius: '50%',
            backgroundColor: 'rgba(255,255,255,0.2)',
            animation: '$dot-flashing 1s infinite alternate',
            animationDelay: '0s',
        },
        '&:after': {
            left: `${DOT_SIZE + DOT_GAP}px`,
            width: `${DOT_SIZE}px`,
            height: `${DOT_SIZE}px`,
            borderRadius: '50%',
            backgroundColor: 'rgba(255,255,255,0.2)',
            animation: '$dot-flashing 1s infinite alternate',
            animationDelay: '1s',
        },
    },
    '@keyframes dot-flashing': {
        '0%': {
            backgroundColor: 'rgba(255,255,255,0.9)',
        },
        '50%, 100%': {
            backgroundColor: 'rgba(255,255,255,0.2)',
        },
    },
}))

type Props = {
    disabled: boolean
    loading: boolean
    title: string
    value: string
    options: { deviceId: string; label: string }[]
    onChange: (deviceId: string) => void
    icon?: ReactElement
}

export const MediaDevice = (props: Props) => {
    const classes = useStyles()
    const { disabled, loading } = props

    return (
        <TextField
            variant="standard"
            select
            disabled={disabled}
            value={props.value || ''}
            onChange={event => {
                if (disabled) return
                props.onChange(event.target.value)
            }}
            SelectProps={{
                IconComponent: () => null,
                renderValue: value => {
                    return (
                        <Box
                            display="flex"
                            alignItems="center"
                            className={disabled ? classes.disabled : ''}
                        >
                            {props.icon}
                            <Box>
                                <div className={classes.label}>
                                    {props.title} <ArrowDropDownIcon />
                                </div>
                                <div className={classes.value}>
                                    {loading && (
                                        <div className={classes.dotFlashing} />
                                    )}

                                    {!loading &&
                                        (disabled
                                            ? 'Not allowed'
                                            : props.options.find(
                                                  mic => mic.deviceId === value
                                              )?.label)}
                                </div>
                            </Box>
                        </Box>
                    )
                },
            }}
            InputProps={{ disableUnderline: true }}
        >
            {props.options.map(option => (
                <MenuItem key={option.deviceId} value={option.deviceId}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    )
}
