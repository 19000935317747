export const setPlaybookList = list => ({
    type: 'setPlaybookList',
    payload: list,
})

export const resetPlaylist = () => ({
    type: 'resetPlaylist',
})

export const setActivePlaybook = number => ({
    type: 'setActivePlaybook',
    payload: number,
})
