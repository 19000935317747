import firebase from './init'
import { captureSentryError } from '../services/sentry'
import { getUser } from './auth'
import { doc, onSnapshot } from 'firebase/firestore'
import { fetchQuickActionConfig } from '../api/quickActions'

type EnabledQuickActions = string[]

let isInitial: boolean = true
let quickActionSettings: EnabledQuickActions = []
let unsubFromQuickActionSettings: null | (() => void) = null

const subscribeToUserQuickActions = uid =>
    new Promise<EnabledQuickActions>(resolve => {
        // onSnapshot will receive updates whenever quick action settings are updated
        unsubFromQuickActionSettings = onSnapshot(
            doc(firebase.firestore(), `quickActionsUserSettings/${uid}`),
            async querySnapshot => {
                const userQASnapshot =
                    (querySnapshot.data()
                        ?.quickActions as EnabledQuickActions) || []
                quickActionSettings = userQASnapshot

                if (!isInitial) {
                    // On user settings update, re-fetch quick actions config
                    await fetchQuickActionConfig()
                }

                resolve(userQASnapshot)
            },
            e => {
                isInitial = true // Something went wrong, retry another time
                captureSentryError(e)
            }
        )
    })

export const getUserQuickActions: () => Promise<EnabledQuickActions> =
    async () => {
        const firebaseUser = getUser()
        const uid = firebaseUser?.user.uid

        if (!uid) return Promise.resolve([])

        if (isInitial) {
            await subscribeToUserQuickActions(uid)
            isInitial = false
        }

        return Promise.resolve(quickActionSettings)
    }

// Garbage collector to remove all subscriptions and reset everything to initial
export const firebaseUserQuickActionsGC = () => {
    isInitial = true
    quickActionSettings = []
    unsubFromQuickActionSettings?.()
}
