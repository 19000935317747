import { useEffect, useState } from 'react'

export const useTextDebounce = (
    payload: string,
    delay: number | undefined = 500
): string => {
    const [text, setText] = useState('')

    useEffect(() => {
        const timeOutId = setTimeout(() => setText(payload), delay)
        return () => clearTimeout(timeOutId)
    }, [delay, payload])

    return text
}
