import firebase from '../firebase/init'
import { getUser } from '../firebase/auth'
import * as ANALYTICS from '../background/analytics'
import { request } from './utils'
import { captureSentryError } from '../services/sentry'
import { PlaybookType } from '@guidde/common-types/build/fsdb'

export const getPlaybooks = async ({ sendLogEvent, ...params }) => {
    const user: any = getUser()
    if (EXT_MODE && !user) return null

    const { applicationId, tags, text, page } = params
    const logSearch = (event, data) =>
        ANALYTICS.logEvent(event, {
            'pb-appId': applicationId,
            tags,
            text,
            page,
            ...data,
        })

    try {
        const url = EXT_MODE ? '/a/v1/search' : '/k/v1/getPlaybooks'

        const data = await request(url, 'POST', {
            ...params,
            ...(EXT_MODE ? { source: 'extension' } : {}),
        })

        if (sendLogEvent) {
            const searchText = text.toLowerCase().trim()
            const searchKey = `lastSearchedText/${user.uid}`

            if (SDK_MODE) {
                const lastSearchedText = sessionStorage?.getItem(searchKey)

                if (searchText === lastSearchedText) {
                    return data
                }

                sessionStorage?.setItem(searchKey, searchText)
            }

            // In extension service worker session storage is not available
            if (EXT_MODE) {
                const storageSearch = await chrome.storage.local.get(searchKey)
                const lastSearchedText = storageSearch?.[searchKey]

                if (searchText === lastSearchedText) {
                    return data
                }

                await chrome.storage.local.set({ [searchKey]: searchText })
            }

            logSearch(data.nbHits ? 'search' : 'search-no-results', {
                hits: data.nbHits,
            })
        }
        return data
    } catch (e) {
        captureSentryError(e, { params })
        logSearch('search_fail', { error: e })
        return null
    }
}

export const getPlaybook = async (
    playbookId: string
): Promise<PlaybookType | null> => {
    try {
        if (EXT_MODE) {
            const user = getUser()
            if (!user) return null

            return await request(`/c/v1/quickguidde?id=${playbookId}`, 'GET')
        }

        if (SDK_MODE) {
            return await request('/k/v1/getPlaybook', 'POST', {
                playbookId,
            })
        }

        return null
    } catch (e) {
        captureSentryError(e, { playbookId })
        return null
    }
}

const getPlaybookListSdk = async (playbookId: string) => {
    try {
        const data = await request('/k/v1/getPlaybookList', 'POST', {
            playbookId,
        })
        return data || []
    } catch (e) {
        captureSentryError(e, { playbookId })
        return null
    }
}

const getPlaybookListExt = async (playbookId: string) => {
    try {
        const user = getUser()
        if (!user) return null

        return await request(`/c/v1/playlist?id=${playbookId}`, 'GET')
    } catch (e) {
        captureSentryError(e, { playbookId })
        return null
    }
}

export const getPlaybookList = async (playbookId: string) => {
    try {
        if (SDK_MODE) {
            return await getPlaybookListSdk(playbookId)
        }

        if (EXT_MODE) {
            return await getPlaybookListExt(playbookId)
        }
    } catch (e) {
        captureSentryError(e, { playbookId })
        return null
    }
}

export const getPlaybookSteps = async payload => {
    try {
        return await request(`/c/v1/quickguidde?id=${payload}`, 'GET')
    } catch (e) {
        captureSentryError(e, payload)
        return null
    }
}

export const sharePlaybookOnly = async payload => {
    try {
        return await request('/c/v1/playbook-share', 'POST', payload)
    } catch (e) {
        captureSentryError(e, payload)
        return null
    }
}

export const getPlaybookOptions = async payload => {
    try {
        return await request('/c/v1/get-shareable-options', 'POST', payload)
    } catch (e) {
        captureSentryError(e, payload)
        return null
    }
}

export const fetchTranscript = async url => {
    if (!url) return null

    const response = await fetch(url)

    if (!response.ok) {
        captureSentryError(new Error('Failed to fetch transcript'), { url })
        return null
    }

    const data = await response.text()
    if (data) return JSON.parse(data)
    return JSON.parse('{}')
}

export const sendViewNotification = async (
    msg,
    sendResponse?: (_data: any) => void
) => {
    try {
        const { orgId, uid, playbookId } = msg.params
        if (orgId) {
            const data = await firebase
                .database()
                .ref(`/playbookViews/${orgId}/${uid}/${playbookId}`)
                .set({
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                })
            return sendResponse?.(data)
        } else {
            const data = await request('/k/v1/sendViewNotification', 'POST', {
                playbookId,
            })
            return sendResponse?.(data)
        }
    } catch (e) {
        captureSentryError(e, msg)
        return sendResponse?.(null)
    }
}
